import React from 'react';
import { th } from 'react-icons-kit/fa';
import { Card, CardBody } from 'reactstrap';
import { withTheme } from '../contexts/AppContext';


export default withTheme(class OrderTotal extends React.Component {
    constructor(props) {
        super(props);
        const context = this.props.context;
        // console.log('TOTAL PROPS ------', this.props)
        this.state = {
            orderSorted: {},
            confirmedTotal: 0.00,
            confirmedCGST: 0.00,
            confirmedSGST: 0.00,
            confirmedTax: 0.00,
            confirmedTaxes: 0.00,
            confirmedServiceFee: 0.00,
            confirmedVAT: 0.00,
            confirmedFees: 0.00,
            confirmedGrandTotal: 0.00,
            confirmedPayTotal: 0.00,
            confirmedCount: 0,
            confirmedCreditsUsed: 0.00,
            discount: 0.00,
            loading: false,
            orderdata: context.orderdata
        }
    }

    updateOrderPerUser() {
        let orderdata = this.state.orderdata;
        if(orderdata) {
        var confirmedTotal = this.state.orderdata.subtotal;
        let discount = this.state.orderdata.discount;
    
        let confirmedTaxDetails = this.state.orderdata.tax_info ? JSON.parse(this.state.orderdata.tax_info) : null;
    
        var confirmedTax = 0.00;
        var confirmedTaxes = 0.00;
        var confirmedCGST = this.state.orderdata.cgstAmount;
        var confirmedSGST = this.state.orderdata.sgstAmount;
        var confirmedServiceFee = this.state.orderdata.serviceAmount;
        var confirmedVAT = this.state.orderdata.vatAmount;
        var confirmedFees = this.state.orderdata.kumbaAmount;
        var confirmedGrandTotal = 0.00;
        var confirmedCount = 0;
        var confirmedPayTotal = 0.00;
        var confirmedCreditsUsed = 0.00;
    
        let orderSorted = {};
    
        if (orderdata && orderdata.orders && orderdata.orders.length > 0) {
          orderdata.orders.forEach(order => {
            if (!orderSorted[order.user]) {
              orderSorted[order.user] = [];
            }

            let options_json = order.options_json ? JSON.parse(order.options_json) : null;
    
            order.orderTotal = order.item_total * order.quantity;
            confirmedCount += order.quantity;
    
            order.subtitle = "";
            if (options_json && options_json.length > 0) {
              // console.log(order.rest_id);
              let allRests = this.state.subrestdata ? this.state.subrestdata.concat([this.state.mainrestdata]) : [this.state.mainrestdata];
              let selectedRest = allRests.filter(rest => rest.restdata && rest.restdata.restaurant_id === order.rest_id);
              let menuTable = selectedRest[0].menuTable;
              options_json.forEach(option => {
                let choiceitem = option.choiceitem ? menuTable[option.choiceitem] : null;
                order.subtitle += choiceitem ? choiceitem.name + "," : option.name + ",";
              });
              order.subtitle = order.subtitle
                .trim()
                .replace(/.$/, ".")
                .substr(0, 80);
            }
            orderSorted[order.user].push(order);
          });
    
        //   console.log(confirmedTaxDetails);
    
        if(confirmedTaxDetails && Object.keys(confirmedTaxDetails).length > 0) {
            for(let key in confirmedTaxDetails){
                confirmedTax += confirmedTaxDetails[key].actual_tax;
            };
            // console.log(confirmedTax);
        } else {
            confirmedTax = 0;
            //confirmedCGST + confirmedSGST + confirmedVAT + confirmedFees + confirmedServiceFee;
        }
    
        confirmedGrandTotal = Math.round((confirmedTotal + confirmedTax) * 100) / 100;
    
          if (orderdata.order_credits) {
            if (orderdata.order_credits > confirmedGrandTotal - discount) {
              confirmedPayTotal = 0.0;
              confirmedCreditsUsed = confirmedGrandTotal - discount;
            } else {
              confirmedPayTotal = Math.round((confirmedGrandTotal - discount - orderdata.order_credits) * 100) / 100;
              confirmedCreditsUsed = orderdata.order_credits;
            }
          } else {
            confirmedPayTotal = confirmedGrandTotal - discount;
          }
    
          this.setState({
            orderSorted: orderSorted,
            confirmedTotal,
            confirmedCGST,
            confirmedSGST,
            confirmedTax,
            confirmedTaxes,
            confirmedServiceFee,
            confirmedVAT,
            confirmedFees,
            confirmedGrandTotal,
            confirmedPayTotal,
            confirmedCount,
            confirmedCreditsUsed,
            discount, loading: false
          });
        } else {
          this.setState({ orderSorted: null, loading: false });
        }
        //this.getuserinfos();
        } else {
            this.setState({loading : false});
        }
      }

    componentDidMount() {
        this.updateOrderPerUser();
        // console.log('ORDER TOTAL PROPS -------', this.state)
    }
    

    render() {
        const { state } = this;
        if (this.state.orderdata && this.props.context.restdata) {
            let confirmedTaxDetails = state.orderdata.tax_info ? JSON.parse(state.orderdata.tax_info) : null; 
            return (
                <Card style={{ backgroundColor: window.backgroundColor}}>
                    <div className='p-2'>
                        {
                            state.confirmedTotal > 0 ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: window.textColor }}>
                                    <b>Subtotal:</b>
                                    <b>{window.rupeeSym + this.state.confirmedTotal.toFixed(2)}</b>
                                </div>: null
                        }
                        {
                            state.confirmedTax > 0 && confirmedTaxDetails ?
                                Object.keys(confirmedTaxDetails).map(taxname => {
                                    let taxdata = confirmedTaxDetails[taxdata];
                                return (<div style={{ display: 'flex', justifyContent: 'space-between', color: window.textColor }}>
                                    <b>{taxname}</b>
                                    <b>
                                        {window.rupeeSym + ((Math.round((taxdata.actual_tax) * 100) / 100).toFixed(2) || 0.00)
                                        }</b>
                                </div>)
                            }): null
                        }
                        {
                            state.orderCredits > 0 ? 
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: this.props.context.textColor }}>
                                    <b>{"Available Credits"} :</b>
                                    <b>{window.rupeeSym + state.orderCredits.toFixed(2)}</b>
                                </div> : null
                        }
                        {
                            state.orderCredits > 0 ? 
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: this.props.context.textColor }}>
                                    <b>{"Credits Applied"} :</b>
                                    <b>{"- " + window.rupeeSym + state.confirmedCreditsUsed.toFixed(2)}</b>
                                </div> : null
                        }
                        {
                            state.discount > 0 ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: this.props.context.textColor }}>
                                    <b>{"Discount"} :</b>
                                    <b>{"- " + window.rupeeSym + state.discount.toFixed(2)}</b>
                                </div> : null
                        }
                        {
                            state.confirmedCount > 0 ?
                                <div style={{ display: 'flex', justifyContent: 'space-between', color: this.props.context.textColor }}>
                                    <b>{"Total"} :</b>
                                    <b>{window.rupeeSym + state.confirmedPayTotal.toFixed(2)}</b>
                                </div> : null
                        }
                    </div>
                </Card>
            )
        } else {
            return null;
        }
    }
});
