import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-activity'
import { withTheme } from '../../../contexts/AppContext'
import AppItemCustomization from "../../../items/ItemCustomization";
import cogoToast from "cogo-toast";
import renderHTML from 'react-render-html';
import { formatDescriptionFull } from "../../../helpers/HTMLDescripton"


export default withTheme(props => {
    // console.log('PROPS ---', props);
    const { context, match } = props;
    const restdata = context.restdata;
    const itemID = match.params.itemID;

    const [state, setState] = useState({
        loading: true
    });
    const [item, setItem] = useState(props.location.state || null);

    const [activeSlot, setActiveSlot] = useState(context ? context.activeDeliverySlot : null);
    const [buttonText, setButtonText] = useState("ADD ITEM");
    const [customizations, setCustomizations] = useState(null);
    const [slotError, setSlotError] = useState(false);
    const [chooseOptions, setChooseOptions] = useState(false);
    const [orderExists, setOrderExists] = useState(false);
    const [existingOrder, setExistingOrder] = useState(null);
    const [error, setError] = useState(null);
    const [showImg, setShowImg] = useState(true);

    const theme = window.themeColor;
    const outOfStock = item.isOutOfStock;
    const id = item.item_id;
    const img = item.image;

    let isDigitalMenu =
        context.isDigitalMenu ||
        (props.restdata && (!props.restdata.isOpen ||
            props.restdata.subscription_package === "digital-menu"));




    // const getCustomizations = async () => {
    //     try {
    //         let url = window.kumbaURL + "/items/" + itemID;
    //         let { data } = await axios({
    //             url,
    //             method: "GET",
    //             headers: { 'foodbots-header': context.token }
    //         });
    //         if (isDigitalMenu) {
    //             setButtonText("SHORTLIST");
    //         } else if (data && data.length > 0) {
    //             setButtonText("ADD ITEM");
    //         } else {
    //             setButtonText("ADD ITEM");
    //         }
    //         console.log('DETAILS ---', data);
    //         setItem(data);
    //         setState({ ...state, loading: false })
    //         setCustomizations(data);
    //     } catch (err) {
    //         setError(err);
    //     }
    // };

    useEffect(() => {
        getCustomizations();
        if (!item) {
            getCustomizations()
        } else {
            setState({ ...state, loading: false })
        }
    }, []);


    // context methods
    const findUncomfirmedOrder = () => {
        let order = null;

        if (restdata && restdata.type === 'homechef') {
            //console.log("Is homechef");
            const slotInStorage = context.selectedDeliverySlot;
            //console.log(slotInStorage, context.activeDeliverySlot);
            if (slotInStorage === context.activeDeliverySlot) {
                //console.log("Is equal");
                if (context.unconfirmedOrders) {
                    const order = context.unconfirmedOrders.filter(
                        (order) => order.item_id === id
                    );
                    // console.log({ order, id });
                    //console.log("Orders unconfirmed");
                    if (order && order[0]) {
                        // console.log(order[0]);
                        setOrderExists(true);
                        setExistingOrder(order[0]);
                    } else {
                        setOrderExists(false);
                        setExistingOrder(null);
                    }
                } else {
                    //console.log("No Orders unconfirmed");
                    setOrderExists(false);
                    setExistingOrder(null);
                }
            } else {
                //console.log("Is not equal");
                setOrderExists(false);
                setExistingOrder(null);
            }
        } else {
            //console.log("Is not homechef");
            if (context.unconfirmedOrders) {
                const order = context.unconfirmedOrders.filter(
                    (order) => order.item_id === id
                );
                // console.log({ order, id });

                if (order && order[0]) {
                    // console.log(order[0]);
                    setOrderExists(true);
                    setExistingOrder(order[0]);
                }
            } else {
                //console.log("Is not equal");
                setOrderExists(false);
                setExistingOrder(null);
            }
        }

    };

    const optionsClose = () => {
        setChooseOptions(false);
        // close modal
    };

    const updateQuantity = (id, quantity) => {
        if (quantity <= 0) {
            context.removeOrder(id);
            setOrderExists(false);
            setExistingOrder(null);
        } else {
            // if(customizations && customizations.length > 0) {
            //     addItem();
            // } else {
            context.updateOrder(id, quantity);
            findUncomfirmedOrder();
            // }
        }
        // console.log("updateQuantity");
    };

    const getCustomizations = async () => {
        try {
            let url = window.kumbaURL + "/customization/item/" + id;
            let { data } = await axios({
                url,
                method: "GET",
            });
            if (isDigitalMenu) {
                setButtonText("SHORTLIST");
            } else if (data && data.length > 0) {
                setButtonText("ADD ITEM");
            } else {
                setButtonText("ADD ITEM");
            }

            setCustomizations(data);
        } catch (err) {
            setError(err);
        }
    };

    const submitOrder = () => {
        const price =
            (item.isHappyHr || context.restdata.isHappyHr) && item.special_price
                ? item.special_price
                : item.price;

        const data = {
            item_id: id,
            options: [],
            quantity: 1,
            itemdata: item,
            item_total: price,
            restID: item.menu,
            id: context.unconfirmedOrders.length + 1,
        };

        context.addOrders(data);
        findUncomfirmedOrder();
        // console.log("submitOrder");
    };

    const addItem = () => {
        if (context.unconfirmedCount === 0) {
            // localStorage.removeItem('selectedDeliverySlot')
            window.removeCookie('selectedDeliverySlot');
            context.setContextState({
                selectedDeliverySlot: null,
            })
        }
        if (customizations && customizations.length > 0) {
            setChooseOptions(true);
        } else {
            const slotInStorage = context.selectedDeliverySlot;
            if (restdata.type === 'homechef') {
                if (slotInStorage && slotInStorage !== 'null') {
                    if (slotInStorage !== context.activeDeliverySlot) {
                        setSlotError(true)
                    } else {
                        submitOrder();
                    }
                } else {
                    // localStorage.setItem('selectedDeliverySlot', context.activeDeliverySlot)
                    context.setContextState({
                        selectedDeliverySlot: context.activeDeliverySlot
                    })
                    window.setCookie('selectedDeliverySlot', context.activeDeliverySlot)
                    submitOrder();
                }
            } else {
                submitOrder();
            }
        }
        // submitOrder();
    };

    const addFromModal = async () => {
        context.setContextState({
            selectedDeliverySlot: null,
        });
        await context.clearOrder();
        cogoToast.success('Cleared Your Cart')
        setSlotError(false);
        return Promise.resolve();
    };

    useEffect(() => {
        let isMounted = true;
        const runFunc = async () => {
            try {
                findUncomfirmedOrder();
                // await getCustomizations();
            } catch (error) {
                console.log(error);
            }
        };

        if (item) {
            runFunc();
        } else {
            return;
        }

        return () => {
            isMounted = false;
        };
    }, [item, context.activeDeliverySlot]);

    useEffect(() => {
        setActiveSlot(context.activeDeliverySlot);
        // setSelectedSlot(`context.selectedDeliverySlot);
    }, [context]);

    const menuTypeMatches = () => {
        if (context.selectedMenuType) {
            // alert('there is menu type selected ')
            switch (context.selectedMenuType) {
                case 'delivery':
                    console.log('DELIVERY OPEN---', context.restdata.isDeliveryOpen)
                    return context.restdata.isDeliveryOpen
                case 'takeaway':
                    console.log(
                        'TAKEAWAY OPEN---',
                        context.restdata.isTakeawayOpen
                    );
                    return context.restdata.isTakeawayOpen
                default:
                    return context.restdata.isOpen
            }
        } else
            return context.restdata.isOpen
    }

    const isHappyHr = (restdata && restdata.isHappyHr) || (item && item.isHappyHr);

    const itemOptions = (
        <AppItemCustomization
            itemdata={item}
            customizations={customizations}
            optionsClose={optionsClose}
            restdata={restdata}
            isHappyHr={isHappyHr}
            menuTable={context.menuTable}
            menuTypeMatches={menuTypeMatches}
        />
    );

    const trimDescription = (desc) => {
        const maxDesLength = 150;
        if (desc.length > maxDesLength) {
            let trimmedString = desc.substr(0, maxDesLength);
            trimmedString = trimmedString.substr(
                0,
                Math.min(maxDesLength, trimmedString.lastIndexOf(" "))
            );
            return trimmedString + "...";
        } else {
            return item.description;
        }
    };

    let dine_in_check = context
        ? (!context.selectedMenuType ||
            context.selectedMenuType !== "dine-in" ||
            (context.selectedMenuType === "dine-in" && context.tableID)) &&
        !isDigitalMenu
        : false;

    const colCheck = img || outOfStock || dine_in_check;

    const eatarianColor = item.eatarian === 'veg' ? 'green' : item.eatarian === 'nonveg' ? 'red' : null;



    return (
        <div style={{ height: '100vh' }}>
            {
                state.loading && context ? <div className='text-center' style={{ paddingTop: '40vh' }}>
                    <Spinner size={25} color={window.themeColor} />
                </div> : null
            }
            {
                item ? <>
                    <div className='jumbotron' style={{
                        backgroundImage: `url(${item.image})`,
                        backgroundSize: '100% 100%',
                        height: '50vh',
                        borderRadius: '0px 0px 30px 30px',
                        backgroundRepeat: 'no-repeat'
                    }}></div>
                    <div
                        className="menu-options-modal-close"
                        onClick={() => props.history.goBack()}
                    >
                        <p className="menu-options-modal-close-text">&times;</p>
                    </div>
                    <div className="menu-options-modal-body-container">
                        <h2 className="menu-options-modal-title">{item.name}</h2>
                        <p
                            className="menu-options-modal-subtitle"
                        >
                            {item.html_description ? renderHTML(item.html_description) : renderHTML(item.description ? formatDescriptionFull(item.description) : '')}
                        </p>
                        <p
                            className="menu-options-modal-price"
                        >
                            {window.rupeeSym + item.price}
                        </p>
                    </div>

                    {itemOptions}

                </> : null
            }
        </div>
    )
});
