import axios from 'axios';
import cogoToast from 'cogo-toast';
import io from '../../socket-client';
// import { googleProvider, firebaseAuth } from "../../Firebase";
import logo from '../../img/logo_2.png';
import Cookies from 'js-cookie';

export const initialState = {
    themeColor: 'rgb(122, 193, 209)',
    backgroundColor: 'rgb(255 255 255)',
    svgTextColor: 'white',
    textColor: 'black',

    restID: Cookies.get('restID') || null,
    token: Cookies.get('token') || null,
    tableID: Cookies.get('tableID') || null,
    orderID: Cookies.get('orderID') || null,
    eventID: null,

    isRestDone: false,
    isTableDone: false,
    isOrderDone: false,
    isUserDone: false,
    isEventDone: false,

    isDigitalMenu: false,

    mainrestdata: null,
    restdata: null,
    subrestdata: null,
    categoryItems: null,
    starredItems: [],
    categoryIDItems: null,
    recommendedItems : [],
    categoriesList: [],
    categories: [],
    user: null,
    tabledata: null,
    orderdata: null,
    eventdata: null,
    orderSorted: null,
    allusers: [],
    personInfo: {},
    suborder: null,

    remTime: 0,

    refOrder: null,
    refUser: null,
    chosenTable: null,

    activeOrder: null,
    activeTable: null,

    persontotal: {},
    ordertotal: null,
    payingtotal: null,

    activated: null,
    loading: false,
    io,
    payingfor: [],

    unconfirmedCount: 0,
    unconfirmedTotal: 0.0,
    unconfirmedPayTotal: 0.0,
    unconfirmedCreditsUsed: 0.0,
    unconfirmedTax: 0.0,
    unconfirmedTaxes: 0.0,
    unconfirmedCGST: 0.0,
    unconfirmedSGST: 0.0,
    unconfirmedVAT: 0.0,
    unconfirmedServiceFee: 0.0,
    unconfirmedFees: 0.0,
    unconfirmedGrandTotal: 0.0,
    unconfirmedTaxDetails: {},
    orderCredits: 0.0,

    confirmedCount: 0,
    confirmedTotal: 0.0,
    confirmedPayTotal: 0.0,
    confirmedCreditsUsed: 0.0,
    confirmedTax: 0.0,
    confirmedTaxes: 0.0,
    confirmedCGST: 0.0,
    confirmedSGST: 0.0,
    confirmedVAT: 0.0,
    confirmedServiceFee: 0.0,
    confirmedFees: 0.0,
    confirmedTaxDetails: {},
    confirmedGrandTotal: 0.0,

    discount: 0.0,

    selectedRestID: null,
    orderExistsonTable: false,
    userOrderExistsOnTable: false,

    rzpid: null,
    creditsUsed: null,

    readyToCheckout: false,

    backendURL: window.kumbaURL,

    customerFees: window.customerFees,

    vegOnly: true,
    eggOnly: true,
    meatOnly: true,
    alcoholicOnly: true,
    nonalcoholicOnly: true,

    isLoggedin: false,
    email: null,

    isStatic: false,

    menuType: null,
    selectedMenuType: sessionStorage.getItem('menuType') || null,
    pos_menutype: [],
    active_pos_menutype: 'All',

    showCheckoutDialog: false,

    showPopup: false,
    popupText: null,
    popupType: null,

    selectedAddress: null,
    activeDeliverySlot: null,
    selectedDeliverySlot: Cookies.get('selectedDeliverySlot') || null,
    deliveryCharge: 0.0,
    partner_name : null,

    showConfirm: false,
    confirmMessage: null,

    selectedSlotRange: null,
    deliveryslots: [],
    isAddressDone : false,

    pos_item_menugrps: {},
    menugrps: {},

    listingID: null,
    listingdata: null,
    showLogin: false,
    loginRedirect: null,

    PWAPopup: false,
    orderOfferApplied: false,
    orderOfferDiscount: 0,
    orderOfferID: null,

    paymentLoading: false,
    paymentCancelled :false,

    socketConnected : false,
    userLoyaltyTier: null,
    waitingForApproval : false,
};

const getURLInfo = () => {
    let [fullloc, domain, path] = /:\/\/([^\/]+)(.*)/.exec(
        window.location.href
    );

    let [subdomain, maindomain, ext] = domain.split('.');

    return {
        fullloc,
        domain,
        path,
        subdomain,
        maindomain,
        ext,
    };
};

const getBrowserInfo = {
    options: [],
    header: [
        navigator.platform,
        navigator.userAgent,
        navigator.appVersion,
        navigator.vendor,
        window.opera,
    ],
    dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' },
    ],
    databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
    ],
    init: function () {
        var agent = this.header.join(' '),
            os = this.matchItem(agent, this.dataos),
            browser = this.matchItem(agent, this.databrowser);

        return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
        var i = 0,
            j = 0,
            html = '',
            regex,
            regexv,
            match,
            matches,
            version;

        for (i = 0; i < data.length; i += 1) {
            regex = new RegExp(data[i].value, 'i');
            match = regex.test(string);
            if (match) {
                regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                matches = string.match(regexv);
                version = '';
                if (matches) {
                    if (matches[1]) {
                        matches = matches[1];
                    }
                }
                if (matches) {
                    matches = matches.split(/[._]+/);
                    for (j = 0; j < matches.length; j += 1) {
                        if (j === 0) {
                            version += matches[j] + '.';
                        } else {
                            version += matches[j];
                        }
                    }
                } else {
                    version = '0';
                }
                return {
                    name: data[i].name,
                    version: parseFloat(version),
                };
            }
        }
        return { name: 'unknown', version: 0 };
    },
};

export default class AppContext_Controller {
    static setContextState(objectData) {
        // console.log('setContextState ---', objectData)
        this.setState(objectData);
    }

    static activateremTimer(timeInSec, timerOut, rzpid, creditsUsed) {
        this.setState({
            remTime: timeInSec,
            rzpid: rzpid,
            creditsUsed: creditsUsed,
        });
        this.timeout = timerOut;
        this.interval = setInterval(() => {
            this.setState({ remTime: this.state.remTime - 1 });
        }, 1000);
    }

    static finalizeOrderNow() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (this.interval) {
            clearTimeout(this.interval);
        }
        this.setState({ remTime: 0, rzpid: null, creditsUsed: null });
    }

    static readyToPay(status) {
        // console.log('setting readyToCheckout to -- ', status)
        if (this.state.isStatic) {
            this.setState({ readyToCheckout: status });
        }
    }

    static setToken(token) {
        this.setState({ token: token });
        window.setCookie('token', token);
    }

    static getRestaurantDetails(restID) {
        if (this.state.restID && !this.state.socketConnected) {
            let url = window.kumbaURL + '/restaurant/' + restID;

            io.socket.request(
                {
                    url,
                    method: 'GET',
                },
                function (jsondata, jwres) {
                    if (jwres.error) {
                        ////console.log(jwres.error);
                    } else {
                        this.setState({socketConnected : true})
                        ////console.log(jsondata);
                        //this.setState({restdata:jsondata, isRestDone : true});
                        //localStorage.setItem('restdata', JSON.stringify(jsondata));
                    }
                }.bind(this)
            );
        }
    }

    static togglePWAInstall(value) {
        this.setState({
            PWAPopup: value,
        });
    }

    static setRestaurantID(restID) {
        // console.log('setting restID to --', restID);
        window.setCookie('restID', restID);
        this.setState({ restID: restID });
        // return Promise.resolve(restID);
    }

    static setListingID(listingID) {
        window.setCookie('listingID', listingID);
        this.setState({ listingID: listingID, tabledata: null });
        //return Promise.resolve(listingID);
    }

    static loginFromPopup(redirect, show) {
        // alert("I have been called for "+ redirect)
        // console.log('OPENING LOGIN', show);
        this.setState({
            showLogin: show,
            loginRedirect: redirect || null,
        });
    }

    static setDigitalMenu(status) {
        var isDigitalMenu =
            status ||
            (this.state.restdata &&
                this.state.restdata.subscription_package &&
                this.state.restdata.subscription_package === 'digital-menu') ||
            (this.state.tabledata && !this.state.tabledata.acceptOrders);
        this.setState({ isDigitalMenu: isDigitalMenu });
        // console.log('is digital menu ---', status);
    }

    static setStaticMenu(status) {
        //console.log('setting static to --', status);
        window.setCookie('isStatic', status);
        this.setState({
            isStatic: status,
        });
        if (status) {
            window.removeCookie('tableID');
            this.setState({
                tabledata: null,
            });
        }
    }

    static setMenuType(menuType) {
        console.log(
            `%c SET MENU TYPE ${menuType}`,
            'color: green; font-size: 20px'
        );
        sessionStorage.setItem('menuType', menuType);
        this.setState({ selectedMenuType: menuType });
       
    }

    static setMultipleCookies(objData) {
        // console.log('setMultipleCookies ---', objData)
        let keys = Object.keys(objData);
        //let values = Object.values(objData);
        keys.forEach((key, i) => {
            window.setCookie(key, objData[key]);
        });
    }

    static async fetchRestaurantData(restID) {
        let { fullloc, domain, path, subdomain, maindomain, ext } =
            getURLInfo();
        // console.log("Subdomain = ", subdomain);
        try {
            let url = window.kumbaURL + '/restaurant/' + restID;
            //let header={"foodbots-header":this.state.token};
            const response = await axios({
                url,
                method: 'GET',
                //headers : header,
            });

            var restdata = response.data;
            // console.log("Identifier " + restdata.web_identifier + "-" + subdomain);
            if (
                !fullloc.includes('localhost') &&
                !fullloc.includes(':3000') &&
                restdata.web_identifier
            ) {
                //TODO : Throw popup message Redirect (Redirecting to the restaurant's webpage, Please wait..);
                let link = null;
                // alert(domain + " " + restdata.web_identifier + " " + subdomain + " " + restdata.web_identifier.includes('.') + " " + path);
                if(restdata.web_identifier.includes('.') && restdata.web_identifier !== domain) {
                    this.popup(
                        "Redirecting to the restaurant's webpage, Please wait..",
                        'info'
                    );
                    link =
                    'https://' +
                    restdata.web_identifier +
                    path;
                    window.location.replace(link);
                } else if(!restdata.web_identifier.includes('.') && restdata.web_identifier !== subdomain) {
                    this.popup(
                        "Redirecting to the restaurant's webpage, Please wait..",
                        'info'
                    );
                    let linkPart = ext
                        ? maindomain + '.' + ext
                        : subdomain + '.' + maindomain;
                    link =
                        'https://' +
                        restdata.web_identifier +
                        '.' +
                        linkPart +
                        path;
                    window.location.replace(link);
                } else {
                    this.getRestaurantDetails(restID);
                    return await this.getCategoryItems(restdata);
                }
            } else {
                this.getRestaurantDetails(restID);
                return await this.getCategoryItems(restdata);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     *
     * @param {string} popupText
     * @param {string} popupType
     */
    static popup(popupText, popupType) {
        //console.log('========== WORKING =============')
        this.setState({
            showPopup: !this.state.showPopup,
            popupText,
            popupType,
        });
    }

    static async loadRestaurantDetails() {
        // console.log('geting rest details', this.state.restID);
        if (this.state.restID) {
            //const hide = cogoToast.loading("Loading", { hideAfter: 0 });
            // this.setState({ restdata: null });
            const data = await this.fetchRestaurantData(this.state.restID);

            this.setState({
                themeColor: data.restdata.svgColor || '#7ab1c1',
                backgroundColor: data.restdata.backgroundColor || 'white',
                svgTextColor: data.restdata.svgTextColor || 'white',
                textColor: data.restdata.textColor,
                highlightColor: data.restdata.highlightColor || '#EBF5F2',
                selectedMenuType: this.state.selectedMenuType
                    ? this.state.selectedMenuType
                    : !data.restdata.allowed_menu_types
                    ? 'dine-in'
                    : data.restdata.allowed_menu_types &&
                      data.restdata.allowed_menu_types.length === 1
                    ? data.restdata.allowed_menu_types[0]
                    : null,
            });

            // addeding restaurant colors to the window object
            window.backgroundColor = data.restdata.backgroundColor || 'white';
            window.themeColor = data.restdata.svgColor || '#7ab1c1';
            window.svgTextColor = data.restdata.svgTextColor || 'white';
            window.textColor = data.restdata.textColor;
            window.highlightColor = data.restdata.highlightColor || '#EBF5F2';

            // setting background color to body tag
            document.querySelector('body').style.backgroundColor =
                data.restdata.backgroundColor || 'white';

            //setting font-family to body and html tag
            document.querySelector('body').style.fontFamily = 'Montserrat';
            document.querySelector('html').style.fontFamily = 'Montserrat';

            // console.log('res data ==', data, 'CONTEXT --', this.state);
            var isDigitalMenu =
                this.state.isDigitalMenu ||
                (data.restdata.subscription_package &&
                    data.restdata.subscription_package === 'digital-menu');
            // console.log(isDigitalMenu);
            if (
                data.restdata.sub_restaurants &&
                data.restdata.sub_restaurants.length > 0
            ) {
                const pArray = data.restdata.sub_restaurants.map(
                    async (subrest) => {
                        return await this.fetchRestaurantData(
                            subrest.restaurant_id
                        );
                    }
                );
                const subrestdata = await Promise.all(pArray);
                //subrestdata = subrestdata;
                this.setState({
                    categoryItems: data.categoryItems,
                    categoryIDItems: data.categoryIDItems,
                    recommendedItems : data.recommendedItems,
                    mainrestdata: data,
                    restdata: data.restdata,
                    starredItems: data.starredItems,
                    isRestDone: true,
                    categories: data.categories,
                    categoriesList: data.categoriesList,
                    pos_item_menugrps: data.pos_item_menugrps,
                    menugrps: data.menugrps,
                    categoryTable: data.categoryTable,
                    menuTable: data.menuTable,
                    subrestdata: subrestdata,
                    isDigitalMenu: isDigitalMenu,
                    loading: false,
                });
                // console.log("subrest found");
                this.setDigitalMenu(false);
                return new Promise((resolve, reject) => {
                    return resolve('SUbrest found');
                });
            } else {
                // console.log(data);
                // console.log("No subrest found");
                this.setState({
                    categoryItems: data.categoryItems,
                    categoryIDItems: data.categoryIDItems,
                    recommendedItems : data.recommendedItems,
                    mainrestdata: data,
                    restdata: data.restdata,
                    starredItems: data.starredItems,
                    isRestDone: true,
                    categories: data.categories,
                    categoriesList: data.categoriesList,
                    pos_item_menugrps: data.pos_item_menugrps,
                    menugrps: data.menugrps,
                    categoryTable: data.categoryTable,
                    menuTable: data.menuTable,
                    subrestdata: null,
                    isDigitalMenu: isDigitalMenu,
                    loading: false,
                });
                // console.log(this.state.isRestDone);
                this.setDigitalMenu(false);
                return new Promise((resolve, reject) => {
                    return resolve('No subrest found');
                });
            }
            //hide();
        } else {
            // console.log("No rest found");
            return new Promise((resolve, reject) => {
                return resolve('No rest found');
            });
        }
    }

    static async loadListingDetails() {
        // console.log("Subdomain = ", subdomain);
        try {
            let url = window.kumbaURL + '/listing/' + this.state.listingID;
            //let header={"foodbots-header":this.state.token};
            const response = await axios({
                url,
                method: 'GET',
                //headers : header,
            });

            // addeding restaurant colors to the window object
            window.backgroundColor = 'white';
            window.themeColor = '#7ab1c1';
            window.svgTextColor = 'white';
            window.textColor = 'black';
            window.highlightColor = '#EBF5F2';

            // setting background color to body tag
            document.querySelector('body').style.backgroundColor = 'white';

            //setting font-family to body and html tag
            document.querySelector('body').style.fontFamily = 'Montserrat';
            document.querySelector('html').style.fontFamily = 'Montserrat';

            var listingdata = response.data;
            this.setState({ listingdata: listingdata });

            return Promise.resolve(listingdata);
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async getCategoryItems(restdata) {
        return new Promise((resolve, reject) => {
            var categoryItems = {};
            var categoryIDItems = {};
            var starredItems = [];
            var categoriesList = [];
            let categoryTable = {};
            let menuTable = {};
            let pos_item_menugrps = {};
            let menugrps = {};
            let recommendedItems = [];
            var toggle = {};
            if (
                restdata &&
                restdata.menu_items &&
                restdata.menu_items.length > 0
            ) {
                var menuItems = restdata.menu_items.sort((a, b) => {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                });
                let tax_charges = restdata.taxes;
                // console.log('TAX --', tax_charges);
                menuItems.forEach((item) => {
                    var subcat = item.sub_category || 'All';
                    var subcat_id = item.sub_category_id || 'All';

                    if(item.isRecommended) {
                        recommendedItems.push(item);
                    }

                    menuTable[item.item_id] = item;

                    menuTable[item.item_id].item_tax_details = item.tax_group_id
                        ? tax_charges.filter(
                              (x) => x.tax_group_id === item.tax_group_id
                          )
                        : [];

                    if (!categoryItems[item.category]) {
                        categoryItems[item.category] = {};
                    }

                    if (!categoryIDItems[item.category_id]) {
                        categoryIDItems[item.category_id] = {};
                    }

                    if (!categoryItems[item.category][subcat]) {
                        categoryItems[item.category][subcat] = [];
                    }

                    if (!categoryIDItems[item.category_id][subcat_id]) {
                        categoryIDItems[item.category_id][subcat_id] = [];
                    }

                    categoryItems[item.category][subcat].push(item);
                    categoryIDItems[item.category_id][subcat_id].push(item);

                    if (item.isStarred) {
                        starredItems.push(item);
                    }
                });

                if (restdata.categoryList && restdata.categoryList.length > 0) {
                    categoriesList = restdata.categoryList.sort((a, b) =>
                        a.orderNum && b.orderNum
                            ? a.orderNum - b.orderNum
                            : a.orderNum
                            ? -1
                            : 1
                    );
                    // console.log("CONTEXT getCategoriesList :::::::   ", categoriesList);
                    for (let cat of categoriesList) {
                        categoryTable[cat.id] = {
                            ...cat,
                            itemCount: {
                                'dine-in': 0,
                                takeaway: 0,
                                delivery: 0,
                                null: 0,
                            },
                        };
                        categoryIDItems[cat.id] &&
                            cat.isAvailable &&
                            Object.entries(categoryIDItems[cat.id]).forEach(
                                ([subcat, itemsList]) => {
                                    //(subcat === "All" || (categoryTable[subcat] && categoryTable[subcat].isAvailable)) &&
                                    itemsList.forEach((val) => {
                                        if (val.isAvailable) {
                                            for (let menu_type of val.menu_type) {
                                                if (menu_type) {
                                                    categoryTable[cat.id]
                                                        .itemCount[menu_type]++;
                                                    if (
                                                        !pos_item_menugrps[
                                                            menu_type
                                                        ]
                                                    ) {
                                                        pos_item_menugrps[
                                                            menu_type
                                                        ] = [];
                                                    }

                                                    let mentype =
                                                        cat.menu_group ||
                                                        val.pos_menutype;

                                                    if (
                                                        cat.menu_group_description
                                                    ) {
                                                        menugrps[mentype] =
                                                            cat.menu_group_description;
                                                    }
                                                    if (
                                                        pos_item_menugrps[
                                                            menu_type
                                                        ].indexOf(mentype) ===
                                                        -1
                                                    ) {
                                                        pos_item_menugrps[
                                                            menu_type
                                                        ].push(mentype);
                                                    }
                                                } else {
                                                    categoryTable[cat.id]
                                                        .itemCount['null']++;
                                                }
                                            }
                                            // categoryTable[cat.id].itemCount;
                                        }
                                        //categoryTable[cat.id].pos_menugrp = val.pos_menutype;
                                    });
                                }
                            );
                    }
                } else {
                    categoriesList = [];
                }

                // console.log(categoryTable);

                var preCatList = restdata.categories
                    ? JSON.parse(restdata.categories)
                    : [];
                var currentCatList = categoryItems
                    ? Object.keys(categoryItems).sort()
                    : [];

                var validList = preCatList
                    ? preCatList.filter((x) => currentCatList.indexOf(x) >= 0)
                    : []; //Categories in existing list, missing in current list
                var missingList = currentCatList
                    ? currentCatList.filter((x) => preCatList.indexOf(x) < 0)
                    : []; //Categories in current list, missing from exisiting list

                var categories = validList.concat(missingList);

                var data = {
                    restdata,
                    categoryItems,
                    categoryIDItems,
                    recommendedItems,
                    categoriesList,
                    categoryTable,
                    menuTable,
                    pos_item_menugrps,
                    menugrps,
                    categories,
                    starredItems,
                };
                return resolve(data);
            } else {
                var data = {
                    restdata,
                    categoryItems,
                    categoryIDItems,
                    recommendedItems,
                    categoriesList,
                    categoryTable,
                    menuTable,
                    pos_item_menugrps,
                    menugrps,
                    categories: [],
                    starredItems,
                };
                return resolve(data);
            }
        });
    }

    static async googlelogin(googleUser) {
        try {
            var id_token = googleUser.getAuthResponse().id_token;
            const response = await axios({
                url: window.kumbaURL + '/googlelogin',
                method: 'POST',
                data: {
                    id_token: id_token,
                },
            });
            var data = response.data;
            var token = data.token;
            this.setState({ token: data.token });
            window.setCookie('token', data.token);
            return await this.getUser();
        } catch (e) {
            // alert("Couldn't login. Please try again.");
            this.popup("Couldn't login. Please try again.", 'error');
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async googlesignout() {
        this.auth2.signOut();
        this.setState({
            user: null,
            token: null,
            redirect: true,
            restdata: null,
            isRestDone: false,
            isOrderDone: false,
            isUserDone: false,
            isTableDone: false,
        });
    }

    static async login(email, password) {
        //e.preventDefault()
        this.setState({ loading: true });
        if (email && password) {
            try {
                const response = await axios({
                    url: window.kumbaURL + '/auth/signin',
                    method: 'POST',
                    data: {
                        email: email,
                        password: password,
                    },
                });
                var data = response.data;
                var token = data.token;
                this.setState({ token: data.token });
                window.setCookie('token', data.token);
                this.setState({ loading: false });
                return await this.getUser();
            } catch (err) {
                // alert("Couldn't login. Please try again.");
                this.popup("Couldn't login. Please try again.", 'error');
                this.setState({ loading: false });
                return new Promise((resolve, reject) => {
                    return reject(err);
                });
            }
        } else {
            return new Promise((resolve, reject) => {
                return resolve(null);
            });
        }
    }

    static async logout() {
        //e.preventDefault();
        try {
            await axios({
                url: window.kumbaURL + '/auth/signout',
                method: 'GET',
            });
            this.setState({ ...initialState, loading: false });
            window.removeAllCookies();
        } catch (err) {
            // alert("Couldn't log out. Please try again.");
            this.popup("Couldn't log out. Please try again.", 'error');
        }
    }

    static async signup(values) {
        var userdata = null;
        var token = null;

        this.setState({ loading: true });

        if (values.email && values.password && !this.state.isUserDone) {
            try {
                let url = window.kumbaURL + '/auth/signup';
                const response = await axios({
                    url,
                    method: 'POST',
                    data: {
                        email: values.email,
                        password: values.password,
                        role: 'user',
                    },
                });

                var data = response.data;

                userdata = data.user;
                token = data.token;

                this.setState({ token: token });

                window.setCookie('token', token);

                const userResponse = await axios({
                    url: window.kumbaURL + '/profile',
                    method: 'POST',
                    headers: { 'foodbots-header': token },
                    data: {
                        first_name: values.firstname,
                        last_name: values.lastname,
                        email: values.email,
                    },
                });

                if (values.img) {
                    const formData = new FormData();
                    formData.append('image', values.img);
                    const imgResponse = await axios({
                        url:
                            window.kumbaURL +
                            'image/profile/' +
                            userdata.profile.user_id,
                        method: 'POST',
                        headers: { 'foodbots-header': token },
                        data: formData,
                    });
                }
                this.setState({ loading: false });
                return await this.getUser();
            } catch (err) {
                //alert(JSON.stringify(err));
                this.setState({ loading: false });
                //console.log(err);
                // alert("Something went wrong. Please try again.");
                this.popup('Something went wrong. Please try again.', 'error');
            }
        }
    }

    static updateToken(token) {
        this.setState({ token: token });
    }

    static selectRest(restID) {
        this.setState({ selectedRestID: restID });
    }

    static async getUser() {
        if (this.state.token) {
            try {
                // this.setState({ loading: true });
                let url = window.kumbaURL + '/profile/getuser';
                let header = { 'foodbots-header': this.state.token };
                //console.log(header);
                const res = await axios({
                    url,
                    method: 'GET',
                    headers: header,
                });

                var jsondata = res.data;
                console.log('USER FOUND ---', jsondata);
                this.setState({
                    user: jsondata,
                    isUserDone: true,
                    loading: false,
                });

                await this.updateUserStatusOnTable();
                return Promise.resolve(jsondata);
            } catch (err) {
                // this.setState({ loading: false });
                return Promise.reject(err);
            }
        } else {
            this.setState({ loading: false });
            return Promise.resolve(null);
        }
    }

    // async processUserOrder() {
    //   var jsondata = this.state.user;
    //   try {
    //     if (jsondata.pending_orders && jsondata.pending_orders[0]) {
    //       if(this.state.tableID) {
    //         if (jsondata.pending_orders[0].table_id === this.state.tableID) {
    //           return await this.updateTable(jsondata.pending_orders[0].order_id, jsondata.pending_orders[0].table_id);
    //         } else {
    //           return await this.updateTable(jsondata.pending_orders[0].order_id,jsondata.pending_orders[0].table_id);
    //         }
    //       } else if(this.state.restID) {

    //       }
    //     } else if (this.state.refOrder) {
    //       return await this.addPersonToOrder(this.state.refOrder,this.state.refUser);
    //     } else if (this.state.tableID) {
    //       return await this.updateTable(null, this.state.tableID);
    //     } else {
    //       this.setState({ loading: false });
    //       return new Promise((resolve, reject) => {
    //         return resolve(null);
    //       });
    //     }
    //   } catch (e) {
    //     this.setState({ loading: false });
    //     return new Promise((resolve, reject) => {
    //       return reject(e);
    //     });
    //   }
    // }

    static handleBarcodeScan(data) {
        this.setState({ restID: data });
        this.state.loadRestaurantDetails();
    }

    static async updateEmailToken(email, token) {
        //AsyncStorage.setItem('token' , token);
        //console.log(token);
        window.setCookie('token', token);

        this.setState({ email: email, token: token });
        return await this.getUser();
    }

    static async getTableDetails(tableID) {

        console.log('SETTING TABLE DETAILS ---', tableID);
        
        let { fullloc, domain, path, subdomain, maindomain, ext } =
            getURLInfo();

        try {
            window.setCookie('tableID', tableID);
            // this.setState({ loading: true });
            let url = window.kumbaURL + '/resttables/' + tableID;
            const response = await axios({
                url,
                method: 'GET',
                //headers : header,
            });

            var jsondata = response.data;
            this.joinTable();

            let { mainrestdata, subrestdata } = this.state;
            let rests = [];
            if (subrestdata && subrestdata.length > 0) {
                rests = subrestdata
                    .map((x) => x.restdata.restaurant_id)
                    .concat([mainrestdata.restdata.restaurant_id]);
            } else if (mainrestdata) {
                rests = [mainrestdata.restdata.restaurant_id];
            } else {
                rests = [];
            }
            // console.log(rests);

            if (
                !mainrestdata ||
                (mainrestdata &&
                    rests.indexOf(jsondata.restaurant_id.restaurant_id) !== -1)
            ) {
                //is in (mainrest + subrecfr
                console.log('Reached here if');
                window.setCookie(
                    'restID',
                    jsondata.restaurant_id.restaurant_id,
                    360
                );
                
                // console.log(jsondata.orders);
                // let userOrderExistsOnTable = false;
                // let orderExistsonTable =
                //     !jsondata.allowMultipleOrders &&
                //     jsondata.orders &&
                //     jsondata.orders.length > 0;
                // if (orderExistsonTable && this.state.isUserDone) {
                //     jsondata.orders.forEach((order) => {
                //         if (order.user === this.state.user.user_id) {
                //             userOrderExistsOnTable = true;
                //         }
                //     });
                // } else {
                //     userOrderExistsOnTable = false;
                // }

                // var isDigitalMenu =
                //     this.state.isDigitalMenu ||
                //     !jsondata.acceptOrders ||
                //     (orderExistsonTable && !userOrderExistsOnTable);
                // if (orderExistsonTable && !userOrderExistsOnTable) {
                //     //cogoToast.info("This table is occupied. You can view the menu but cannot place orders at this table", { hideAfter: 5 });
                // }

                this.setState({
                    tableID: tableID,
                    tabledata: jsondata,
                    restID: jsondata.restaurant_id.restaurant_id,
                });

                // alert("Set table " + tableID);

                //// console.log('Working till here', this.state.tableID);
                window.removeCookie('orderID');
                await this.updateUserStatusOnTable();

                //// console.log('Working till here too', this.state.tableID);

                var isDigitalMenu =
                    this.state.isDigitalMenu ||
                    !jsondata.acceptOrders ||
                    (this.state.orderExistsonTable &&
                        !this.state.userOrderExistsOnTable);

                this.setState({
                    isTableDone: true,
                    isDigitalMenu: isDigitalMenu,
                    // loading: false,
                });

                return Promise.resolve(jsondata);
            } else {
                // TODO : Throw error redirect ("The table doesn't belong to this restaurant. Please wait while we redirect")
                //alert("The table doesn't belong to this restaurant. Please wait while we redirect");
                //window.location.replace(process.env.REACT_APP_KUMBA_URL + path);
                console.log('Reached here else');
                window.removeCookie('tableID');
                return Promise.resolve();
            }
        } catch (err) {
            console.log(err);
            window.removeCookie('tableID');
            // this.setState({ loading: false });
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    static async checkUserOrderStatus(order_id) {
        try {
            let url = window.kumbaURL +
                    `/mainorders/checkuser/${order_id}/${this.state.user.user_id}`;
            const userOrderStatus = await axios(
                url,
                {
                    headers: {
                        'foodbots-header': this.state.token,
                    },
                }
            );
           

            return userOrderStatus.data.userExists;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static async requestPermissionToOrder(table_id) {
        try {
            const response = await axios({
                url : window.kumbaURL +'/resttables/orderpermission/' + table_id,
                // headers: { 'foodbots-header': this.state.token}                
            });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    static joinTable() {
            if (this.state.tableID && !this.state.tabledata) {
                let url =
                    window.kumbaURL +
                    '/socket/resttables/join/' +
                    this.state.tableID;

                io.socket.request(
                    {
                        url,
                        method: 'POST',
                    },
                    function (jsondata, jwres) {
                        if (jwres.error) {
                            ////console.log(jwres.error);
                        } else {
                            console.log('TABLE ROOM UPDATE --', jsondata)
                            console.log("%cTABLE DATA UPDATED --", "color: red; font-size: 20px");
                             
                            // this.requestPermissionToOrder(this.state.tableID).then(data => {
                            //     console.log('APPROVAL DATA ---', data)
                            // });
                            
                        }
                    }
                );
            }
    }

    static async updateUserStatusOnTable() {
        console.log('Executing updateUserStatusOnTable');
        try {
            let jsondata = this.state.tabledata;
            console.log('TABLE DATA --', jsondata);
            let orderExistsonTable = false;
            let userOrderExistsOnTable = false;
            

            if (jsondata && jsondata.orders && jsondata.orders.length > 0) {
                console.log('Order exists');
                orderExistsonTable = true;
                let order = jsondata.orders[0];
                if (this.state.user) {
                    console.log('User exists');

                    // console.log('Adding user to order');
                    // let data = await this.addPersonToOrder(
                    //     order.order_id,
                    //     null
                    // );

                    const userIsPartOfOrder = await this.checkUserOrderStatus(
                        order.order_id
                    );
                    console.log('USER IS PART --', userIsPartOfOrder);
                    //  alert(`USER IS PART -- ${userIsPartOfOrder}`)
                    if (userIsPartOfOrder) {
                        this.setState({ orderID: order.order_id });
                        window.setCookie('orderID', order.order_id, 360);
                        userOrderExistsOnTable = true;
                        await this.getOrder();
                        this.setState({
                            orderExistsonTable: orderExistsonTable,
                            userOrderExistsOnTable: userOrderExistsOnTable,
                            permissionToOrder: true,
                            waitingForApproval : false,
                        });
                    } else {
                        userOrderExistsOnTable = false;
                        
                        this.setState({
                            permissionToOrder: false,
                            orderID: null,
                            orderExistsonTable: orderExistsonTable,
                            userOrderExistsOnTable: userOrderExistsOnTable,
                            waitingForApproval: false,
                        });
                        alert("Table is currently occupied. Please contact restaurant for support");
                        // this.setState({});
                        // this.popup(
                        //     'Unable to place order. This table is already occupied. Please contact the restaurant',
                        //     'warning'
                        // );
                    }
                    return await this.loadRestaurantDetails();
                } else {
                    //*TODO - Add a popup with a login button
                    // this.popup(
                    //     'Unable to place order. Please login',
                    //     'warning'
                    // );
                    console.log('No user exists');
                    userOrderExistsOnTable = false;
                    this.setState({
                        orderExistsonTable: orderExistsonTable,
                        userOrderExistsOnTable: userOrderExistsOnTable,
                        permissionToOrder : false,
                        waitingForApproval : false,
                        orderID : null
                    });
                    return await this.loadRestaurantDetails();
                }
            } else {
                //*TODO -
                console.log('No orders on table');
                orderExistsonTable = false;
                userOrderExistsOnTable = false;
                let waitingForApproval = false;

                if(jsondata && jsondata.requiresApproval) {
                    waitingForApproval = true;
                }

                this.setState({
                    orderExistsonTable: orderExistsonTable,
                    userOrderExistsOnTable: userOrderExistsOnTable,
                    orderID: null,
                    permissionToOrder : true,
                    waitingForApproval : waitingForApproval
                });
                return await this.loadRestaurantDetails();
            }
        } catch (e) {
            // this.setState({
            //     orderExistsonTable: false,
            //     userOrderExistsOnTable: false,
            // });
            console.log(e);
            return Promise.reject(e);
        }
    }

    static async getEventDetails(eventID) {
        try {
            this.setState({ loading: true });
            let url = window.kumbaURL + '/events/' + eventID;
            const response = await axios({
                url,
                method: 'GET',
            });

            var jsondata = response.data;
            // console.log("Table data", jsondata);
            window.setCookie('restID', jsondata.rest.restaurant_id);
            //window.setCookie("tableID", tableID, 60);

            this.setState({
                eventID: eventID,
                eventdata: jsondata,
                restID: jsondata.rest.restaurant_id,
                isEventDone: true,
            });
            await this.loadRestaurantDetails();
            this.setState({ loading: false });
            return new Promise((resolve, reject) => {
                return resolve(jsondata);
            });
        } catch (err) {
            //console.log(err);
            this.setState({ loading: false });
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    static async changeTableDetails(orderID, oldTable, newTable) {
        try {
            if (orderID) {
                let url = window.kumbaURL + '/changetable';
                let header = { 'foodbots-header': this.state.token };
                const response = await axios({
                    url,
                    method: 'PUT',
                    headers: header,
                    data: {
                        old_table: oldTable,
                        new_table: newTable,
                        order_id: orderID,
                    },
                });
                var jsondata = response.data;
                this.setState({ orderID: orderID });
                window.setCookie('orderID', orderID);
                await this.getOrder();
            } else if (this.state.orderID) {
                let url = window.kumbaURL + '/changetable';
                let header = { 'foodbots-header': this.state.token };
                const response = await axios({
                    url,
                    method: 'PUT',
                    headers: header,
                    data: {
                        old_table: this.state.tableID || null,
                        new_table: newTable,
                        order_id: this.state.orderID,
                    },
                });
                var jsondata = response.data;
                await this.getOrder();
            }
            return await this.getTableDetails(newTable);
        } catch (err) {
            //console.log(err);
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    static async recordScan() {
        try {
            let module = getBrowserInfo.init();
            let url = window.kumbaURL + '/recordscan/';
            io.socket.request(
                {
                    url,
                    method: 'POST',
                    data: {
                        tableID: this.state.tableID,
                        restID: this.state.restID,
                        osName: module.os ? module.os.name : null,
                        osVersion: module.os ? module.os.version : null,
                        browserName: module.browser
                            ? module.browser.name
                            : null,
                        browserVersion: module.browser
                            ? module.browser.version
                            : null,
                    },
                    //headers : header,
                },
                function (resData, jwres) {
                    if (jwres.error) {
                        //console.log(jwres.error);
                        return new Promise((resolve, reject) => {
                            return reject(jwres.error);
                        });
                    } else {
                        return new Promise((resolve, reject) => {
                            return resolve(resData);
                        });
                    }
                }
            );
        } catch (err) {
            //console.log(err);
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    static clearData() {
        window.removeAllCookies();
        this.setState(initialState);
    }

    static clearOrderData() {
        // localStorage.removeItem('selectedDeliverySlot');
        // window.removeCookie('selectedDeliverySlot');
        // this.unsubscribePush(this.state.orderID);

        window.removeCookie('orderID');
        window.removeCookie('tableID');
        //window.removeCookie("restID");
        //window.removeCookie("unconfirmedOrders");
        //this.unconfirmedOrders = [];
        //this.clearOrder();
        //this.updateUnconfirmedCount();

        this.setState({
            //...this.state,
            tableID: null,
            orderID: null,
            eventID: null,

            isTableDone: false,
            isOrderDone: false,
            isEventDone: false,

            tabledata: null,
            orderdata: null,
            eventdata: null,
            orderSorted: null,
            allusers: [],
            personInfo: {},
            suborder: null,

            remTime: 0,

            refOrder: null,
            refUser: null,
            chosenTable: null,

            activeOrder: null,
            activeTable: null,

            persontotal: {},
            ordertotal: null,
            payingtotal: null,

            payingfor: [],

            orderCredits: 0.0,

            confirmedCount: 0,
            confirmedTotal: 0.0,
            confirmedPayTotal: 0.0,
            confirmedCreditsUsed: 0.0,
            confirmedTax: 0.0,
            confirmedTaxes: 0.0,
            confirmedCGST: 0.0,
            confirmedSGST: 0.0,
            confirmedVAT: 0.0,
            confirmedServiceFee: 0.0,
            confirmedFees: 0.0,
            confirmedTaxDetails: {},
            confirmedGrandTotal: 0.0,

            discount: 0.0,

            orderExistsonTable: false,
            userOrderExistsOnTable: false,

            rzpid: null,
            creditsUsed: null,

            showPopup: false,
            showCheckoutDialog: false,

            readyToCheckout: false,

            // selectedAddress: null,
            // activeDeliverySlot: null,
            // selectedDeliverySlot: null,
            // isAddressDone : false,
            // deliveryCharge : 0,

            showConfirm: false,
            confirmMessage: null,
        });
    }

    static async updateTable(orderID, tableID, restID) {
        try {
            if (this.state.token) {
                if (tableID) {
                    await this.getTableDetails(tableID);
                }

                if (restID) {
                    window.setCookie('restID', restID);
                    this.setState({ restID: restID });
                    await this.loadRestaurantDetails();
                }

                if (!orderID) {
                    this.setState({ tableOccupied: false, loading: false });
                    this.setState({ orderID: null });
                    return new Promise((resolve, reject) => {
                        return resolve(null);
                    });
                } else {
                    window.setCookie('orderID', orderID);
                    this.setState({ orderID: orderID, tableOccupied: false });
                    return await this.getOrder();
                }
            } else {
                this.setState({ loading: false });
                return new Promise((resolve, reject) => {
                    return reject(new Error('No user found'));
                });
            }
        } catch (err) {
            this.setState({ loading: false });
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    static addShare(orderID, userID) {
        this.setState({ refOrder: orderID, refUser: userID });
    }

    static async createOrder() {
        if (
            this.state.token &&
            this.state.isRestDone &&
            !this.state.isOrderDone
        ) {
            try {
                let url = window.kumbaURL + '/mainorders/';
                let header = { 'foodbots-header': this.state.token };
                const response = await axios({
                    url,
                    method: 'POST',
                    headers: header,
                    data: {
                        order_type: 'dine-in',
                        user: this.state.user.user_id,
                        rest: this.state.restID,
                        table_id: this.state.tableID || null,
                    },
                });
                var data = response.data;
                //alert("Created new order : " + data.order_id);
                window.setCookie('orderID', data.order_id);
                this.setState({ orderID: data.order_id }, () => {
                    this.getOrder();
                });
                return new Promise((resolve, reject) => {
                    return resolve(data);
                });
            } catch (e) {
                return new Promise((resolve, reject) => {
                    return reject(e);
                });
            }
        } else {
            return new Promise((resolve, reject) => {
                return resolve(null);
            });
        }
    }

    static removeItemInOrder(item_id, combo_id) {
        this.unconfirmedOrders = this.unconfirmedOrders.filter(
            (eachorder) =>
                (item_id && eachorder.item_id !== item_id) ||
                (combo_id && eachorder.combo_id === combo_id)
        );
        this.updateUnconfirmedCount();
        window.removeCookie('unconfirmedOrders');
        window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
        //cogoToast.success("Removed from cart", { hideAfter: 2 });
    }

    static updateItemInOrder(item_id, combo_id, quantity) {
        this.unconfirmedOrders.forEach((order) => {
            if (order.item_id === item_id) {
                order.quantity = quantity;
            }
        });
        window.removeCookie('unconfirmedOrders');
        window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
        this.updateUnconfirmedCount();
        //cogoToast.success("Updated cart", { hideAfter: 2 });
    }

    static addOrders(order) {
        let itemOrders = this.unconfirmedOrders.filter(
            (x) => x.item_id === order.item_id
        );
        let itemOrdersQuantity = 0;
        for (let eorder of itemOrders) {
            itemOrdersQuantity += eorder.quantity;
        }

        let allowedQuantity = order.itemdata.item_limit
            ? order.itemdata.item_limit - itemOrdersQuantity
            : 9999;

        if (!order.itemdata.item_limit || order.quantity <= allowedQuantity) {
            order.subtitle = '';
            // let options_ = order.options_json ? JSON.parse(order.options_json) : null;
            if (order.options.length > 0) {
                order.options.forEach((option) => {
                    order.subtitle += option.choicename
                        ? option.choicename + ','
                        : option.name + ',';
                });
                order.subtitle = order.subtitle
                    .trim()
                    .replace(/.$/, '.')
                    .substr(0, 80);
            }
            this.unconfirmedOrders.push(order);
            // console.log(this.unconfirmedOrders);
            window.removeCookie('unconfirmedOrders');
            window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
            this.updateUnconfirmedCount();
        } else {
            cogoToast.error(
                "I'm sorry. We only have " +
                    order.itemdata.item_limit +
                    ' of these remaining',
                { hideAfter: 2 }
            );
        }
        //cogoToast.success("Added to cart", { hideAfter: 2 });
    }

    static async updateUnconfirmedCount() {
        var unconfirmedTotal = 0.0;
        var unconfirmedCount = 0;
        var unconfirmedTax = 0.0;
        var unconfirmedTaxes = 0.0;
        var unconfirmedCGST = 0.0;
        var unconfirmedSGST = 0.0;
        var unconfirmedVAT = 0.0;
        var unconfirmedServiceFee = 0.0;
        var unconfirmedFees = 0.0;
        var unconfirmedGrandTotal = 0.0;

        let unconfirmedTaxDetails = {};

        var orderCredits =
            this.state.orderdata && this.state.orderdata.order_credits
                ? this.state.orderdata.order_credits
                : 0.0;

        for (let index in this.unconfirmedOrders) {
            // console.log(order);
            let order = this.unconfirmedOrders[index];
            let options_json = order.options_json ? JSON.parse(order.options_json) : null;
            if (options_json && options_json.length > 0) {
                var subtitle = '';
                options_json.forEach((option) => {
                    subtitle += option.name + ', ';
                });
            }
            order.orderTotal = order.item_total * order.quantity;
            unconfirmedTotal += order.orderTotal;
            unconfirmedCount += order.quantity;

            order.taxTotal = 0.00;
            order.taxInfo = {};

            // console.log('unconfirmedOrderTotal = ' + order.orderTotal);

            if (order.itemdata) {
                let allRests = this.state.subrestdata
                    ? this.state.subrestdata.concat([this.state.mainrestdata])
                    : [this.state.mainrestdata];
                let selectedRest = allRests.filter(
                    (rest) =>
                        rest &&
                        rest.restdata &&
                        rest.restdata.restaurant_id === order.itemdata.menu
                );
                let menuTable =
                    selectedRest && selectedRest.length > 0
                        ? selectedRest[0].menuTable
                        : null;

                if (
                    menuTable &&
                    menuTable[order.itemdata.item_id].item_tax_details
                ) {
                    let item_tax_details =
                        menuTable[order.itemdata.item_id].item_tax_details;

                    let itemTaxes = item_tax_details.filter(
                        (x) => x.tax_on_tax === null
                    );
                    let dependentTaxes = item_tax_details.filter(
                        (x) => x.tax_on_tax !== null
                    );

                    //console.log(itemTaxes, dependentTaxes);

                    let taxDetails = {};

                    for (const index in itemTaxes) {
                        let tax = itemTaxes[index];
                        //console.log(tax);
                        let calcTax = async (tax) => {
                            let tax_amount =
                                Math.round(order.orderTotal * tax.tax_amount) /
                                100;
                            if (!unconfirmedTaxDetails[tax.name]) {
                                unconfirmedTaxDetails[tax.name] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!taxDetails[tax.id]) {
                                taxDetails[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!order.taxInfo[tax.id]) {
                                order.taxInfo[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            taxDetails[tax.id].actual_tax += tax_amount;
                            order.taxInfo[tax.id].actual_tax += tax_amount;
                            order.taxTotal += tax_amount;
                            unconfirmedTaxDetails[tax.name].actual_tax +=
                                tax_amount;
                            return Promise.resolve();
                        };

                        await calcTax(tax);
                        //console.log()
                    }

                    for (const index in dependentTaxes) {
                        let tax = dependentTaxes[index];
                        let calcTax = async (tax) => {
                            let tax_amount = 0.0;
                            if (taxDetails[tax.tax_on_tax]) {
                                tax_amount =
                                    Math.round(
                                        taxDetails[tax.tax_on_tax].actual_tax *
                                            tax.tax_amount
                                    ) / 100;
                            }
                            if (!unconfirmedTaxDetails[tax.name]) {
                                unconfirmedTaxDetails[tax.name] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!taxDetails[tax.id]) {
                                taxDetails[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            if (!order.taxInfo[tax.id]) {
                                order.taxInfo[tax.id] = {
                                    name: tax.name,
                                    description: tax.description,
                                    tax_amount: tax.tax_amount,
                                    actual_tax: 0.0,
                                };
                            }
                            taxDetails[tax.id].actual_tax += tax_amount;
                            order.taxInfo[tax.id].actual_tax += tax_amount;
                            order.taxTotal += tax_amount;
                            unconfirmedTaxDetails[tax.name].actual_tax +=
                                tax_amount;
                            return Promise.resolve();
                        };

                        await calcTax(tax);
                    }
                    //console.log(taxDetails, unconfirmedTaxDetails)
                } else {
                    if (process.env.REACT_APP_COUNTRY === 'IN') {
                        if (!unconfirmedTaxDetails['CGST']) {
                            unconfirmedTaxDetails['CGST'] = {
                                name: 'CGST',
                                description: '',
                                tax_amount: 2.5,
                                actual_tax: 0.0,
                            };
                        }
                        if (!unconfirmedTaxDetails['SGST']) {
                            unconfirmedTaxDetails['SGST'] = {
                                name: 'SGST',
                                description: '',
                                tax_amount: 2.5,
                                actual_tax: 0.0,
                            };
                        }
                        if (!unconfirmedTaxDetails['VAT']) {
                            unconfirmedTaxDetails['VAT'] = {
                                name: 'VAT',
                                description: '',
                                tax_amount: 9,
                                actual_tax: 0.0,
                            };
                        }
                        let service_fee =
                            this.state.restdata &&
                            this.state.restdata.service_fee
                                ? this.state.restdata.service_fee
                                : 0;

                        if (!unconfirmedTaxDetails['SERVICE_FEE']) {
                            unconfirmedTaxDetails['SERVICE_FEE'] = {
                                name: 'SERVICE FEE',
                                description: '',
                                tax_amount: service_fee,
                                actual_tax: 0.0,
                            };
                        }

                        var cgst = order.itemdata.cgst || 0;
                        var sgst = order.itemdata.sgst || 0;
                        var vat = order.itemdata.vat || 0;

                        unconfirmedTaxDetails['CGST'].actual_tax +=
                            (order.orderTotal * cgst) / 100;
                        unconfirmedTaxDetails['SGST'].actual_tax +=
                            (order.orderTotal * sgst) / 100;
                        unconfirmedTaxDetails['VAT'].actual_tax +=
                            (order.orderTotal * vat) / 100;
                        unconfirmedTaxDetails['SERVICE_FEE'].actual_tax +=
                            (order.orderTotal * service_fee * 1.05) / 100;
                    } else {
                        if (!unconfirmedTaxDetails['VAT']) {
                            unconfirmedTaxDetails['VAT'] = {
                                name: 'VAT',
                                description: '',
                                tax_amount: 9,
                                actual_tax: 0.0,
                            };
                        }
                        var vat = order.itemdata.vat || 0;
                        unconfirmedTaxDetails['VAT'].actual_tax +=
                            (order.orderTotal * vat) / 100;
                    }
                }
            } else {
                if (process.env.REACT_APP_COUNTRY === 'IN') {
                    if (!unconfirmedTaxDetails['CGST']) {
                        unconfirmedTaxDetails['CGST'] = {
                            name: 'CGST',
                            description: '',
                            tax_amount: 2.5,
                            actual_tax: 0.0,
                        };
                    }
                    if (!unconfirmedTaxDetails['SGST']) {
                        unconfirmedTaxDetails['SGST'] = {
                            name: 'SGST',
                            description: '',
                            tax_amount: 2.5,
                            actual_tax: 0.0,
                        };
                    }
                    if (!unconfirmedTaxDetails['VAT']) {
                        unconfirmedTaxDetails['VAT'] = {
                            name: 'VAT',
                            description: '',
                            tax_amount: 9,
                            actual_tax: 0.0,
                        };
                    }
                    let service_fee =
                        this.state.restdata && this.state.restdata.service_fee
                            ? this.state.restdata.service_fee
                            : 0;

                    if (!unconfirmedTaxDetails['SERVICE_FEE']) {
                        unconfirmedTaxDetails['SERVICE_FEE'] = {
                            name: 'SERVICE FEE',
                            description: '',
                            tax_amount: service_fee,
                            actual_tax: 0.0,
                        };
                    }
                } else {
                    if (!unconfirmedTaxDetails['VAT']) {
                        unconfirmedTaxDetails['VAT'] = {
                            name: 'VAT',
                            description: '',
                            tax_amount: 9,
                            actual_tax: 0.0,
                        };
                    }
                }
            }
        }

        let kumba_fee =
            this.state.restdata && this.state.restdata.kumbapct
                ? this.state.restdata.kumbapct
                : 0.0;
        let kumba_fixed =
            this.state.restdata && this.state.restdata.kumbafixed
                ? this.state.restdata.kumbafixed
                : 0.0;
        unconfirmedFees =
            (unconfirmedTotal * kumba_fee * 1.18) / 100 + kumba_fixed;
        if (process.env.REACT_APP_COUNTRY === 'IN') {
            if (unconfirmedTaxDetails) {
                //console.log(unconfirmedTaxDetails)
                for (let key in unconfirmedTaxDetails) {
                    // console.log(key);
                    unconfirmedTax += unconfirmedTaxDetails[key].actual_tax;
                }
            }
            //console.log(unconfirmedTax);
            var total = unconfirmedTotal + unconfirmedTax + unconfirmedFees;
            unconfirmedGrandTotal = total;
        } else {
            var total = unconfirmedTotal + unconfirmedTaxes + unconfirmedFees;
            unconfirmedServiceFee =
                (total *
                    (this.state.restdata
                        ? this.state.restdata.service_fee
                        : 0)) /
                100;
            unconfirmedGrandTotal = total + unconfirmedServiceFee;
        }

        // console.log('unconfirmedTax = ' + unconfirmedTax);
        // console.log('unconfirmedGrandTotal = ' + unconfirmedGrandTotal);
        // console.log('orderCredits = ' + orderCredits);

        // console.log(unconfirmedGrandTotal);

        var unconfirmedPayTotal = 0.0;
        var unconfirmedCreditsUsed = 0.0;

        if (orderCredits > unconfirmedGrandTotal) {
            unconfirmedPayTotal = 0.0;
            unconfirmedCreditsUsed = unconfirmedGrandTotal;
        } else {
            unconfirmedPayTotal = parseFloat(
                unconfirmedGrandTotal - orderCredits
            );
            unconfirmedCreditsUsed = orderCredits;
        }

        // console.log('unconfirmedPayTotal = ' + unconfirmedPayTotal);

        this.setState({
            // unconfirmedCount: unconfirmedCount,
            unconfirmedTotal,
            unconfirmedCount,
            unconfirmedTax,
            unconfirmedTaxes,
            unconfirmedCGST,
            unconfirmedSGST,
            unconfirmedVAT,
            unconfirmedServiceFee,
            unconfirmedFees,
            unconfirmedGrandTotal,
            unconfirmedPayTotal,
            unconfirmedTaxDetails,
            unconfirmedCreditsUsed,
            orderCredits,
        });
        return Promise.resolve();
    }

    static removeOrder(id) {
        this.unconfirmedOrders = this.unconfirmedOrders.filter(
            (eachorder) => id && eachorder.id !== id
        );
        window.removeCookie('unconfirmedOrders');
        window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
        this.updateUnconfirmedCount();
        // if(this.unconfirmedOrders.length === 0) {
        //     this.setState({selectedDeliverySlot : null});
        //     window.removeCookie('selectedDeliverySlot');
        // }
    }

    static confirm(message, yes, no) {
        this.setState({
            showConfirm: !this.state.showConfirm,
            confirmMessage: message,
        });
    }

    static updateOrderInstructions(id, val) {
        for (let order of this.unconfirmedOrders) {
            if (order.id === id) {
                order.instructions = val;
            }
        }

        console.log(this.unconfirmedOrders);

        window.removeCookie('unconfirmedOrders');
        window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
    }

    static updateOrder(id, quantity) {
        // console.log('updated ---', id, quantity);
        if (quantity > 0) {
            let currOrder = this.unconfirmedOrders
                .filter((x) => x.id === id)
                .pop();
            let diffquantity = quantity - currOrder.quantity;
            let itemOrders = this.unconfirmedOrders.filter(
                (x) => x.item_id === currOrder.item_id
            );
            let itemOrdersQuantity = 0;
            for (let order of itemOrders) {
                itemOrdersQuantity += order.quantity;
            }

            let allowedQuantity = currOrder.itemdata.item_limit
                ? currOrder.itemdata.item_limit - itemOrdersQuantity
                : 9999;

            if (
                !currOrder.itemdata.item_limit ||
                diffquantity <= allowedQuantity
            ) {
                for (let order of this.unconfirmedOrders) {
                    if (order.id === id) {
                        order.quantity = quantity;
                    }
                }
                window.removeCookie('unconfirmedOrders');
                window.setCookie(
                    'unconfirmedOrders',
                    this.unconfirmedOrders,
                    360
                );
                this.updateUnconfirmedCount();
            } else {
                cogoToast.error(
                    "I'm sorry. We only have " +
                        currOrder.itemdata.item_limit +
                        ' of these remaining',
                    { hideAfter: 2 }
                );
            }
            //cogoToast.success("Updated cart", { hideAfter: 2 });
        } else {
            this.removeOrder(id);
        }
    }

    static removeComboCustomization(combo_id, item_id) {
        this.unconfirmedOrders.forEach((order) => {
            if (
                order.id === combo_id &&
                order.customizations &&
                order.customizations > 0
            ) {
                order.customizations.filter((custom) => custom.id !== item_id);
            }
        });
        window.removeCookie('unconfirmedOrders');
        window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
        this.updateUnconfirmedCount();
        //cogoToast.success("Removed from cart", { hideAfter: 2 });
    }

    static updateComboCustomization(combo_id, item_id, quantity) {
        if (quantity > 0) {
            this.unconfirmedOrders.forEach((order) => {
                if (
                    order.id === combo_id &&
                    order.customizations &&
                    order.customizations > 0
                ) {
                    order.customizations.forEach((custom) => {
                        if (custom.id === item_id) {
                            order.quantity = quantity;
                        }
                    });
                }
            });
        }
        window.removeCookie('unconfirmedOrders');
        window.setCookie('unconfirmedOrders', this.unconfirmedOrders, 360);
        this.updateUnconfirmedCount();
        //cogoToast.success("Updated cart", { hideAfter: 2 });
    }

    static async submitOrder(rzpid, creditsUsed, instructions) {
        this.setState({ paymentLoading: true });

        try {
            // if (!this.state.orderID) {
            //     await this.createOrder();
            // }
            if(this.state.tableID) {
                let tabledata = await this.getTableDetails(this.state.tableID);
                if(tabledata.orders && tabledata.orders.length > 0) {
                    let userStatus = await this.checkUserOrderStatus(tabledata.orders[0].order_id);
                    if(!userStatus) {
                        this.state.popup("Sorry for the inconvenience, but this table seems to be occupied. Please ask the staff for assistance")
                        return Promise.reject(new Error("Unable to process order"))
                    }
                }
            }
            let url = window.kumbaURL + '/mainorders/add';
            let header = { 'foodbots-header': this.state.token };
            var orders = [];
            

            let delivery_slot = this.state.deliveryslots
                ? this.state.deliveryslots
                      .filter(
                          (x) =>
                              x.slot_id === Cookies.get('selectedDeliverySlot')
                      )
                      .pop()
                : null;

            let order_status = null;
            let mainorder_status = null;
            let send_restaurant_notifications = null;
            let date = null;

            if(this.state.selectedSlotRange) {
                let [hours, minutes] = this.state.selectedSlotRange.split(":");
                date = new Date();
                date.setHours(parseInt(hours));
                date.setMinutes(parseInt(minutes));
            }

            if (this.state.selectedMenuType === 'dine-in') {
                if (
                    this.state.restdata &&
                    this.state.restdata.canRejectOrders
                ) {
                    order_status = 'waiting';
                    mainorder_status = 'on-going';
                } else {
                    order_status = 'accepted';
                    mainorder_status = 'on-going';
                }
                send_restaurant_notifications = true;
            } else if (this.state.selectedMenuType === 'takeaway') {
                if (
                    this.state.restdata &&
                    this.state.restdata.online_payments
                ) {
                    order_status = 'waiting';
                    mainorder_status = 'awaiting_payment';
                    send_restaurant_notifications = false;
                } else {
                    if(date) {
                        order_status = 'confirmed';
                        mainorder_status = 'received';
                    } else {
                        order_status = 'accepted';
                        mainorder_status = "accepted";
                    }
                    send_restaurant_notifications = true;
                }
            } else {
                if (
                    this.state.restdata &&
                    this.state.restdata.online_payments
                ) {
                    order_status = 'waiting';
                    mainorder_status = 'awaiting_payment';
                    send_restaurant_notifications = false;
                } else {
                    order_status = 'accepted';
                    mainorder_status = 'awaiting_confirmation';
                    send_restaurant_notifications = true;
                }
            }

            this.unconfirmedOrders.forEach((order) => {
                // console.log("EACH ORDER PLACED ----", order);
                var eachorder = {
                    order_id:
                        this.state.orderID && this.state.orderID !== 'null'
                            ? this.state.orderID
                            : null,
                    item_id: order.item_id,
                    quantity: order.quantity,
                    rest_id: order.restID,
                    // combos
                    table: this.state.tableID,
                    item_total: order.orderTotal,
                    // staff
                    payment_mode: 'kumba',
                    status: order_status,
                    ind_payment_id:
                        process.env.REACT_APP_COUNTRY === 'IN' ? rzpid : null,
                    us_payment_id:
                        process.env.REACT_APP_COUNTRY !== 'IN' ? rzpid : null,
                    item_specifics: order.instructions,
                    tax_info: JSON.stringify(order.taxInfo),
                    tax_total: order.taxTotal || 0,
                    item_pay_total: order.orderTotal + (order.taxTotal || 0),
                    options: order.options.map((option) => option.id),
                    options_json:
                        order.options && order.options.length > 0
                            ? JSON.stringify(order.options)
                            : null,
                };
                console.log('SENDING ORDER =====', eachorder);
                orders.push(eachorder);
            });

            let pstdata = {
                order_id:
                    this.state.orderID && this.state.orderID !== 'null'
                        ? this.state.orderID
                        : null,
                order_type: this.state.selectedMenuType,
                rest_id: this.state.restID,
                table_id: this.state.tableID,
                ordersList: orders,
                user: this.state.user.user_id,
                status: order_status,
                mainorder_status: mainorder_status,
                payment_id: rzpid,
                credits_used: creditsUsed,
                instructions: instructions,
                delivery_address:
                    this.state.selectedAddress &&
                    this.state.selectedMenuType === 'delivery'
                        ? this.state.selectedAddress.id
                        : null,
                delivery_slot: delivery_slot ? delivery_slot.slot_id : null,
                delivery_charge: this.state.deliveryCharge || 0.0,
                customer_preferred_time: date,
                send_restaurant_notifications: send_restaurant_notifications,
                discount: this.state.orderOfferDiscount,
                partner_name: this.state.partner_name,
            };

            console.log(pstdata);

            const response = await axios({
                url,
                headers: header,
                method: 'POST',
                data: pstdata,
            });

            var data = response.data;

            if (data && data.orderID && (!this.state.orderID || this.state.orderID !== data.orderID)) {
                this.setState({ orderID: data.orderID });
                window.setCookie('orderID', data.orderID);
                console.log("Setting", data.orderID);
            }
            this.setState({paymentLoading : false});
            this.clearOrder();
            sessionStorage.clear();
            this.getOrder();
            // if(this.state.tableID){
            //     this.getTableDetails(this.state.tableID);
            // }
            return Promise.resolve(data);

            // } else {
            //     //hide();
            //     cogoToast.error("Couldn't place order.", { hideAfter: 2 });
            //     return new Promise((resolve, reject) => {
            //         return resolve(null);
            //     });
            // }
        } catch (err) {
            this.setState({ error: err, paymentLoading: false });
            console.log(err);
            //alert("Could not add '" + this.props.itemdata.name + "' to cart");
            // alert("Couldn't complete the operation");
            this.popup("Couldn't place the order", 'error');
            return new Promise((resolve, reject) => {
                return reject(err);
            });
        }
    }

    static async clearOrder() {
        // localStorage.removeItem('selectedDeliverySlot');
        window.removeCookie('selectedDeliverySlot');
        window.removeCookie('unconfirmedOrders');
        this.unconfirmedOrders = [];
        this.setState({
            unconfirmedCount: 0,
            unconfirmedTotal: 0.0,
            unconfirmedPayTotal: 0.0,
            unconfirmedCreditsUsed: 0.0,
            unconfirmedTax: 0.0,
            unconfirmedTaxes: 0.0,
            unconfirmedCGST: 0.0,
            unconfirmedSGST: 0.0,
            unconfirmedVAT: 0.0,
            unconfirmedServiceFee: 0.0,
            unconfirmedFees: 0.0,
            unconfirmedGrandTotal: 0.0,
            unconfirmedTaxDetails: {},
            deliveryCharge: 0.0,
            selectedAddress: null,
            isAddressDone: false,
            showPopup: false,
            popupText: null,
            popupType: null,
            activeDeliverySlot: null,
            selectedDeliverySlot: null,
        });
        //this.updateUnconfirmedCount();
        return Promise.resolve();
    }

    static async fetchOrder(orderID) {
        try {
            if (this.state.token) {
                let url = window.kumbaURL + '/mainorders/sorted/' + orderID;
                let header = { 'foodbots-header': this.state.token };
                const response = await axios({
                    url,
                    headers: header,
                    method: 'GET',
                });
                return new Promise((resolve, reject) => {
                    return resolve(response.data);
                });
            } else {
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static createCartFromOrder() {
        let orderdata = this.state.orderdata;
        orderdata.orders.forEach((order, index) => {
            var data = {
                item_id: order.item_id.item_id,
                options: order.options || [],
                quantity: order.quantity,
                itemdata: order.item_id,
                item_total: order.item_total,
                restID: order.item_id.menu,
                id: index + 1,
            };
            this.addOrders(data);
        });
        this.setState({
            selectedAddress: orderdata.delivery_address,
            isAddressDone: true,
            deliveryCharge: orderdata.delivery_charge,
        });
        if (orderdata.delivery_slot) {
            // localStorage.setItem(
            //     'selectedDeliverySlot',
            //     orderdata.delivery_slot.slot_id
            // );
            this.setState({
                selectedDeliverySlot: orderdata.delivery_slot.slot_id,
            });
        }
    }

    static async checkoutFeedback(id, feedback) {
        // console.log({ sub_order_id: id, feedback });
        const url = window.kumbaURL + '/orders/feedback';
        axios(url, {
            method: 'PUT',
            data: { sub_order_id: id, feedback },
            headers: { 'foodbots-header': this.state.token },
        })
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
    }

    static async trueCallerAuth(requestID) {
        var url = window.kumbaURL + '/auth/truecaller';
        // console.log('Sending Data...')
        this.state.io.socket.request(
            {
                url,
                method: 'POST',
                data: { requestID },
            },
            (jsondata, jwres) => {
                //alert('TrueCaller Joson ------' + JSON.stringify(jsondata))
                //alert('TrueCaller jwres ------' + JSON.stringify(jwres))
            }
        );
    }

    static async getOrder() {
        // alert("GETTING ORDER WITH ID OF --"+ this.state.orderID)
        console.log('GETTING ORDER -------', this.state.orderID);
        // return new Promise((resolve, reject) => {
        try {
            if (this.state.token && this.state.orderID && this.state.orderID !== 'null' && this.state.orderID !== "undefined") {
                // var dataReceived = null;
                let url =
                    window.kumbaURL +
                    '/mainorders/sorted/' +
                    this.state.orderID;
                let header = { 'foodbots-header': this.state.token };

                let response = await axios({
                    url,
                        headers: header,
                        method: 'GET',
                });

                if(!this.state.orderdata) {
                    this.state.io.socket.request(
                        {
                            url,
                            headers: header,
                            method: 'GET',
                        },
                        function (jsondata, jwres) {
                            // console.log('GOT ORER --------', jsondata, jwres);
                            if (jwres.error) {
                                console.log(jwres.error);
                                // this.setState({ loading: false });
                                // return Promise.reject(jwres.error);
                            } else {
                                //console.log("Order data", jsondata);
                                
                            }
                        }
                    );
                }

                let jsondata = response.data;
                console.log(jsondata, this.state.orderID);
                if (jsondata.order_status === 'finished') {
                    // this.setState({ loading: false });
                    this.clearOrder();
                    this.clearOrderData();
                } else if (jsondata.order_status === 'cancelled') {
                    //alert("Your order has been checked out.");
                    this.clearOrderData();
                    // this.setState({ loading: false });
                    //this.clearOrder();
                } else {
                    window.setCookie('orderID', jsondata.order_id);
                    // window.setCookie('tableID', jsondata.table_id.id, 360);
                    if(jsondata.rest) {
                        window.setCookie(
                            'restID',
                            jsondata.rest.restaurant_id
                        );
                    }

                    console.log('JOINED Order data', jsondata);
                    this.setState({
                        orderdata: jsondata,
                        orderID: jsondata.order_id,
                        restID: jsondata.rest ? jsondata.rest.restaurant_id : null,
                        // loading : false
                    });

                    await this.loadRestaurantDetails();

                    if (
                        jsondata.table_id && this.state.tableID !==
                        jsondata.table_id.id
                    ) {
                        await this.getTableDetails(jsondata.table_id.id);

                    }
                        // if (jsondata.table_id) {
                        //     await this.getTableDetails(jsondata.table_id.id);
                        // }

                        this.updateOrderPerUser();
                }
                return Promise.resolve(jsondata);

                
            } else {
                window.removeCookie('orderID');
                this.setState({orderID : null});
                return Promise.resolve(null);
            }
        } catch (e) {
            this.setState({ loading: false });
            return Promise.reject(e);
        }
    }

    static async addPersonToOrder(refOrder, refUser) {
        console.log('Calling API to addPersonToOrder');
        try {
            if (this.state.token) {
                let url =
                    window.kumbaURL +
                    '/mainorders/share/' +
                    refOrder.order_id +
                    '/' +
                    this.state.user.user_id;
                console.log('URL --', refOrder);
                let header = { 'foodbots-header': this.state.token };

                let response = await axios({
                    url,
                        method: 'GET',
                        headers: header,
                });

                this.state.io.socket.request(
                    {
                        url,
                        method: 'GET',
                        headers: header,
                    },
                    function (jsondata, jwres) {
                        if (jwres.error) {
                            console.log('SOCKET ERROR ---', jwres.error);
                            this.setState({ loading: false });
                            // return Promise.reject(jwres.error);
                        } else {
                            // return Promise.resolve(jsondata);
                            //alert("Added to order : " + this.state.refOrder);
                        }
                    }.bind(this)
                );

                let jsondata = response.data;

                console.log('JOINED ----', jsondata);

                window.setCookie('orderID', jsondata.order_id, 360);
                // window.setCookie('tableID', jsondata.table_id.id, 360);
                window.setCookie(
                    'restID',
                    jsondata.rest.restaurant_id,
                    360
                );

                if(jsondata.table_id) {
                    window.setCookie(
                        'tableID',
                        jsondata.table_id.id,
                        360
                    );
                }

                console.log('JOINED Order data', jsondata);
                this.setState({
                    orderdata: jsondata,
                    orderID: jsondata.order_id,
                    restID: jsondata.rest.restaurant_id,
                    tableID: jsondata.table_id ? jsondata.table_id.id : null,
                });

                await this.loadRestaurantDetails();
                if(jsondata.table_id && (!this.state.tabledata || (this.state.tabledata && this.state.tabledata.id === jsondata.table_id.id))) {
                    await this.getTableDetails(jsondata.table_id.id);
                }
                this.updateOrderPerUser();
                return Promise.resolve(jsondata);
                
            } else {
                return Promise.resolve(null);
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static updateOrderPerUser() {
        let orderdata = this.state.orderdata;
        if (orderdata) {
            var confirmedTotal = this.state.orderdata.order_subtotal;
            let discount = this.state.orderdata.discount;

            let confirmedTaxDetails = this.state.orderdata.tax_info
                ? JSON.parse(this.state.orderdata.tax_info)
                : null;

            var confirmedTax = 0.0;
            var confirmedTaxes = 0.0;
            var confirmedCGST = this.state.orderdata.cgstAmount || 0;
            var confirmedSGST = this.state.orderdata.sgstAmount || 0;
            var confirmedServiceFee = this.state.orderdata.serviceAmount || 0;
            var confirmedVAT = this.state.orderdata.vatAmount || 0;
            var confirmedFees = this.state.orderdata.kumbaAmount || 0;
            var confirmedGrandTotal = 0.0;
            var confirmedCount = 0;
            var confirmedPayTotal = 0.0;
            var confirmedCreditsUsed = 0.0;

            let orderSorted = {};

            if (orderdata && orderdata.orders && orderdata.orders.length > 0) {
                orderdata.orders.forEach((order) => {
                    if (!orderSorted[order.user]) {
                        orderSorted[order.user] = [];
                    }

                    order.orderTotal = order.item_total * order.quantity;
                    confirmedCount += order.quantity;

                    order.subtitle = '';
                    let options_json = order.options_json ? JSON.parse(order.options_json) : null;
                    if (options_json && options_json.length > 0) {
                        // console.log(order.rest_id);
                        let allRests =
                            this.state.subrestdata &&
                            this.state.subrestdata.length > 0
                                ? this.state.subrestdata.concat([
                                      this.state.mainrestdata,
                                  ])
                                : [this.state.mainrestdata];
                        let selectedRest = allRests.filter(
                            (rest) =>
                                rest &&
                                rest.restdata &&
                                rest.restdata.restaurant_id === order.rest_id
                        );
                        // console.log('selectedRest -------------------', selectedRest)
                        let menuTable =
                            selectedRest && selectedRest[0]
                                ? selectedRest[0].menuTable
                                : null;
                        options_json.forEach((option) => {
                            let choiceitem =
                                option.choiceitem &&
                                menuTable &&
                                menuTable[option.choiceitem]
                                    ? menuTable[option.choiceitem]
                                    : null;
                            order.subtitle += choiceitem
                                ? choiceitem.name + ','
                                : option.name + ',';
                        });
                        order.subtitle = order.subtitle
                            .trim()
                            .replace(/.$/, '.')
                            .substr(0, 80);
                    }
                    orderSorted[order.user].push(order);
                });

                // console.log(confirmedTaxDetails);

                if (
                    confirmedTaxDetails &&
                    Object.keys(confirmedTaxDetails).length > 0
                ) {
                    for (let key in confirmedTaxDetails) {
                        confirmedTax += confirmedTaxDetails[key].actual_tax;
                    }
                    // console.log(confirmedTax);
                } else {
                    confirmedTax =
                        confirmedCGST +
                        confirmedSGST +
                        confirmedVAT +
                        confirmedFees +
                        confirmedServiceFee;
                }

                confirmedGrandTotal =
                    Math.round((confirmedTotal + confirmedTax) * 100) / 100;

                var confirmedPayTotal = 0.0;
                var confirmedCreditsUsed = 0.0;
                if (orderdata.order_credits) {
                    if (
                        orderdata.order_credits >
                        confirmedGrandTotal - discount
                    ) {
                        confirmedPayTotal = 0.0;
                        confirmedCreditsUsed = confirmedGrandTotal - discount;
                    } else {
                        confirmedPayTotal =
                            Math.round(
                                (confirmedGrandTotal -
                                    discount -
                                    orderdata.order_credits) *
                                    100
                            ) / 100;
                        confirmedCreditsUsed = orderdata.order_credits;
                    }
                } else {
                    confirmedPayTotal = confirmedGrandTotal - discount;
                }

                this.setState({
                    orderSorted: orderSorted,
                    confirmedTotal,
                    confirmedCGST,
                    confirmedSGST,
                    confirmedTax,
                    confirmedTaxes,
                    confirmedServiceFee,
                    confirmedVAT,
                    confirmedFees,
                    confirmedGrandTotal,
                    confirmedPayTotal,
                    confirmedCount,
                    confirmedCreditsUsed,
                    discount,
                    loading: false,
                });
            } else {
                this.setState({ orderSorted: null, loading: false });
            }
            this.getuserinfos();
        } else {
            this.setState({ loading: false });
        }
    }

    static updateSubOrder(suborder) {
        this.setState({ suborder: suborder });
    }

    static getuserinfos() {
        let data = this.state.orderdata;
        let users = {};
        if (data && data.allusers && data.allusers.length > 0) {
            for (var i = 0; i < data.allusers.length; i++) {
                users[data.allusers[i].user_id] = data.allusers[i];
            }
            this.setState({ allusers: users });
        }
        this.getpayingfor();
    }

    static getpayingfor() {
        if (this.state.user) {
            let paymentDetails =
                this.state.orderdata && this.state.orderdata.payment_details
                    ? this.state.orderdata.payment_details
                    : null;
            var payingfor = [];
            paymentDetails &&
                paymentDetails.length > 0 &&
                paymentDetails.forEach((pd) => {
                    if (pd.person_paying === this.state.user.user_id) {
                        payingfor.push(pd.user);
                    }
                });
            this.setState({ payingfor: payingfor });
            this.perosnifyInformation();
        }
    }

    static perosnifyInformation() {
        var personInfo = {};
        this.state.allusers &&
            this.state.allusers.length > 0 &&
            this.state.allusers.forEach((user) => {
                if (user.user_id !== this.state.user.user_id) {
                    personInfo[user.user_id] = {};
                    personInfo[user.user_id].info = user;
                    personInfo[user.user_id].orders =
                        this.state.orderSorted[user.user_id];
                    personInfo[user.user_id].totals =
                        this.state.persontotal[user.user_id];

                    this.state.orderdata.payment_details.forEach((pd) => {
                        if (pd.user === user.user_id) {
                            personInfo[pd.user].paymentDetails = pd;
                        }
                    });
                }
            });
        this.setState({
            personInfo: personInfo,
            loading: false,
            isOrderDone: true,
        });
    }

    static async cancelOrder(orderID) {
        var data = {};
        try {
            // console.log('Cancelling Order');
            if (this.state.token && orderID) {
                let header = { 'foodbots-header': this.state.token };
                let url = window.kumbaURL + '/mainorders/cancel/' + orderID; // "/razorpay/checkout/"; "/mainorders/pay"

                const response = await axios({
                    url,
                    method: 'DELETE',
                    headers: header,
                });
                this.clearOrderData();
                return Promise.resolve();
            } else {
                this.clearOrderData();
                return Promise.resolve(null);
            }
        } catch (error) {
            alert(JSON.stringify(error));
            this.popup('Something went wrong. Please try again.', 'error');
            return Promise.reject(error);
        }
    }

    static async checkoutOrder(orderID, creditsUsed, rzpid) {
        var data = {};
        try {
            if (this.state.token && orderID) {
                let header = { 'foodbots-header': this.state.token };
                let url =
                    window.kumbaURL + process.env.REACT_APP_CHECKOUT_ORDER_URI; // "/razorpay/checkout/"; "/mainorders/pay"

                if (process.env.REACT_APP_COUNTRY === 'IN') {
                    data = {
                        order_id: orderID,
                        credits_used: creditsUsed,
                        payment_mode: 'kumba',
                        rzpid: rzpid,
                    };
                } else {
                    data = {
                        order_id: orderID,
                        payment_mode: 'kumba',
                        credits_used: creditsUsed,
                    };
                }

                const response = await axios({
                    url,
                    method: 'PUT',
                    headers: header,
                    data: data,
                });
                var jsondata = response.data;
                if (orderID === this.state.orderID) {
                    this.clearOrder();
                    this.clearOrderData();
                }
                return Promise.resolve(jsondata);
            } else {
                return Promise.resolve(null);
            }
        } catch (error) {
            //alert(error);
            // alert("Something went wrong. Please try again.");
            this.popup('Something went wrong. Please try again.', 'error');
            return new Promise((resolve, reject) => {
                return reject(error);
            });
        }
    }

    static async confirmOrder(orderID, creditsUsed, rzpid) {
        var data = {};
        try {
            if (this.state.token && orderID) {
                let header = { 'foodbots-header': this.state.token };
                let url = window.kumbaURL + '/mainorders/confirm'; // "/razorpay/checkout/"; "/mainorders/pay"

                data = {
                    order_id: orderID,
                    credits_used: creditsUsed,
                    payment_mode: 'kumba',
                    rzpid: rzpid,
                };

                const response = await axios({
                    url,
                    method: 'PUT',
                    headers: header,
                    data: data,
                });
                var jsondata = response.data;
                if (orderID === this.state.orderID) {
                    this.clearOrderData();
                    this.clearOrder();
                }
                return Promise.resolve(jsondata);
            } else {
                return Promise.resolve(null);
            }
        } catch (error) {
            this.popup('Something went wrong. Please try again.', 'error');
            return Promise.reject(error);
        }
    }

    static async makePaymentOffline() {
        if (this.state.token && this.state.orderID) {
            let url =
                window.kumbaURL +
                '/mainorders/confirmcheckout/' +
                this.state.orderID;
            let header = { 'foodbots-header': this.state.token };
            try {
                const response = await axios({
                    url,
                    method: 'PUT',
                    headers: header,
                });
            } catch (er) {
                return new Promise((resolve, reject) => {
                    return reject(er);
                });
            }
        }
    }

    /* Service Worker for PushNotifications related code */

    static unsubscribePush() {
        navigator.serviceWorker.ready.then(async function (registration) {
            try {
                //Find the registered push subscription in the service worker
                var subscription =
                    await registration.pushManager.getSubscription();
                if (!subscription) {
                    return;
                    //If there isn't a subscription, then there's nothing to do
                } else {
                    await subscription.unsubscribe();
                    var response = await axios({
                        url: window.kumbaURL + '/push/unregisteruser',
                        method: 'DELETE',
                        data: {
                            subscription: subscription,
                        },
                    });
                    //await axios.delete(window.kumbaURL + "/push/unregisteruser/" + this.state.user.user_id);
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

    static unsubscribeNotifPush() {
        navigator.serviceWorker.ready.then(async function (registration) {
            try {
                //Find the registered push subscription in the service worker
                var subscription =
                    await registration.pushManager.getSubscription();
                if (!subscription) {
                    return;
                    //If there isn't a subscription, then there's nothing to do
                } else {
                    await subscription.unsubscribe();
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

    static subscribePush() {
        navigator.serviceWorker.ready.then(
            async function (registration) {
                var subscription =
                    await registration.pushManager.getSubscription();
                if (subscription) {
                    // console.log("User IS subscribed.");
                    this.setState({ orderSubscribed: true });
                    //await this.unsubscribeNotifPush();
                }
                subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true, //Always display notifications
                    applicationServerKey: window.convertedVapidKey,
                });
                await this.registerSubKumba(subscription);
            }.bind(this)
        );
    }

    static async registerSubKumba(subscription) {
        try {
            if (this.state.orderID) {
                var response = await axios({
                    url: window.kumbaURL + '/push/registeruser',
                    method: 'POST',
                    data: {
                        subscription: subscription,
                        orderID: this.state.orderID,
                        user: this.state.user.user_id,
                    },
                });
                return new Promise((resolve, reject) => {
                    return resolve(response.data);
                });
            } else {
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static initializeNotificationUI() {
        // Set the initial subscription value
        if ('serviceWorker' in navigator && navigator.serviceWorker) {
            console.log('Found serviceworker. Subscribing  to push');
            this.subscribePush();
        } else {
            console.log('Service Worker not enabled');
        }
    }

    static async sendOTP(phone_num) {
        try {
            if (phone_num) {
                var response = await axios({
                    url:
                        window.kumbaURL +
                        (process.env.REACT_APP_SERVER_ENV &&
                        process.env.REACT_APP_SERVER_ENV === 'test'
                            ? '/auth/sendtestotp'
                            : '/auth/sendotp'),
                    method: 'POST',
                    data: {
                        phone_num: phone_num,
                    },
                });
                return new Promise((resolve, reject) => {
                    return resolve(response.data);
                });
            } else {
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async sendPhoneNum(phone_num) {
        try {
            if (phone_num) {
                var response = await axios({
                    url: window.kumbaURL + '/auth/sendphonenum',
                    method: 'POST',
                    data: {
                        phone_num: phone_num,
                        rest_id: this.state.restdata
                            ? this.state.restdata.restaurant_id
                            : null,
                    },
                });

                var data = response.data;
                var token = data.token;
                this.setState({ token: data.token });
                window.setCookie('token', data.token, 360);
                // console.log(response.data);
                let userdata = await this.getUser();
                await this.updateUserStatusOnTable();
                return Promise.resolve(userdata);
            } else {
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async resendOTP(phone_num) {
        try {
            if (phone_num) {
                var response = await axios({
                    url:
                        window.kumbaURL +
                        (process.env.REACT_APP_SERVER_ENV &&
                        process.env.REACT_APP_SERVER_ENV === 'test'
                            ? '/auth/resendtestotp'
                            : '/auth/resendotp'),
                    method: 'POST',
                    data: {
                        phone_num: phone_num,
                    },
                });
                return new Promise((resolve, reject) => {
                    return resolve(response.data);
                });
            } else {
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async googleLogin() {
        // firebaseAuth.signInWithPopup(googleProvider)
        //     .then((result) => {
        //         // var token = result.credential.accessToken;
        //         var user = result.user;
        //         const userData = {
        //             displayName: user.displayName,
        //             photoURL: user.photoURL,
        //             phoneNo: user.phoneNumber,
        //             id: user.uid,
        //             email: user.email
        //         };
        //         console.log('sending ---- ', userData);
        //         axios(`${window.kumbaURL}/auth/firebase`, {
        //             data: userData,
        //             method: 'POST'
        //         })
        //             .then(res => {
        //                 console.log('user ---', res);
        //                 this.setToken(res.data.token)
        //                 this.setState({
        //                     user: res.data.user,
        //                     isLoggedin: true
        //                 })
        //             })
        //             .catch(err => {
        //                 console.log('login error --', err);
        //                 this.setState({
        //                     user: null,
        //                     isLoggedin: false
        //                 })
        //             })
        //     }).catch((error) => {
        //         var errorCode = error.code;
        //         var errorMessage = error.message;
        //         console.log('error ---', errorMessage);
        //         var email = error.email;
        //         var credential = error.credential;
        //     });
    }

    static async verifyOTP(phone_num, otp) {
        // console.log('VERIFY OTP ---', {
        //     phone_num,
        //     otp,
        //     rest_id: this.state.restdata
        //         ? this.state.restdata.restaurant_id
        //         : null
        // });

        try {
            if (phone_num) {
                var response = await axios({
                    url:
                        window.kumbaURL +
                        (process.env.REACT_APP_SERVER_ENV &&
                        process.env.REACT_APP_SERVER_ENV === 'test'
                            ? '/auth/verifytestotp'
                            : '/auth/verifyotp'),
                    method: 'POST',
                    data: {
                        phone_num: phone_num,
                        otp: otp,
                        rest_id: this.state.restdata
                            ? this.state.restdata.restaurant_id
                            : null,
                    },
                });
                // console.log('OTP RETURN DATA --', response)
                var data = response.data;
                var token = data.token;
                this.setState({ token: data.token });
                window.setCookie('token', data.token, 360);
                // console.log(response.data);
                let userdata = await this.getUser();
                // console.log('GET USER DATA --', userdata);
                await this.updateUserStatusOnTable();
                console.log('updateUserStatusOnTable in verifyOTP');
                return Promise.resolve(userdata);
            } else {
                //console.log(`else block`);

                this.setState({ token: null });
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            //console.log(`didnt enter try ${e}`);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async sendEmailOTP(email) {
        try {
            if (email) {
                var response = await axios({
                    url: window.kumbaURL + '/auth/sendemailotp',
                    method: 'POST',
                    data: {
                        email: email,
                    },
                });
                return new Promise((resolve, reject) => {
                    return resolve(response.data);
                });
            } else {
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            console.log(e);
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async verifyEmailOTP(email, otp) {
        try {
            if (email) {
                var response = await axios({
                    url: window.kumbaURL + '/auth/verifyemailotp',
                    method: 'POST',
                    data: {
                        email: email,
                        otp: otp,
                    },
                });
                var data = response.data;
                //alert(JSON.stringify(data));
                var token = data.token;
                this.setState({ token: data.token });
                window.setCookie('token', data.token, 360);
                return await this.getUser();
            } else {
                this.setState({ token: null });
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            console.log(e);
            //alert(JSON.stringify(e));
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async getCards() {
        try {
            let header = { 'foodbots-header': this.state.token };
            var response = await axios({
                url:
                    window.kumbaURL +
                    '/stripe/cards/' +
                    this.state.user.user_id,
                headers: header,
                method: 'GET',
            });
            this.setState({ cards: response.data.sources.data });
            return new Promise((resolve, reject) => {
                return resolve(response.data);
            });
        } catch (e) {
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async addCard(token) {
        try {
            let header = { 'foodbots-header': this.state.token };
            var response = await axios({
                url: window.kumbaURL + '/stripe/addcard/',
                headers: header,
                method: 'POST',
                data: {
                    token: token,
                    user_id: this.state.user.user_id,
                },
            });
            return await this.getCards();
        } catch (e) {
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static updateFilter(name, value) {
        this.setState({ [name]: value });
    }

    static async updateCard(cardid) {
        try {
            let header = { 'foodbots-header': this.state.token };
            var response = await axios({
                url: window.kumbaURL + '/orderpayment/updatecard',
                headers: header,
                method: 'PUT',
                data: {
                    card: cardid,
                    order_id: this.state.orderID,
                    user_id: this.state.user.user_id,
                },
            });
            return await this.getOrder();
        } catch (e) {
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async handleAssistance(type) {
        try {
            var response = await axios({
                url: window.kumbaURL + '/resttables/assistance',
                method: 'PUT',
                data: {
                    type: type,
                    table_id: this.state.tableID,
                },
            });
            return Promise.resolve();
        } catch (e) {
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }

    static async processLandingURL() {
        try {
            let isStatic = false;
            let loading = true;

            this.setState({ loading: true });

            let { fullloc, domain, path, subdomain, maindomain, ext } =
                getURLInfo();

            // console.log(subdomain, maindomain, ext, path);

            let urls = path.split('/');
            let url = urls[1];
            let objID = urls[2] ? urls[2].toUpperCase() : null;

            // console.log(urls, url, objID);

            const token = Cookies.get('token') || null;
            this.setState({ token: token });

            if (this.state.token) {
                await this.getUser();
            }

            console.log(maindomain, ext);
            

            if (
                fullloc.includes('localhost') ||
                fullloc.includes(':3000') ||
                fullloc.includes(':3001') ||
                subdomain === 'kumba' ||
                (maindomain + '.' + ext === process.env.REACT_APP_DOMAIN &&
                    (subdomain === 'www' ||
                        subdomain === 'in' ||
                        subdomain === 'us' ||
                        subdomain === 'uk'))
            ) {
                // alert("Reached if " + subdomain + " " + fullloc);
                // alert(url + "-" + objID);
                if (url === 'share' && objID) {
                    let url = window.kumbaURL + '/orderpayment/' + objID;
                    let header = { 'foodbots-header': this.state.token };
                    const response = await axios({
                        url,
                        method: 'GET',
                        headers: header,
                    });
                    const scandata = await this.recordScan();
                    var jsondata = response.data;
                    console.log('======== SHARE DATA ========', jsondata);
                    loading = false;
                    this.setDigitalMenu(false);
                    this.setStaticMenu(false);
                    this.setState({ shareData: jsondata, loading, isStatic });
                    this.setRestaurantID(jsondata?.order_id?.rest);
                    return Promise.resolve(jsondata);
                    // return Promise.resolve()
                } else if (url === 'postscan' && objID) {
                    let tabledata = await this.getTableDetails(objID);
                    this.setRestaurantID(
                        tabledata?.restaurant_id?.restaurant_id
                    );
                    const scandata = await this.recordScan();

                    this.setState({ loading: false });
                    //window.setCookie("isStatic", false);
                    this.setDigitalMenu(false);
                    this.setStaticMenu(false);

                    return Promise.resolve(tabledata);
                } else if (url === 'changetable' && objID) {
                    isStatic = false;
                    //window.setCookie("isStatic", false);
                    this.setStaticMenu(false);
                    this.setDigitalMenu(false);

                    const tabledata = await this.changeTableDetails(
                        null,
                        null,
                        objID
                    );
                    // console.log(tabledata);

                    const scandata = await this.recordScan();
                    loading = false;
                    //this.setCountDown(true);
                    this.setState({
                        //isStatic,
                        loading,
                    });

                    return Promise.resolve(tabledata);
                } else if (url === 'scanevents' && objID) {
                    isStatic = false;
                    //window.setCookie("isStatic", false);
                    this.setStaticMenu(false);
                    this.setDigitalMenu(false);

                    const eventdata = await this.getEventDetails(objID);
                    const scandata = await this.recordScan();
                    //this.setCountDown(true);
                    this.setState({ loading: false });
                    return Promise.resolve(eventdata);
                } else if (url === 'places' && objID) {
                    // console.log('restID ---', objID);

                    window.removeCookie('tableID');

                    this.setRestaurantID(objID);
                    this.setDigitalMenu(false);
                    this.setStaticMenu(true);

                    const status = await this.loadRestaurantDetails();

                    // const scandata = await this.recordScan();
                    this.setState({
                        loading: false,
                        //payToRest: true
                    });

                    return Promise.resolve(this.state.restdata);
                } else if (url === 'qrpayment' && objID) {
                    this.setRestaurantID(objID);
                    const restData = await this.loadRestaurantDetails();
                    //console.log('rest data =', restData);
                    this.setStaticMenu(false);
                    this.setDigitalMenu(false);
                    //window.setCookie("tableID", null);

                    this.setState({
                        //payToRest: true,
                        loading: false,
                    });

                    //this.setCountDown(true);
                    return Promise.resolve(restData);
                } else if (url === 'checkin' && objID) {
                    window.removeCookie('tableID');

                    this.setRestaurantID(objID);
                    this.setDigitalMenu(false);
                    this.setStaticMenu(true);
                    const status = await this.loadRestaurantDetails();

                    const scandata = await this.recordScan();
                    this.setState({
                        loading: false,
                        //payToRest: true
                    });

                    return Promise.resolve(this.state.restdata);
                } else if (url === 'listing' && objID) {
                    try {
                        // console.log("Done till here 5");
                        window.removeCookie('restID');
                        window.removeCookie('tableID');
                        window.removeCookie('unconfirmedOrders');
                        window.removeCookie('orderID');
                        this.setListingID(objID);
                        // console.log("Done till here 3");
                        this.setDigitalMenu(false);
                        this.setStaticMenu(true);
                        const listingdata = await this.loadListingDetails();

                        this.setState({
                            loading: false,
                            restID: null,
                            tableID: null,
                            restdata: null,
                            isRestDone: false,
                            isTableDone: false,
                            tabledata: null,
                            orderID: null,
                            //payToRest: true
                        });

                        return Promise.resolve(listingdata);
                    } catch (e) {
                        console.log(e);
                        return Promise.reject(e);
                    }
                } else {
                    const restID = Cookies.get('restID') || null;
                    const tableID = null;
                    const orderID = Cookies.get('orderID') || null;
                    const isStatic = Cookies.get('isStatic') || true;
                    const listingID = Cookies.get('listingID') || null;
                    const selectedDeliverySlot =
                        Cookies.get('selectedDeliverySlot') || null;

                    this.setState({
                        selectedDeliverySlot: selectedDeliverySlot,
                    });
                    // this.setMultipleCookies({
                    //     restID, tableID, orderID, isStatic
                    // })

                    // console.log(restID, tableID, orderID, isStatic);
                    if (orderID) {
                        this.setStaticMenu(false);
                        this.setDigitalMenu(false);
                    } else {
                        this.setStaticMenu(true);
                        this.setDigitalMenu(false);
                    }

                    console.log({
                        restID,
                        orderID,
                        isStatic,
                        listingID,
                    });

                    this.setState({
                        restID,
                        orderID,
                        isStatic,
                        listingID,
                    });

                    try {
                        if (this.state.token) {
                            if (this.state.orderID) {
                                let orderdata = await this.getOrder();
                                // if (orderdata.table_id) {
                                //     await this.getTableDetails(
                                //         orderdata.table_id.id
                                //     );
                                // }
                            }
                        }

                        if (this.state.tableID && !this.state.tabledata) {
                            let tabledata = await this.getTableDetails(
                                this.state.tableID
                            );
                            this.setState({ loading: false });
                            return Promise.resolve(tabledata);
                        } else if (this.state.restID && !this.state.restdata) {
                            let restdata = await this.loadRestaurantDetails();
                            this.setState({ loading: false });
                            return Promise.resolve(restdata);
                        } else if (
                            this.state.listingID &&
                            !this.state.listingdata
                        ) {
                            let listingdata = await this.loadListingDetails();
                            this.setState({ loading: false });
                            return Promise.resolve(listingdata);
                        } else {
                            this.setState({ loading: false });
                            return Promise.resolve(null);
                        }
                    } catch (e) {
                        return Promise.reject(e);
                    }
                }
            } else {
                //const scandata = await this.recordScan();
                // alert("Reached else " + subdomain + " " + domain);
                let searchParam = null;
                if (maindomain + '.' + ext === process.env.REACT_APP_DOMAIN) {
                    searchParam = subdomain;
                } else {
                    searchParam = domain;
                }
                console.log(searchParam);
                try {
                    let url =
                        window.kumbaURL +
                        '/restaurant/getdomain/' +
                        searchParam;
                    const response = await axios({
                        url,
                        method: 'GET',
                    });
                    var jsondata = response.data;
                    console.log(jsondata);

                    if (jsondata.type === 'restaurant') {
                        this.setRestaurantID(jsondata.id[0]);
                    } else if (jsondata.type === 'listing') {
                        this.setListingID(jsondata.id);
                    } else {
                        this.setRestaurantID(jsondata);
                    }

                    //let restdata = await this.loadRestaurantDetails();
                } catch (e) {
                    //TODO : Throw error Redirect (the specified url is invalid, Please wait while re redirect)
                    //alert("the specified url is invalid, Please wait while re redirect") ;
                    //window.location.replace(process.env.REACT_APP_KUMBA_URL + path);
                }
                loading = false;

                //await this.loadRestaurantDetails();

                if (url === 'share' && objID) {
                    isStatic = false;
                    let url = window.kumbaURL + '/orderpayment/' + objID;
                    let header = { 'foodbots-header': this.state.token };
                    const response = await axios({
                        url,
                        method: 'GET',
                        headers: header,
                    });
                    const scandata = await this.recordScan();
                    var jsondata = response.data;
                    this.setDigitalMenu(false);
                    this.setStaticMenu(false);
                    loading = false;
                    this.setState({ shareData: jsondata, loading });
                    return Promise.resolve(jsondata);
                } else if (url === 'postscan' && objID) {
                    //isStatic = false;

                    const tabledata = await this.getTableDetails(objID);
                    // console.log('TableData -----', tabledata);
                    const scandata = await this.recordScan();
                    // window.removeCookie('listingID');
                    //loading = false;
                    this.setState({ loading: false });
                    this.setDigitalMenu(false);
                    this.setStaticMenu(false);
                    //window.setCookie("isStatic", false);
                    //this.setDigitalMenu(false);

                    return Promise.resolve(tabledata);
                } else if (url === 'changetable' && objID) {
                    isStatic = false;
                    //window.setCookie("isStatic", false);
                    const tabledata = await this.changeTableDetails(
                        null,
                        null,
                        objID
                    );
                    // console.log(tabledata);

                    const scandata = await this.recordScan();
                    loading = false;
                    this.setDigitalMenu(false);
                    this.setStaticMenu(false);
                    // window.removeCookie("listingID");
                    //this.setCountDown(true);
                    this.setState({ isStatic, loading, listingID: null });

                    return Promise.resolve(tabledata);
                } else if (url === 'scanevents' && objID) {
                    isStatic = false;
                    //window.setCookie("isStatic", false);

                    const eventdata = await this.getEventDetails(objID);
                    const scandata = await this.recordScan();
                    this.setDigitalMenu(false);
                    this.setStaticMenu(false);
                    // window.removeCookie('listingID');
                    //this.setCountDown(true);
                    this.setState({ loading: false, listingID: null });
                    return Promise.resolve(eventdata);
                } else if (url === 'qrpayment' && objID) {
                    this.setRestaurantID(objID);
                    const restData = await this.loadRestaurantDetails();
                    // window.removeCookie("listingID");
                    //console.log('rest data =', restData);

                    this.setDigitalMenu(false);
                    this.setStaticMenu(false);

                    this.setState({
                        //payToRest: true,
                        loading: false,
                    });

                    //this.setCountDown(true);
                    return Promise.resolve(restData);
                } else {
                    const tableID = null;
                    const orderID = Cookies.get('orderID') || null;
                    const listingID = Cookies.get('listingID') || null;
                    //const isStatic = Cookies.get("isStatic") || true;

                    // window.setCookie('tableID', tableID);
                    // window.setCookie('orderID', orderID);
                    // window.setCookie('isStatic', isStatic);
                    // window.setCookie('listingID', listingID);

                    if (orderID) {
                        this.setDigitalMenu(false);
                        this.setStaticMenu(false);
                    } else {
                        this.setDigitalMenu(false);
                        this.setStaticMenu(true);
                    }

                    this.setState({ tableID, orderID, listingID });
                    //this.setState({loading : false, restID, tableID, orderID, isStatic});

                    try {
                        if (this.state.token) {
                            await this.getUser();
                            if (this.state.orderID) {
                                let orderdata = await this.getOrder();
                                if (orderdata.table_id) {
                                    await this.getTableDetails(
                                        orderdata.table_id.id
                                    );
                                }
                            }
                        }

                        if (this.state.tableID && !this.state.tabledata) {
                            let tabledata = await this.getTableDetails(
                                this.state.tableID
                            );
                            this.setState({ loading: false });
                            return Promise.resolve(tabledata);
                        } else if (this.state.restID && !this.state.restdata) {
                            let restdata = await this.loadRestaurantDetails();
                            this.setState({ loading: false });
                            return Promise.resolve(restdata);
                        } else if (
                            this.state.listingID &&
                            !this.state.listingdata
                        ) {
                            let listingdata = await this.loadListingDetails();
                            this.setState({ loading: false });
                            return Promise.resolve(listingdata);
                        } else {
                            this.setState({ loading: false });
                            return Promise.resolve(null);
                        }
                    } catch (e) {
                        return Promise.reject(e);
                    }
                }
            }
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async installApp() {
        // Hide the app provided install promotion
        // this.hideInstallPopup();
        // this.setState({showInstallPopup : false});
        console.log('Install App running --- ');
        this.togglePWAInstall(false);
        // Show the install prompt
        this.state.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        const { outcome } = await this.state.deferredPrompt.userChoice;
        // Optionally, send analytics event with outcome of user choice
        console.log(`User response to the install prompt: ${outcome}`);
        // We've used the prompt, and can't use it again, throw it away
        this.setState({ deferredPrompt: null });
        return Promise.resolve();
    }

    static beforeInstallApp(e) {
        // Prevent the mini-infobar from appearing on mobile
        console.log('Before Install App running --- ', e);
        e.preventDefault();
        // Stash the event so it can be triggered later.
        // if(getPWADisplayMode() === "browser") {
        window.deferredPrompt = e;

        // window.deferredPrompt.prompt();
        // deferredPrompt = e;
        // Update UI notify the user they can install the PWA
        // this.showInstallPopup();
        this.togglePWAInstall(true);
        // }
        // showInstallPromotion();
        // Optionally, send analytics event that PWA install promo was shown.
        console.log(`'beforeinstallprompt' event was fired.`);
    }

    static afterInstallApp() {
        // Hide the app-provided install promotion
        // this.setState({showInstallPopup : false});
        console.log('After Install App running --- ');
        this.togglePWAInstall(false);
        // hideInstallPromotion();
        // Clear the deferredPrompt so it can be garbage collected
        window.deferredPrompt = null;
        // Optionally, send analytics event to indicate successful install
        console.log('PWA was installed');
    }
}
