import React, { Component, Suspense } from "react";
import {
  AppContext,
  AppContextProvider,
  withTheme,
} from "./contexts/AppContext";

//Custom CSS
import "./css/modal.css";
import "./css/main_file.css";
import "./css/index.css";
// import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

//React Components
import "react-activity/dist/react-activity.css";
import AppItemCustomization from "./items/ItemCustomization";

import Guests from "./guests/Guests";
import Script from "react-load-script";

import { StripeProvider } from "react-stripe-elements";

import io from "./socket-client";
import AppLoading from "./components/AppLoading";
// import Receipt from "./mainPage/Receipt";
import Receipt from './v2/views/RestaurantReceiptPage/RestaurantReceiptPage';
import Popup from "./components/Popup/Popup";
import Feedback from "./views/feedback/Feedback";
import Checkin from "./checkin/Checkin";
import RestaurantMenuDetails from "./v2/views/RestaurantMenuDetails/RestaurantMenuDetails";
import RestaurantListing from './v2/views/RestaurantListingPage/RestaurantListingPage';
import LoginPopup from "./components/LoginPopup";
import InstallPWA from "./components/InstallPWA";
import "react-datetime/css/react-datetime.css";

const Cart = React.lazy(() => import("./cartMiniComponents/cart"));
const Checkout = React.lazy(() => import("./cartMiniComponents/Checkout"));
const Events = React.lazy(() => import("./events/Events"));
const Offers = React.lazy(() => import("./offers/Offers"));
const OrderPlaced = React.lazy(() =>
  import("./cartMiniComponents/OrderPlaced")
);

const RestaurantDetailsPage = React.lazy(() => import('./v2/views/RestaurantDetailsPage/RestaurantDetailsPage'));
const LoyaltyPage = React.lazy(() => import('./v2/views/LoyaltyPage/LoyaltyPage'));

const Main = React.lazy(() => import("./mainPage/NewMain"));
const BarcodeScanner = React.lazy(() => import("./mainPage/BarcodeScanner"));
const Login = React.lazy(() => import("./signUp/Login"));
const BarcodeLanding = React.lazy(() =>
  import("./mainPage/BarcodeScanLandingCovid")
);
const RestaurantMain = React.lazy(() => import("./mainPage/RestaurantMain"));
const ListRestaurants = React.lazy(() =>
  import("./restaurants/ListRestaurants")
);
const MainMenu = React.lazy(() => import("./mainPage/MainMenu"));
const PayToRestaurant = React.lazy(() => import("./mainPage/PayToRestaurant"));
const PosMenu = React.lazy(() => import("./restaurants/PosMenu"));
const AddressPage = React.lazy(() => import("./v2/views/AddressPage/AddressPage"));
const RestaurantOffersPage = React.lazy(() => import('./v2/views/RestaurantOffersPage/RestaurantOffersPage'));
const Friends = React.lazy(() => import('./friends/Friends'));

const CheckoutStatus = React.lazy(() =>
  import("./cartMiniComponents/CheckoutStatus")
);
const Reservation = React.lazy(() =>
  import("./views/checkin/Reservation")
);

// console.log(process.env)

class App extends Component {
  constructor(props) {
    super(props);
    this.auth2 = null;
    this.googleUser = null;
    this.state = {
      isLoggedin: false,
      email: null,
      stripeLoaded: false,
    };
  }

  onLoad() {
    this.setState({ stripeLoaded: true });
  }

  render() {
    const router = (
        <Router>
            <div>
                <InstallPWA />
                <Popup />
                <LoginPopup />
                <Switch>
                    <Route exact path="/" component={Main} />
                    <RestaurantRoute
                        exact
                        path="/posmenu"
                        component={PosMenu}
                    />

                    <Route exact path="/scan" component={BarcodeScanner} />
                    <Route exact path="/change" component={BarcodeScanner} />
                    <Route exact path="/login" component={Login} />
                    <Route
                        exact
                        path="/checkin/:restID"
                        component={BarcodeLanding}
                    />
                    <Route exact path="/checkin" component={Checkin} />
                    <Route exact path="/friends" component={Friends} />
                    <Route
                        exact
                        path="/postscan/:tableID"
                        component={BarcodeLanding}
                    />
                    <Route
                        exact
                        path="/share/:shareID"
                        component={BarcodeLanding}
                    />
                    <Route
                        exact
                        path="/places/:restID"
                        component={BarcodeLanding}
                    />
                    <Route
                        exact
                        path="/qrpayment/:restID"
                        component={BarcodeLanding}
                    />
                    <Route
                        exact
                        path="/listing/:restID"
                        component={BarcodeLanding}
                    />

                    <Route exact path="/Reservation/:restID" component={Reservation} />
                    <Route
                        exact
                        path="/restaurant/pay"
                        component={PayToRestaurant}
                    />
                    <Route exact path="/receipt/:orderID" component={Receipt} />
                    <Route
                        exact
                        path="/changetable/:tableID"
                        component={BarcodeLanding}
                    />
                    <Route
                        exact
                        path="/scanevents/:eventID"
                        component={BarcodeLanding}
                    />
                    <Route exact path="/events" component={Events} />
                    <Route
                        exact
                        path="/item/:restID/:itemID"
                        component={RestaurantMenuDetails}
                    />
                    {/* <Route exact path="/offers" component={Offers} /> */}
                    <Route exact path="/offers" component={RestaurantOffersPage} />
                    <Route exact path="/loyalty" component={LoyaltyPage} />
                    <UserRoute exact path="/user" component={Main} />
                    <Route exact path="/feedback" component={Feedback} />

                    <RestaurantRoute
                        exact
                        path="/item"
                        component={AppItemCustomization}
                    />
                    <RestaurantRoute
                        exact
                        path="/about-us"
                        component={RestaurantDetailsPage}
                    />
                    <RestaurantRoute
                        exact
                        path="/orderplaced"
                        component={OrderPlaced}
                    />
                    <ListingRoute
                        exact
                        path="/list"
                        component={RestaurantListing}
                    />
                    <RestaurantRoute
                        exact
                        path="/main"
                        component={RestaurantMain}
                    />
                    <RestaurantRoute exact path="/cart" component={Cart} />
                    <RestaurantRoute
                        exact
                        path="/checkout"
                        component={Checkout}
                    />
                    <RestaurantRoute
                        exact
                        path="/address"
                        component={AddressPage}
                    />
                    <Route
                        exact
                        path="/payment/:status/:txnid"
                        component={CheckoutStatus}
                    />
                    <RestaurantRoute exact path="/menu" component={MainMenu} />
                    <RestaurantRoute
                        exact
                        path="/restaurants"
                        component={ListRestaurants}
                    />
                    <RestaurantRoute exact path="/event" component={Guests} />
                </Switch>
            </div>
        </Router>
    );

    return (
      <Suspense fallback={<AppLoading />}>
        <AppContextProvider>
          {process.env.REACT_APP_COUNTRY === "US" ? (
            <Script
              url="https://js.stripe.com/v3/"
              onLoad={this.onLoad.bind(this)}
              onError={() => {
                this.setState({ scriptLoaded: false });
              }}
            />
          ) : null}
          {process.env.REACT_APP_COUNTRY === "US" ? (
            this.state.stripeLoaded ? (
              <StripeProvider
                apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
              >
                {router}
              </StripeProvider>
            ) : (
              <div>Loading</div>
            )
          ) : (
            router
          )}
        </AppContextProvider>
      </Suspense>
    );
  }
}

const AppApp = withTheme(App);
export default AppApp;

const UserRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) => (
      <Route
        {...rest}
        render={(props) =>
          context.loading ? (
            <AppLoading />
          ) : context.isUserDone ? (
            <Component {...props} context={context} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )}
  </AppContext.Consumer>
);

const TableRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) => (
      <Route
        {...rest}
        render={(props) =>
          context.loading ? (
            <AppLoading />
          ) : context.isTableDone ? (
            <Component {...props} context={context} />
          ) : context.isUserDone ? (
            <Redirect
              to={{
                pathname: "/user",
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )}
  </AppContext.Consumer>
);

const RestaurantRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) => (
      <Route
        {...rest}
        render={(props) =>
          context.loading ? (
            <AppLoading />
          ) : context.isRestDone ? (
            <Component {...props} context={context} />
          ) : context.isUserDone ? (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )}
  </AppContext.Consumer>
);

const ListingRoute = ({ component: Component, ...rest }) => (
    <AppContext.Consumer>
        {(context) => (
            <Route
                {...rest}
                render={(props) =>
                    context.loading ? (
                        <AppLoading />
                    ) : context.listingdata ? (
                        <Component {...props} context={context} />
                    ) : context.isUserDone ? (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location },
                            }}
                        />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        )}
    </AppContext.Consumer>
);

const OrderRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) => (
      <Route
        {...rest}
        render={(props) =>
          context.loading ? (
            <AppLoading />
          ) : context.isOrderDone ? (
            <Component {...props} context={context} />
          ) : context.isUserDone ? (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )}
  </AppContext.Consumer>
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <AppContext.Consumer>
    {(context) => (
      <Route
        {...rest}
        render={(props) =>
          context.isRestDone ? (
            <Redirect
              to={{
                pathname: "/main",
                state: { from: props.location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )}
  </AppContext.Consumer>
);
