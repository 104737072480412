import React, { Component } from "react";
import { withTheme } from "../contexts/AppContext";

import "../css/order_style.css";
import PayTmCheckout_Controller from '../controllers/cartMiniComponents/PayTmCheckout.controller'; 
import PayTmCheckoutView from "../views/cartMiniComponentsViews/PayTmCheckoutView";

import AppLoading from "../components/AppLoading";
import postscribe from 'postscribe';
import { Redirect } from "react-router";

class PayTmCheckout extends Component {
	constructor(props) {
		super(props);

		this.isEmail = PayTmCheckout_Controller.isEmail.bind(this);
		this.onLoad = PayTmCheckout_Controller.onLoad.bind(this);
		this.updateUser = PayTmCheckout_Controller.updateUser.bind(this);
		this.onClick = PayTmCheckout_Controller.onClick.bind(this);
        this.confirmPayment = PayTmCheckout_Controller.confirmPayment.bind(this);
        this.processPayment = PayTmCheckout_Controller.processPayment.bind(this);
        this.checkoutOrder = PayTmCheckout_Controller.checkoutOrder.bind(this);
        this.confirmOrder = PayTmCheckout_Controller.confirmOrder.bind(this);
        this.cancelOrder = PayTmCheckout_Controller.cancelOrder.bind(this);

		this.state = {
			rzp: null,
			phone: null,
			name: "",
			email: "",
			validation: false,
            processing : false,
            redirectURL : null,
            online_payments : false,
            offline_payments : false,

		};

		// this.chargeAndCheckout = this.chargeAndCheckout.bind(this);
		// this.chargeAndConfirm = this.chargeAndConfirm.bind(this);
	}

	componentDidMount() {
        let {context} = this.props;
        let scripttag =
            '<script type="application/javascript" src="' +
            process.env.REACT_APP_PAYTM_BACKEND_URL +
            '/merchantpgpui/checkoutjs/merchants/' + process.env.REACT_APP_PAYTM_MERCHANT_ID + '.js" crossorigin="anonymous"><\/script>';
        console.log(scripttag);

        postscribe('#paydiv', scripttag, {
            done : () => {
                if (window.Paytm && window.Paytm.CheckoutJS) {
                    window.Paytm.CheckoutJS.onLoad(() => this.onLoad());
                }  
            }
        });

		if (context) {
            if(context.orderID) {
			    context.getOrder();
		    }

            this.setState({
                phone:
                    this.props.context && this.props.context.isUserDone
                        ? this.props.context.user.phone
                        : null,
                name:
                    this.props.context && this.props.context.isUserDone
                        ? this.props.context.user.first_name
                        : '',
                email:
                    this.props.context &&
                    this.props.context.isUserDone &&
                    this.props.context.user.email
                        ? this.props.context.user.email
                        : '',
                online_payments:
                    this.props.context && this.props.context.restdata
                        ? this.props.context.restdata.online_payments
                        : null,

                offline_payments:
                    this.props.context && this.props.context.restdata
                        ? this.props.context.restdata.offline_payments
                        : null,
            });
        }

	}

    componentDidUpdate(prevProps) {
        if(this.props.context && prevProps.context && JSON.stringify(this.props.context.user) !== JSON.stringify(prevProps.context.user)) {
            this.setState({
                phone:
                    this.props.context && this.props.context.isUserDone
                        ? this.props.context.user.phone
                        : null,
                name:
                    this.props.context && this.props.context.isUserDone
                        ? this.props.context.user.first_name
                        : '',
                email:
                    this.props.context &&
                    this.props.context.isUserDone &&
                    this.props.context.user.email
                        ? this.props.context.user.email
                        : '',
                online_payments:
                    this.props.context && this.props.context.restdata
                        ? this.props.context.restdata.online_payments
                        : null,

                offline_payments:
                    this.props.context && this.props.context.restdata
                        ? this.props.context.restdata.offline_payments
                        : null,
            });
        }
    }

	render() {
        if(this.state.goCheckout) {
            return(<Redirect to="/checkout" />);
        } else if(this.props.context && this.props.context.restdata) {
            return (
                <PayTmCheckoutView
                    parent={this}
                />
            );
        } else {
            return (<AppLoading />);
        }
	}
}

const AppPTmCheckout = withTheme(PayTmCheckout);
export default AppPTmCheckout;
