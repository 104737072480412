import React, { useState, useEffect } from "react";
import "../../css/login.css";
import { Modal, ModalBody } from "reactstrap";
import { withTheme } from "../../../contexts/AppContext";
import RestaurantLoginPageIN from "./RestaurantLoginPageIN";
import { Redirect, withRouter } from "react-router-dom";
import axios from "axios";

const RestaurantLoginController = ({ context, closeModal, history, skip }) => {
  const [pageOpen, setPageOpen] = useState(true);
  const [phoneValid, setPhoneValid] = useState(false);
  const [otp, setOtp] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [otpReceived, setOtpReceived] = useState(null);
  const [otpVerified, setOtpVerified] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [stage, setStage] = useState(1);
  const [lastName, setLastName] = useState(null)
  const [redirect, setRedirect] = useState(false);
  const [phoneValue, setPhoneValue] = useState(
    context && context.user ? context.user.phone : ""
  );
  const [name, setName] = useState(
    context.user ? context.user.first_name : ''
  );
  const [seconds, setSeconds] = useState(30);
  const [firstBtnDisabled, setFirstBtnDisabled] = useState(false);
  const [secondBtnDisabled, setSecondBtnDisabled] = useState(false);
  const [enable_whatsapp, set_enable_whatapp] = useState(null)
  const [loginRedirect, set_loginRedirect] = useState(false);

  const [askName, setAskName] = useState(false);

  const countdown = () => {
    setSeconds((prev) => {
      if (prev === 0) {
        return 0;
      }
      return prev - 1;
    });
  };

  const onChangeOTP = (otp) => {
    var value = otp + "";
    if (value.length < 4) {
      value = "0".repeat(4 - value.length) + value;
    }
    // console.log(otp, value);
    setOtp(otp);
  };

  const onChange = (e) => {
    var num_pattern = /^[0-9]{10}$/g;
    var value = e.target.value;
    var confirmed = value.match(num_pattern) ? true : false;
    setPhoneValue(value);
    setPhoneValid(confirmed);
  };

  const onChangeName = (e) => {
    var value = e.target.value;
    setName(value);
  };
  const onChangeLastName = e => {
    var value = e.target.value;
    setLastName(value);
  }

  const sendOTP = async () => {
      try {
          if (!phoneValid) {
              return;
          }
          setFirstBtnDisabled(true);
          var data = await context.sendOTP(window.phoneCode + phoneValue);
          if (data && data.otp) {
              setOtpReceived(data.otp);
              setOtpSent(true);
              setStage(2);
          } else {
              setOtpSent(true);
              setStage(2);
          }
          const count = setInterval(countdown, 1000);
          setFirstBtnDisabled(false);
          return Promise.resolve();
      } catch (e) {
          // alert(e.message);
          setFirstBtnDisabled(false);
          context.popup('Something went wrong. Please try again', 'error');
          return Promise.reject(e);
      }
    
  };

  const sendPhoneNum = async () => {
    // console.log('Sending ---', phoneValue, name)
    try {
      context.setContextState({
        loading: true
      });
      var data = await context.sendPhoneNum(window.phoneCode + phoneValue);
      context.setContextState({
        loading: false
      });
       setRedirect(true);
       set_loginRedirect(true);
      return Promise.resolve();
      //console.log('LOGIN CONTEXT --', context);
    } catch (e) {
      context.setContextState({
        loading: false
      });
      // alert(e.message);
      context.popup("Something went wrong. Please try again", "error");
      return Promise.reject(e);
    }
  };

  const resendOTP = async () => {
    try {
      var data = await context.resendOTP(window.phoneCode + phoneValue);
      if (data && data.otp) {
        setOtpReceived(data.otp);
        setOtpSent(true);
        setStage(2);
      } else {
        setOtpSent(true);
        setStage(2);
      }
      return Promise.resolve(null);
    } catch (e) {
      // alert(e.message);
      context.popup('Something went wrong. Please try again', "error");
      return Promise.reject(e);
    }
  };

  const verifyOTP = async () => {
      try {
          setSecondBtnDisabled(true);

          var data = await context.verifyOTP(
              window.phoneCode + phoneValue,
              otp
          );
          // console.log('COMP OTP RETURN DATA ---', data);
          setOtpSent(false);
          if (data) {
              setOtpVerified(true);
          }
          context.setContextState({
              // isLoggedIn: true,
              loading: false,
          });

          console.log('USER :::::' + data);

          if (data.first_name) {
              setRedirect(true);
              set_loginRedirect(true);
          } else {
              setAskName(true);
          }
          setSecondBtnDisabled(false);
          //console.log("LOGIN CONTEXT --", context);
          // TODO: finish what happens after login
          // if(afterLogin){
          //   console.log('After Login')
          //   afterLogin();
          // }
          return Promise.resolve()
      } catch (e) {
          setSecondBtnDisabled(false);
          context.popup('Invalid OTP. Please try again.', 'error');
          setOtp('');
          return Promise.reject(e);
      }
    
  };

  const updateUser = async () => {

    try {
        const userDasta = {
        first_name: name,
        // last_name: lastName
        }
        // if(enable_whatapp){
        //   updateUser.enable_whatapp = enable_whatapp
        // }
        console.log('UPDATING USER ---', userDasta, enable_whatsapp);
        if (!name) {
        context.popup('First name is required')
        } else {
            var url = window.kumbaURL + "/profile/updateuser";
            let header = { "foodbots-header": context.token };
            let res = await axios(url, {
                method: 'PUT',
                headers: header,
                data: !enable_whatsapp ? { ...userDasta } : { ...userDasta, enable_whatsapp }
            })
                
            context.loginFromPopup(context.loginRedirect)
            await context.getUser();
            setRedirect(true)
            set_loginRedirect(true);
        }
    } catch(e) {
            context.popup('Error saving user information', 'error');
            return Promise.reject(e);
    }
  }

//   useEffect(() => {
//     console.log('USER DON UPDATE OOOOOO', context.user)
//     if (context.user && context.user.first_name) {
//         setRedirect(true);
//     }
//   }, [context.user])

  if (loginRedirect) {
    return <Redirect to={context.loginRedirect} />
  } else
    return (
      <RestaurantLoginPageIN
        pageOpen={pageOpen}
        setPageOpen={setPageOpen}
        stage={stage}
        setStage={setStage}
        otpSent={otpSent}
        otpVerified={otpVerified}
        closeModal={closeModal}
        updateUser={updateUser}
        context={context}
        phoneValid={phoneValid}
        setPhoneValid={setPhoneValid}
        onChange={onChange}
        onChangeName={onChangeName}
        set_enable_whatapp={set_enable_whatapp}
        onChangeLastName={onChangeLastName}
        lastName={lastName}
        setRedirect={setRedirect}
        otp={otp}
        setOtp={setOtp}
        onChangeOTP={onChangeOTP}
        sendOTP={sendOTP}
        sendPhoneNum={sendPhoneNum}
        otpReceived={otpReceived}
        seconds={seconds}
        resendOTP={resendOTP}
        firstBtnDisabled={firstBtnDisabled}
        secondBtnDisabled={secondBtnDisabled}
        history={history}
        skip={skip}
        verifyOTP={verifyOTP}
        askName={askName}
      />
    );
};

const AppRestaurantLoginController = withRouter(
  withTheme(RestaurantLoginController)
);
export default AppRestaurantLoginController;
