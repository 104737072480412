import React from 'react';
import {
    Row,
    Col,
    Button,
    ListGroup,
    ListGroupItem,
    Navbar,
    Modal
} from "reactstrap";
import { Link } from "react-router-dom";
import sidebar_curve from "../../img/sidebar_curve.png";
import CustomBtn from '../../components/CustomBtn';


export default (props) => {
    const { parent, styles, backRow } = props;
    return (
        <div style={{ backgroundColor: parent.props.context.backgroundColor }}>
            {/* <img
                src={sidebar_curve}
                style={{ left: 0, top: 0, position: "fixed", width: "100%" }}
            /> */}
            <div
                style={{
                    paddingTop: "40%",
                    width: "100%",
                    position: "fixed",
                    zIndex: 100,
                    textAlign: "center"
                }}
            >
                <label style={{ ...styles.nameStyle, color: parent.props.context.textColor }}>
                    {parent.props.context && parent.props.context.user ? (
                        parent.props.context.user.first_name
                    ) : (
                            "Guest"
                        )}
                </label>
                {parent.props.context && parent.props.context.user ? (
                    <small
                        style={{ color: parent.props.context.textColor, fontFamily: "Lato" }}
                    >
                        {parent.props.context.user.phone}
                    </small>
                ) : null}
            </div>
            <ListGroup
                style={{
                    width: "100%",
                    left: 0,
                    position: "absolute",
                    top: "50%"
                }}
            >
                <ListGroupItem style={{ backgroundColor: parent.props.context.backgroundColor }}>
                    <div onClick={parent.props.sidebarClose}>{backRow}</div>
                </ListGroupItem>
                {parent.props.context.subrestdata ? (
                    <ListGroupItem style={{ backgroundColor: parent.props.context.backgroundColor }}>
                        <Link to={{ pathname: "/restaurants" }}>
                            <Row>
                                <Col md={2} xs={2}>
                                    <img src={sidebar_curve} style={{ width: "2%" }} />
                                </Col>
                                <Col md={10} xs={10}>
                                    <label style={{ ...styles.labelStyle, color: parent.props.context.textColor }}>
                                        Choose Restaurant
                          </label>
                                </Col>
                            </Row>
                        </Link>
                    </ListGroupItem>
                ) : null}
            </ListGroup>

            {parent.props.context && parent.props.context.isUserDone ? (
                <Navbar style={styles.Navbarstyle}>
                    <Button
                        style={{ ...styles.Buttonstyle, color: parent.props.context.textColor }}
                        onClick={parent.props.context.logout}
                        block
                        color="danger"
                    >
                        Logout
                  </Button>
                </Navbar>
            ) : (
                    <Navbar style={styles.Navbarstyle}>
                        <Button
                            style={{ backgroundColor: parent.props.context.themeColor, color: parent.props.context.svgTextColor }}
                            onClick={() => parent.setState({ loginForm: true })}
                            block
                            // color="danger"
                        >
                            Login
                  </Button>
                    </Navbar>
                )}
        </div>
    )
}

