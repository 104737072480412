import React, { useEffect, useState } from "react";
import { withTheme } from "../../../../contexts/AppContext";
import EachRestSuggestion from "./EachRestSuggestion";


/**
 * 
 * TODO
 * isDigital menu = false
 */
function RestSuggestions({ context }) {
    const [allowOrder, setAllowOrder] = useState(false);

    // FIX THIS 👇🏽
    // var subrest = context.subrestdata.filter((eachrest) => {
    //     return (
    //         eachrest.restdata.restaurant_id ===
    //         context.selectedRestID
    //     );
    // });

    useEffect(() => {
        // console.log('running');

        if (context) {
            if (
                context.restdata &&
                context.restdata.subscription_package === "digital-menu"
            ) {
                return;
            } else if (context.selectedMenuType === "dine-in") {
                if (context.tableID && context.restdata.isOpen) {
                    setAllowOrder(true);
                }
                //   return;
            } else if (context.selectedMenuType === "delivery") {
                if (context.restdata.isDeliveryOpen) {
                    setAllowOrder(true);
                }
                //   return;
            } else if (context.selectedMenuType === "takeaway") {
                if (context.restdata.isTakeawayOpen) {
                    setAllowOrder(true);
                }
                //   return;
            }
        }
    }, [context.restdata, context.selectedMenuType, context.tableID, context]);

    if(context && context.restdata && context.restdata.menu_items && context.restdata.menu_items.filter(x => x.isRecommended).length > 0) {

    return (
        <div >
            <label style={{ color: window.themeColor }} className="m-2">
                Try our recommendations
            </label>
            <div className="d-flex pb-4" style={{ overflowX: "scroll", width: '90vw' }}>
                {context.restdata.menu_items.filter(x => x.isRecommended).map((item, i) => {
                        return (
                            <EachRestSuggestion
                                key={item.item_id}
                                title={item.name.toUpperCase()}
                                theme={window.themeColor}
                                description={item.description}
                                price={item.price}
                                img={item.image}
                                qty={item.qty}
                                inCart={item.inCart}
                                id={item.item_id}
                                outOfStock={item.isOutOfStock}
                                isDigitalMenu={false}
                                isOpen={true}
                                // parent={this}
                                item={item}
                                // restdata={subrest?.restdata}
                                allowOrder={allowOrder}
                                displayType={"search"}
                                category={context.categoryTable ? context.categoryTable[item.category_id] : null}
                            />
                        );
                })}
            </div>
        </div>
    );
    } else {
        return null;
    }
}
export default withTheme(RestSuggestions);
