import axios from 'axios';
import cogoToast from "cogo-toast";

export default class PackageCart_Controller {

    static addOrders(pkg) {
        var data = {
            item_id : pkg.id,
            quantity : 0,
            itemdata : pkg,
            item_total : pkg.price,
            restID : pkg.rest
        }
		this.ordersList.push(data);
		this.setState({refresh : !this.state.refresh});
    }

    static removeItemInOrder(id) {
		this.ordersList = this.ordersList.filter((eachorder) => eachorder.id !== id);
		this.setState({refresh : !this.state.refresh});
    }

    static updateItemInOrder(id, quantity) {
        if(quantity >= 0) {
                this.ordersList.forEach((order) => {
                    if(order.id === id) {
                        order.quantity = quantity;
                    }
				});
				this.setState({refresh : !this.state.refresh});
        }
	}


    static async submitOrder(rzpid, credits_used) {
		const hide = cogoToast.loading("Placing Order ... ", { hideAfter: 0 });
		try {
			var guest = await this.props.addGuests();
		  	let url=window.kumbaURL + "/mainorders/add";
		  	let header = {"foodbots-header" : this.props.context.token};
			var orders = [];
			  
		  	this.ordersList.forEach(order => {
				var eachorder = {
					item_id : order.item_id,
					quantity : order.quantity
				};
				orders.push(eachorder);
			});

		  	const response = await axios({
				url,
				headers: header,
				method : 'POST',
				data : {
					order_id: this.props.context.orderID,
					order_type : 'dine-in',
					rest_id : this.props.context.restID,
					packagesList : orders,
					status : "accepted",
					user : this.props.context.user ? this.props.context.user.user_id : null,
					payment_id : rzpid,
					guest : guest.id,
					credits_used : credits_used
				}
			});
			  
			var data=response.data;
			hide();
			cogoToast.success("Placed order successfully.", {
				hideAfter: 2
			});

		  /*Important : To reset all state variables for next order use */
          this.clearOrder();
		  this.props.closeDialog();
		  //await this.props.getGuests();
		} catch(err) {
			  this.setState({error : err});
			  hide();
			  //alert("Could not add '" + this.props.itemdata.name + "' to cart");
			  cogoToast.error("Could not place order. Please try again", {
				hideAfter: 2
			});
		}
	  }
	
	  static clearOrder() {
		this.props.context && this.props.context.evendata && this.props.context.eventdata.packages 
		&& (this.props.context.eventdata.packages.forEach(pkg => {
			if(pkg.isActive) {
				this.addOrders(pkg);
			}
		})
		)
      }

      static closeDialog() {
        this.setState({dialogOpen : false});
    }

    static payAtRestaurant() {
        console.log("TO BE IMPLEMENTED");
    }

    
	/*
	
	async fetchOrder() {
		try {
			if(this.props.guest && this.props.guest.order) {
				//alert(JSON.stringify(this.props.guest))
				let url=window.kumbaURL + "/mainorders/sorted/" + this.props.guest.order.order_id ;
				let header={"foodbots-header":this.props.context.token};
				var response = await axios({
					url,
					headers : header,
					method : "GET"
				}); 
				var data = response.data;
				console.log(data);
				this.setState({orderdata : data});
			} else {
				this.setState({orderdata : null});
			}
		} catch (error) {
			console.log(error);
		}
	}

	*/

}