export default class login_US_Controller {
    static onChangeOTP(otp) {
        //console.log(otp);
        var value = otp + '';
        if (value.length < 4) {
            value = '0'.repeat(4 - value.length) + value;
        }
        // console.log(otp, value);
        this.setState({ otp: value });
    }

    static onChange(e) {
        var num_pattern = /^[0-9]{10}$/g;
        var value = e.target.value;
        var confirmed = value.match(num_pattern) ? true : false;
        this.setState({ email: value, emailValid: confirmed });
    }

    static responseGoogle(response) {
        // console.log(response);
        if (!response.error) {
            this.props.context.googlelogin(response);
        }
    }

    static async sendOTP() {
        try {
            var response = await this.props.context.sendEmailOTP(
                this.state.email
            );
            this.setState({ otpsent: true });
        } catch (e) {
            // alert(e.message);
            this.props.context.popup(`${e.message}`, 'error');
        }
    }

    static async resendOTP() {
        try {
            var response = await this.props.context.sendEmailOTP(
                this.state.email
            );
            this.setState({ otpsent: true });
        } catch (e) {
            this.props.context.popup(`${e.message}`, 'error');
            // alert(e.message);
        }
    }

    static async verifyOTP() {
        try {
            var response = await this.props.context.verifyEmailOTP(
                this.state.email,
                this.state.otp
            );
            this.setState({ otpsent: false, showPopup: true });
            //this.props.closeModal();
        } catch (e) {
            this.props.context.popup(`${e.message}`, 'error');
            // alert(e.message);
        }
    }
}
