import React from "react";
import "./KbTimeline.css";

const KbTimeline = ({ steps, currentStep }) => {
  const stepArr = [
    {
      stepNumber: 1,
      stepText: "Order Confirmed",
    },
    {
      stepNumber: 2,
      stepText: "Preparing your order",
    },
    {
      stepNumber: 3,
      stepText: "Order is ready",
    },
    {
      stepNumber: 4,
      stepText: "Your order has been picked up",
    },
    {
      stepNumber: 5,
      stepText: "Your order has been delivered",
    },
  ];

  let modifiedStepArr = [];
  if (steps) {
    modifiedStepArr = stepArr.slice(0, steps);
  }

  return (
    <div className="kb-timeline-container">
      {modifiedStepArr.map((step) => {
        return step.stepNumber === 1 ? (
          <div
            className="kb-timeline-flex"
            style={{ opacity: currentStep >= step.stepNumber ? "1" : "0.5" }}
            key={step.stepNumber}
          >
            <div className="kb-timeline-circle"></div>
            <div className="kb-timeline-text">{step.stepText}</div>
          </div>
        ) : (
          <div
            className="kb-timeline-with-tail"
            style={{ opacity: currentStep >= step.stepNumber ? "1" : "0.5" }}
            key={step.stepNumber}
          >
            <div className="kb-timeline-line"></div>
            <div className="kb-timeline-flex">
              <div className="kb-timeline-circle"></div>
              <div className="kb-timeline-text">{step.stepText}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default KbTimeline;
