import React from 'react';
import { withTheme } from '../contexts/AppContext';
import { Modal, ModalBody } from 'reactstrap';
import Btn from './CustomBtn'

export default withTheme(props => {
    const { context } = props;
    return (
        <Modal isOpen={context.PWAPopup} style={{ marginTop: '60vh' }} size='lg'>
            <div className='card w-100 text-center'>
                    <div className='card-body m-2'>
                            <h3>Install App</h3>
                            <p>Lorem, ipsum dolor</p>
                            <Btn
                                value='Install'
                                customeClass='w-100'
                            />
                    </div>
            </div>
        </Modal>
    )
})
