import React, { useState, useEffect } from 'react';
import { withTheme } from '../../contexts/AppContext';
import StarRatings from "react-star-ratings";
import TextInput from '../../components/TextInput';
import CustomeBtn from '../../components/CustomBtn';
import Axios from 'axios';
import { Modal, Card, CardBody } from 'reactstrap';
import { Spinner } from 'react-activity';
import { Redirect } from 'react-router';
import Header from '../../components/Header';
import { InputGroup, InputGroupAddon, InputGroupText, Input, Label } from 'reactstrap';

export default withTheme(props => {
    // console.log('feedback ----', props);
    const { context } = props;
    const [feedback, setfeedback] = useState(null);
    const [service_rating, setservice_rating] = useState(0);
    const [food_rating, setfood_rating] = useState(0);
    const [drinks_rating, setdrinks_rating] = useState(0);
    const [ambience_rating, setambience_rating] = useState(0);
    const [overall_rating, setoverall_rating] = useState(0);
    const [user, setuser] = useState(null);
    const [rest, setrest] = useState(null);
    const [table, settable] = useState(null);
    const [phone, setphone] = useState(
        context.user && context.user.phone
            ? context.user.phone.substr(-10)
            : null
    );
    const [email, setemail] = useState(
        context.user && context.user.email ? context.user.email : null
    );
    const [name, setname] = useState(
        context.user && context.user.first_name ? context.user.first_name : null
    );
    const [company, setcompany] = useState(null);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("sending")

    const handleFeedbackSubmit = () => {
        setLoading(true)
        let url = window.kumbaURL + "/feedback";
        const data = {
            feedback,
            service_rating,
            food_rating,
            drinks_rating,
            ambience_rating,
            overall_rating,
            user,
            rest,
            table,
            phone : window.phoneCode + phone,
            email,
            name,
            company
        };
        // console.log('sending --', data);
        Axios(url, {
            method: 'POST',
            data
        })
            .then(res => {
                // console.log(res)
                setStatus('success');
                setTimeout(() => {
                    setStatus('redirect')
                    setLoading(false);
                }, 4000);
            })
            .catch(err => {
                console.log(err);
                setStatus('error');
                setTimeout(() => {
                    setLoading(false);
                }, 3000);
            })
    };

    useEffect(() => {
        setrest(context.restID);
        if(context.user) {
            setuser(context.user.user_id);
        }
        settable(context.tableID);
    }, []);

    const PopupStatus = () => {
        switch (status) {
            case 'loading':
                return (
                    <div className='text-center'>
                        <Spinner />
                        <h5>Sending</h5>
                    </div>
                )
            case 'error':
                return (
                    <div className='text-center'>
                        <h5>Error Send Feedback</h5>
                        <p>Please try again.</p>
                    </div>
                )
            case 'success':
                return (
                    <div className='text-center'>
                        <h4>Feedback Sent</h4>
                        <h6>Thank you for your feedback.</h6>
                    </div>
                )
            default:
                return <div className='text-center'>
                    <Spinner />
                    <h5>Sending</h5>
                </div>
        }
    }


    if (status === 'redirect') {
        return <Redirect to={'/main'} />
    } else {
        return (
					<div className="container mt-3" style={{ fontFamily: 'Poppins' }}>
						<Header
							title={'Feedback'}
							current={'feedback'}
							goBack={() => props.history.goBack()}
						/>
						<Modal isOpen={loading} style={{ marginTop: '130px' }}>
							<Card>
								<CardBody>
									<PopupStatus />
								</CardBody>
							</Card>
						</Modal>
						<div className="" style={{ marginTop: '120px' }}>
							<div className="text-center">
								<h4 style={{ color: context.themeColor }}>
									Thank you for your patronage.
								</h4>
								<p
									style={{
										color: context.themeColor,
										fontSize: '15px',
									}}
								>
									Please describe your experience today
								</p>
								<hr />
							</div>

							<EachRating
								title={'Food'}
								rating={food_rating}
								textColor={context.themeColor}
								themeColor={context.themeColor}
								changeRating={setfood_rating.bind(this)}
							/>

							<EachRating
								title={'Beverages'}
								rating={drinks_rating}
								textColor={context.themeColor}
								themeColor={context.themeColor}
								changeRating={setdrinks_rating.bind(this)}
							/>

							{context.restdata && context.restdata.type !== 'homechef' ? (
								<EachRating
									title={'Service'}
									rating={service_rating}
									textColor={context.themeColor}
									themeColor={context.themeColor}
									changeRating={setservice_rating.bind(this)}
								/>
							) : null}

							{context.restdata && context.restdata.type !== 'homechef' ? (
								<EachRating
									title={'Ambience'}
									rating={ambience_rating}
									textColor={context.themeColor}
									themeColor={context.themeColor}
									changeRating={setambience_rating.bind(this)}
								/>
							) : null}

							<EachRating
								title={'Overall'}
								rating={overall_rating}
								textColor={context.themeColor}
								themeColor={context.themeColor}
								changeRating={setoverall_rating.bind(this)}
							/>

							<div className="mt-4">
								<h5
									className="ml-1 mr-2"
									style={{
										color: context.themeColor,
										fontSize: '13px',
									}}
								>
									Any other suggestions
								</h5>
								<textarea
									placeholder="Type suggestions here."
									className="w-100 p-2"
									style={{
										background: 'white',
										color: context.textColor,
									}}
									rows={5}
									onChange={(e) => setfeedback(e.target.value)}
								/>
							</div>

							<div className="mt-2">
								<TextInput
									labelText="Name"
									placeholder="Name"
									value={name}
									style={{ backgroundColor: 'white' }}
									onChange={(e) => setname(e.target.value)}
								/>
								<TextInput
									labelText="Email"
									placeholder="Email"
									type="email"
									value={email}
									onChange={(e) => setemail(e.target.value)}
								/>
								{/* <TextInput
                            labelText="Mobile Number"
                            placeholder="Mobile Number"
                            onChange={e => setphone(e.target.value)}
                        /> */}
								<Label
									style={{
										color: context.textColor,
										fontSize: '13px',
									}}
								>
									Phone No:
								</Label>
								<br />
								<InputGroup className="mb-2">
									<InputGroupAddon addonType="prepend">
										<InputGroupText
											style={{
												backgroundColor: context.highlightColor,
												color: context.textColor,
											}}
										>
											{window.phoneCode}
										</InputGroupText>
									</InputGroupAddon>
									<Input
										value={phone}
										placeholder="Phone Number"
										// type='number'
										style={{
											background: 'white',
											color: context.textColor,
										}}
										onChange={(e) => setphone(e.target.value)}
									/>
								</InputGroup>
								<TextInput
									labelText="Company Name"
									placeholder="Company Name"
									onChange={(e) => setcompany(e.target.value)}
								/>
							</div>

							<CustomeBtn
								value="Submit"
								customeClass={'w-100 mb-3 mt-3'}
								onClick={handleFeedbackSubmit}
							/>
						</div>
					</div>
				)
    }
});


const EachRating = (({ title, rating, changeRating, textColor, themeColor }) => {
    return (
        <div className='row mt-1 ml-1 d-flex align-items-center' style={{background : window.backgroundColor}}>
            <div className="col-6 pt-3">
                <p style={{ color: textColor, fontSize: "16px", textAlign: "left" }}>{title}</p>
            </div>
            <div className="col-6">
                <StarRatings
                    rating={rating}
                    starDimension="20px"
                    changeRating={e => changeRating(e)}
                    starSpacing="3px"
                    numberOfStars={5}
                    starRatedColor={themeColor}
                />
            </div>
        </div>
    )
});
