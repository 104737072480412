import React, { useState, useEffect } from "react";
import "./KbRestaurantItem.css";
import {Card, CardImg, CardText, CardBody} from 'reactstrap';

const KbRestaurantItem = ({
  restdata
}) => {
  // console.log('ITEM ---', item)
  // const [imgSrc, setImgSrc] = null;
  // const [showImg, setShowImg] = true;
//   console.log(item);

const gotoRestaurant = () => {
    let url = process.env.REACT_APP_KUMBA_URL;
    let urlParts = url.split(".");

    let finalUrl = null;

    if(restdata.web_identifier) {
        finalUrl = "https://" + restdata.web_identifier + "." + urlParts[1] + "." + urlParts[2];
    } else {
        finalUrl = url + "/places/" + restdata.restaurant_id;
    }
    window.open(finalUrl);
}

return(
    <Card onClick={() => gotoRestaurant()} className='m-2'>
    {restdata.logo_image ? <CardImg top width="10%" height="10%" src={restdata.logo_image} /> : <CardImg top width="10%" height="10%" src='https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg' />}
        <CardBody>
            <h6
              className='text-center'
              style={{ color: window.themeColor, textTransform: "uppercase" }}
            >
              {restdata.name.toUpperCase()}
            </h6>
            {restdata.cuisine && restdata.cuisine[0] ? 
                <div className='badge text-center p-0' style={{ whiteSpace: 'pre-wrap'}}>
                    {restdata.cuisine[0].split(",").map((eachcuisine, index) => index !== restdata.cuisine[0].split(",").length-1 ? eachcuisine + " | " : eachcuisine)}
                </div> 
                : null
            }
        </CardBody>
    </Card>
)

//   return (
//     <div
//       className="kb-menu-item p-2"
//       style={{ background: window.highlightColor, position: 'relative' }}
//       onClick={() => gotoRestaurant()}
//     >
//       {/* <div className='kb-menu-item-eatarian' style={{background: eatarianColor}}></div> */}
     
//       <div className="kb-menu-item-left">
//         <div className='row ml-1'>
//           {/* <Link to={`/item/${context.restdata.restaurant_id}/item_id`}> */}
//             <h3
//               className={`kb-menu-item-left-heading`}
//               style={{ color: window.themeColor, textTransform: "uppercase" }}
//               onClick={() => gotoRestaurant()}
//             >
//               {restdata.name}
//             </h3>
            
//           {/* </Link> */}
//         </div>
//         {restdata.cuisine && restdata.cuisine[0] ? 
//             <div className='badge'>
//                 {restdata.cuisine[0].split(",").map((eachcuisine, index) => index !== restdata.cuisine[0].split(",").length-1 ? eachcuisine + " | " : eachcuisine)}
//             </div> 
//             : null
//         }
//         <p
//           className="kb-menu-item-left-text"
//           onClick={() => gotoRestaurant()}
//           style={{ color: window.textColor }}
//         >
//           {restdata.about_us ? restdata.about_us.length > 100 ? restdata.about_us.substr(0,100) + "..." : restdata.about_us : null}
//         </p>
//       </div>
      
//         <div className="kb-menu-item-right">
          
//             <div
//               className="kb-menu-item-right-img-container"
//             >{
//                 restdata.logo_image ? (
//               <img
//                 src={restdata.logo_image}
//                 alt={restdata.name}
//                 className="kb-menu-item-right-img"
//                 onError={() => setShowImg(false)}
//               />) : <div></div>}
//             </div>
//         </div>
//     </div>
//   );
};

export default KbRestaurantItem;
