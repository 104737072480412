import React, { useState, useEffect } from "react";
import "./KbChoiceField.css";
import { Input } from "reactstrap";

const KbChoiceField = ({
  onChange,
  onSelect,
  onSelectRadio,
  optionViews,
  customization,
  itemdata,
  title,
  parent,
  elements,
  increasePrice,
  decreasePrice,
  itemPriceRadio,
  custom,
  numSelectedChoices,
  choices,
  updatePrice,
  setItemPriceRadio
}) => {

  const [selectedChoice, setSelectedChoice] = useState([]);
  const [textInput, setTextInput] = useState("");

  // console.log('Option Views ----', optionViews)
  // console.log({customization})
  // console.log({itemdata})

  const getPrice = (choicesList) => {
      const options = customization.options.filter((item) => choicesList.includes(item.id));
      let price = 0;
      for(let option of options) {
        price += option.price;
      }
      return price;
  }

  useEffect(() => {
    if (optionViews.length > 0 && customization.minChoices !== null && customization.minChoices > 0 && selectedChoice.length === 0) {
        if(customization.optionType === "radio") {
            setSelectedChoice((prevChoice) => {
                let newVal = optionViews[0].id;
                let existingChoice = prevChoice ? 
                    typeof prevChoice === "string" ? prevChoice : 
                        prevChoice.length > 0 ? prevChoice[0] : null
                        : null;
                const existingOption = existingChoice ? customization.options.filter((item) => item.id === existingChoice).pop() : {price : 0};
                const option = newVal ? customization.options.filter((item) => item.id === newVal).pop() : {price : 0};
                
                console.log({prevChoice, existingChoice, newVal, existingOption, option});
                console.log(existingOption.price, option.price);
                let newprice = option.price - existingOption.price;
                updatePrice(newprice);
                onSelectRadio(newVal);

                return newVal;
            });
        }
    }
  }, []);

  const updatePriceCheckbox = (val, type) => {
     const option = customization.options.filter((item) => item.id === val);

    if (type === "increase") {
      increasePrice(option[0].price);
    } else if (type === "decrease") {
      decreasePrice(option[0].price);
    }
  };

  const updatePriceRadio = (val) => {
    const option = customization.options.filter((item) => item.id === val);
    // const existingOption = customization.options.filter((item) => item.id === val)

    // const newPrice = Number(itemPriceRadio) + Number(option[0].price);

    // updatePrice(newPrice);
    updatePrice(option[0].price);
  };

  const radioChangeHandler = (e) => {
        setSelectedChoice((prevChoice) => {
                let newVal = e.target.value;
                let existingChoice = prevChoice ? 
                    typeof prevChoice === "string" ? prevChoice : 
                        prevChoice.length > 0 ? prevChoice[0] : null
                        : null;
                const existingOption = existingChoice ? customization.options.filter((item) => item.id === existingChoice).pop() : {price : 0};
                const option = customization.options.filter((item) => item.id === newVal).pop();
                let newprice = option.price - existingOption.price;
                updatePrice(newprice);
                onSelectRadio(newVal);

                return newVal;
        });
  };

  const textChangeHandler = (e) => {
    onChange(e.target.value);
    setTextInput(e.target.value);
  };

  const checkChangeHandler = e => {
    const { value, checked } = e.target;
    console.log({value, checked})

    setSelectedChoice(prevChoices => {
      const customCondition = (prevChoices.length < customization.maxChoices || customization.maxChoices === null ||customization.maxChoices === 0);

      const itemCondition = (itemdata.maxChoices === null || itemdata.maxChoices === 0 || (numSelectedChoices + 1) <= itemdata.maxChoices);

      console.log({itemCondition, customCondition});

      const onSelectCondition = customCondition && itemCondition;

      const itemFound = prevChoices && prevChoices.length > 0 ? prevChoices.find(val => val === value) : null;
      let newArr = [];

      let oldprice = getPrice(prevChoices);

      if(!itemFound && checked && onSelectCondition) {
        newArr = [...prevChoices, value];
        let newprice = getPrice(newArr);
        let diffprice = newprice - oldprice;
        console.log({diffprice, oldprice, newprice});
        increasePrice(diffprice);
        onSelect(newArr);
        return newArr;
      }

      if(itemFound && !checked) {
        newArr = prevChoices.filter(x => x !== value);
        let newprice = getPrice(newArr);
        let diffprice = newprice - oldprice;
        console.log({diffprice, oldprice, newprice});
        // if(customization.minChoices === null || customization.minChoices === 0 || newArr.length > customization.minChoices) {
        increasePrice(diffprice);
        onSelect(newArr);
        return newArr
        // } else {
        //     return prevChoices;
        // }
      }

      return prevChoices;
    })
  }

  return (
    <div className="kb-choice-field-container">
      <hr />
      <h2
        className="kb-choice-title-new"
        // style={{ color: parent.props.context.textColor }}
      >
        {title}
      </h2>
      {customization.optionType === "textarea" ? (
        <Input
          type="textarea"
          name={customization.id}
          placeholder="Type here.."
          id={customization.id}
          value={textInput}
          onChange={(e) => textChangeHandler(e)}
        />
      )
      : customization.optionType === "radio" ? (
        <div className="kb-choice-radio-container">
          {optionViews.map((option, index) => (
            <div className="kb-choice-radio-div" key={option.id}>
              <input
                className="kb-choice-radio-input"
                type="radio"
                id={option.id}
                name={option.text}
                value={option.id}
                checked={option.id === selectedChoice}
                onChange={(e) => radioChangeHandler(e)}
              />
              <label
                className={`kb-choice-radio-label ${
                  option.id === selectedChoice ? "kb-choice-custom-bold" : null
                }`}
                htmlFor={option.text}
              >
                {option.text}
              </label>
              <p
                className={`kb-choice-radio-price-text ${
                  option.id === selectedChoice ? "kb-choice-custom-bold" : null
                }`}
              >
                {elements[index]}
              </p>
            </div>
          ))}
        </div>
      ) 
      : (
        <div className="kb-choice-radio-container">
          {optionViews.map((option, index) => (
            <div className="kb-choice-radio-div" key={option.id}>
              <input
                className="kb-choice-radio-input"
                type="checkbox"
                id={option.id}
                name={option.text}
                value={option.id}
                checked={selectedChoice.includes(option.id)}
                onChange={(e) => checkChangeHandler(e)}
              />
              <label
                className={`kb-choice-radio-label ${
                  selectedChoice.includes(option.id)
                    ? "kb-choice-custom-bold"
                    : null
                }`}
                htmlFor={option.text}
              >
                {option.text}
              </label>
              <p
                className={`kb-choice-radio-price-text ${
                  selectedChoice.includes(option.id)
                    ? "kb-choice-custom-bold"
                    : null
                }`}
              >
                {elements[index]}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default KbChoiceField;
