import React from "react";
import { string, object, func, bool } from "prop-types";
import { Button } from "reactstrap";
import { withTheme } from "../contexts/AppContext";
import { Digital } from "react-activity";

const CustomBtn = ({
  value,
  customeClass,
  customeStyle,
  onClick,
  loading,
  block,
  icon,
  size,
  disabled,
  context,
  textColor,
}) => {
  return (
    <Button
      disabled={disabled || loading}
      size={size}
      className={`${customeClass} ${disabled ? "" : "shadow"}`}
      style={{
        backgroundColor: context.themeColor,
        border: "none",
        color: textColor ? textColor : "white",
        ...customeStyle,
      }}
      block={block}
      onClick={() => onClick()}
    >
      {loading ? (
        <Digital color={"white"} size={20} />
      ) : (
        <>
          {value}
          {icon}
        </>
      )}
    </Button>
  );
};

CustomBtn.propTypes = {
  value: string,
  customeClass: string,
  customeStyle: object,
  onClick: func,
  loading: bool,
  color: string,
  block: bool,
  size: string,
  icon: func,
  textColor: string,
};

const customeBtn = withTheme(CustomBtn);
export default customeBtn;
