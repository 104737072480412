import React, { Component } from "react";
import { withTheme } from "../contexts/AppContext";

import "../css/order_style.css";
import RazorpayCheckout_Controller from "../controllers/cartMiniComponents/RazorpayCheckout.controller";
import RazorpayCheckoutViews from "../views/cartMiniComponentsViews/RazorpayCheckoutViews";

class RazorpayCheckout extends Component {
	constructor(props) {
		super(props);

		this.isEmail = RazorpayCheckout_Controller.isEmail.bind(this);
		this.requestOrder = RazorpayCheckout_Controller.requestOrder.bind(this);
		this.onLoad = RazorpayCheckout_Controller.onLoad.bind(this);
		this.updateUser = RazorpayCheckout_Controller.updateUser.bind(this);
		this.verifyAndCaptureCharge = RazorpayCheckout_Controller.verifyAndCaptureCharge.bind(this);
		this.chargeAndConfirm = RazorpayCheckout_Controller.chargeAndConfirm.bind(this);
		this.chargeAndCheckout = RazorpayCheckout_Controller.chargeAndCheckout.bind(this);
		this.handleResponse = RazorpayCheckout_Controller.handleResponse.bind(this);
		this.onClick = RazorpayCheckout_Controller.onClick.bind(this);
		
		this.state = {
			rzp: null,
			phone:
				this.props.context && this.props.context.isUserDone
					? this.props.context.user.phone
					: null,
			name:
				this.props.context && this.props.context.isUserDone
					? this.props.context.user.first_name
					: "",
			email:
				this.props.context &&
				this.props.context.isUserDone &&
				this.props.context.user.email
					? this.props.context.user.email
					: "",
			password: "",
			confirmPassword: "",
			validation: false,
			processing : false,
			orderID : null,
		};
	}

	componentDidMount() {
		if (this.props.context && this.props.context.orderID) {
			this.props.context.getOrder();
		}
	}

	render() {
		return (
			<RazorpayCheckoutViews 
				parent={this}
			/>
		);
	}
}

const AppRPCheckout = withTheme(RazorpayCheckout);
export default AppRPCheckout;
