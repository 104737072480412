import React from 'react';
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Label
} from 'reactstrap'
import { withTheme } from '../contexts/AppContext';

export default withTheme(({
    addon,
    placeholder,
    min,
    max,
    type,
    step,
    labelText,
    context,
    onChange,
    name,
    addontype, 
    ig,
    value
}) => {
    return (
        <>
            <Label style={{ color: context.themeColor, fontSize : "13px" }} >{labelText}</Label>
            <InputGroup className='mb-3'>
                {ig ? <InputGroupText addonType={addontype ? addontype : "prepend"}>{ig}</InputGroupText> : null}
                <Input
                    placeholder={placeholder}
                    min={0} max={100}
                    type={type}
                    step="1"
                    onChange={onChange}
                    value={value}
                />
                {/* <InputGroupAddon addonType="append">.00</InputGroupAddon> */}
            </InputGroup>
        </>
    )
})
