
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Cookies from "js-cookie";
import "./index.css";

//import 'bootstrap/dist/css/bootstrap-theme.css';
window.kumbaURL = process.env.REACT_APP_BACKEND_URL;
window.rupeeSym =
    process.env.REACT_APP_COUNTRY === 'IN'
        ? '\u20B9' + ' '
        : process.env.REACT_APP_COUNTRY === 'UK'
        ? '\u00A3' + ' '
        : process.env.REACT_APP_COUNTRY === 'US'
        ? '$ '
        : '\u20B9' + ' ';

window.phoneCode = process.env.REACT_APP_PHONE_CODE || '+91';
window.googleCountriesList =
    process.env.REACT_APP_COUNTRY === 'IN'
        ? ['in']
        : process.env.REACT_APP_COUNTRY === 'UK'
        ? ['gb', 'be', 'ro']
        : process.env.REACT_APP_COUNTRY === 'US'
        ? ['us']
        : ['in'];
//process.env.REACT_APP_COUNTRY === "IN" ? "\u20B9" : "$";
window.customerFees = parseFloat(
  process.env.REACT_APP_CUSTOMER_CONVENIENCE_FEE
);
//"https://api.foodbots.net";
//"https://api.foodbots.net";
//window.kumbaURL = process.env.BACKEND_URL;

window.setCookie = (name, value, minutes=null) => {
  //var days = new Date(new Date().getTime() + time * 60 * 1000);
  var domain = window.location.hostname;
//   minutes = minutes || 
  var days = minutes ? minutes / (60 * 24) : 0.5;
  // console.log(domain);
  Cookies.set(name, value, {
    domain: domain,
    expires: days,
  });
  // console.log(`cookie of ${name} is ${Cookies.get(name)}`);
};

window.removeAllCookies = (_) => {
  var domain = window.location.hostname;
  Object.keys(Cookies.get()).forEach((cookieName) => {
    var neededAttributes = {
      domain: domain,
      // Here you pass the same attributes that were used when the cookie was created
      // and are required when removing the cookie
    };
    Cookies.remove(cookieName, neededAttributes);
  });
};

window.removeCookie = (name) => {
  var domain = window.location.hostname;
  Cookies.remove(name, { domain: domain });
};


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
