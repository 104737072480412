// file: Registration.jsx

import React, { Component } from 'react';
import {Row, Col, Button, Label, InputGroup, InputGroupAddon, Input, Navbar, NavbarBrand, Modal, Card, CardBody, CardFooter} from 'reactstrap';

import { AppContext, withTheme } from '../contexts/AppContext';
import PackageCart from './PackageCart';

import Login from "../signUp/Login";

import Guest_Controller from "../controllers/guests/Guests.controller";

var styles = {
    Navbarstyle: {
		backgroundColor : "white",
        fontFamily:"Lato",
		boxShadow:"0px 1px 5px darkgrey",
		width : "100%",
		//position : "fixed",
		zIndex : "100",
		//left : 0,
		left : "auto",
		top : 0,
		height : "60px",
    },
    NavbarBrandstyle: {
        fontSize:"15px",
		color:"#5a5050",
		textAlign : "center",
		width : "100%",
		fontFamily:"Lato",
    },
    SummaryButtonStyle:{
        backgroundColor:"#f15b4d",
        marginTop:"20px",
        fontFamily:"Lato",
        display:"block",
        marginRight:"auto",
        marginLeft:"auto",
        border:"none",
        fontSize:"15px",
		width:"130px",
		height : "130px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        borderRadius: "6px",
		padding: "10px 16px",
		
	},
	AddGuestButtonStyle:{
        backgroundColor:"#f15b4d",
        fontFamily:"Lato",
        display:"block",
        border:"none",
        fontSize:"15px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        borderRadius: "6px",
		width : "100%",
		height : "40px"
	},
	CancelButtonstyle:{
        backgroundColor:"#f15b4d",
        fontFamily:"Lato",
        display:"block",
        border:"none",
        fontSize:"15px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        //borderRadius: "6px",
		width : "50%",
		height : "40px",
		position : "fixed",
		zIndex : "120",
		right : 0,
		bottom : 0,
	},
	MenuButtonstyle:{
        backgroundColor:"#6abacd",
        fontFamily:"Lato",
        display:"block",
        border:"none",
        fontSize:"15px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        //borderRadius: "6px",
		width : "30%",
		height : "40px",
		position : "fixed",
		zIndex : "120",
		bottom : "20px",
		right : "33%",
		borderRadius : "20px", 
		margin:"5px", 
		//marginRight : "5px", 
		
	},
	Buttongreenstyle:{
        backgroundColor:"#6abacd",
        marginTop:"20px",
        fontFamily:"Lato",
        display:"block",
        marginRight:"auto",
        marginLeft:"auto",
        border:"none",
        fontSize:"15px",
        width:"130px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        borderRadius: "6px",
        padding: "10px 16px",
    },
    CardStyle: {
        marginTop:"5px",
        boxShadow:"1px 1px 5px darkgrey"
	},
	CardHeaderStyle : {
		backgroundColor : "#6abacd",
		color : "#ffffff"
	},
    CardTitleStyle: {
        fontFamily:"Lato",
        fontSize:"15px",
        color:"#6abacd",
		paddingBottom:"10px",
        borderBottom:"1px solid",
        borderBottomColor:"#b9a6a6"
    },
    labelstyle:{
        display:"block",
        fontFamily:"Lato",
		fontSize:"15px",
	},
	labellightstyle:{
        display:"block",
        fontFamily:"Lato",
		fontSize:"15px",
		color : "#ffffff"
	},
	listItemStyle : {
		border : 0,
		maxHeight : "60px",
		fontFamily:"Lato",
		fontSize:"15px",
		backgroundColor : "white",
		margin : 0,
		padding : "5px",
		//borderBottom:"1px solid",
        //borderBottomColor:"#b9a6a6"
	}
};


class Guests extends Component {
	constructor(props) {
		super(props);

		this.addGuests = Guest_Controller.addGuests.bind(this);
		this.handleSelectChange = Guest_Controller.handleSelectChange.bind(this);
		this.onChange = Guest_Controller.onChange.bind(this);
		this.onChangePhone = Guest_Controller.onChangePhone.bind(this);
		this.openAddModal = Guest_Controller.openAddModal.bind(this);
		this.closeAddModal = Guest_Controller.closeAddModal.bind(this);
		this.openAddCheckinModal = Guest_Controller.openAddCheckinModal.bind(this);
		this.closeAddCheckinModal = Guest_Controller.closeAddCheckinModal.bind(this);
		this.incCount = Guest_Controller.incCount.bind(this);
		this.decCount = Guest_Controller.decCount.bind(this);

		var startDate = new Date();
		startDate.setHours(0,0,0,0);
    	this.state = {
			startDate : startDate,
			guests : null,
			name : this.props.context && this.props.context.isUserDone ? this.props.context.user.name : "",
			phone : this.props.context && this.props.context.isUserDone ? this.props.context.user.phone : "",
			num_male : 0,
			num_female : 0,
			num_other : 0,
			add_date : startDate,
			search : "",
			loginForm : this.props.context.isUserDone ? false : true
		};
	}

	componentDidMount() {
		//this.getGuests();
	}

	render() {		

		var guestSignupForm = 
		<div>
			<Row>
				<Label style={{...styles.labelstyle, fontSize : "20px", textAlign : "center"}}><u>{"Number of Guests"}</u></Label>
			</Row>
			<Row>
				<Col md={{size :6}} xs={12}>
					<InputGroup size="sm">
						<Label style={{...styles.labelstyle, marginRight : "15px"}}>{"Male"}</Label>
						<InputGroupAddon addonType="prepend"> 
							<Button size="xs" style={{backgroundColor:"#f15b4d",border:"none"}} onClick={() => this.decCount("num_male")}>
								-
							</Button>
						</InputGroupAddon>
						<Input style={{textAlign : "center"}} value={this.state.num_male} />
						<InputGroupAddon addonType="append">
							<Button size="xs" style={{backgroundColor:"#6abacd",border:"none"}} onClick={() => this.incCount("num_male")}>
								+
							</Button> 
						</InputGroupAddon>
					</InputGroup>
				</Col>
				<Col md={{size :6}} xs={12}>
					<InputGroup size="sm">
						<Label style={{...styles.labelstyle, marginRight : "15px"}}>{"Female"}</Label>
						<InputGroupAddon addonType="prepend"> 
							<Button size="xs" style={{backgroundColor:"#f15b4d",border:"none"}} onClick={() => this.decCount("num_female")}>
								-
							</Button>
						</InputGroupAddon>
						<Input style={{textAlign : "center"}} value={this.state.num_female} />
						<InputGroupAddon addonType="append">
							<Button size="xs" style={{backgroundColor:"#6abacd",border:"none"}} onClick={() => this.incCount("num_female")}>
								+
							</Button> 
						</InputGroupAddon>
					</InputGroup>
				</Col>
			</Row>
			<PackageCart addnew={true} addGuests={this.addGuests.bind(this)} closeDialog={this.closeAddModal.bind(this)}/> 
			<Row>
				<Col xs={{size : 6, offset : 3}}>
					
				</Col>
			</Row>
		</div>

		return(
			<div className="container-fluid">
				<Modal
					isOpen={this.state.loginForm}
					toggle={() => this.setState({ loginForm: !this.state.loginForm })}
				>
					<Login closeModal={() => this.setState({ loginForm: false })} />
				</Modal>
				<Navbar style={{ ...styles.Navbarstyle, backgroundColor: this.props.context.backgroundColor }} fixed="top">
					<NavbarBrand
					style={{ ...styles.NavbarBrandstyle, textAlign: "center" }}
					>
					<b>Packages</b>
					</NavbarBrand>
				</Navbar>
				{this.props.context && this.props.context.isUserDone ? 
					guestSignupForm : 
					<Card>
						<CardBody>
							<label style={styles.labelstyle}>
								Please sign in to continue
							</label>
						</CardBody>
						<CardFooter>
							<Row>
								<Col xs={{size : 6, offset : 3}}>
									<Button style={styles.AddGuestButtonStyle} onClick={()=>this.setState({loginForm : true})}>Sign In</Button>
								</Col>
							</Row>
						</CardFooter>
					</Card>
				}
				
			</div>
		)
	}
}

const AppGuests = withTheme(Guests);
export default AppGuests;