import React, { useState, useEffect } from "react";
import TotalsRow from '../../../components/TotalsRow';

const KbOrderTotal = ({ orderdata, context }) => {
  const [loading, setLoading] = useState(false);
  const [newConfirmedTotal, setConfirmedTotal] = useState(0.0);
  const [newConfirmedCGST, setConfirmedCGST] = useState(0.0);
  const [newConfirmedSGST, setConfirmedSGST] = useState(0.0);
  const [newConfirmedTax, setConfirmedTax] = useState(0.0);
  const [newConfirmedTaxes, setConfirmedTaxes] = useState(0.0);
  const [newConfirmedServiceFee, setConfirmedServiceFee] = useState(0.0);
  const [newConfirmedVAT, setConfirmedVAT] = useState(0.0);
  const [newConfirmedFees, setConfirmedFees] = useState(0.0);
  const [newConfirmedGrandTotal, setConfirmedGrandTotal] = useState(0.0);
  const [newConfirmedPayTotal, setConfirmedPayTotal] = useState(0.0);
  const [newConfirmedCount, setConfirmedCount] = useState(0.0);
  const [newConfirmedCreditsUsed, setConfirmedCreditsUsed] = useState(0.0);
  const [newDiscount, setDiscount] = useState(0.0);
  const [newOrderSorted, setOrderSorted] = useState({});
  const [subrestdata, setSubrestdata] = useState(null);
  const [mainrestdata, setMainrestdata] = useState(null);

//   const updateOrderPerUser = () => {
//     if (orderdata) {
//       var confirmedTotal = orderdata.order_subtotal;
//       let discount = orderdata.discount;
//       let confirmedTaxDetails = orderdata.tax_info
//         ? JSON.parse(orderdata.tax_info)
//         : null;
//       var confirmedTax = 0.0;
//       var confirmedTaxes = 0.0;
//       var confirmedCGST = orderdata.cgstAmount;
//       var confirmedSGST = orderdata.sgstAmount;
//       var confirmedServiceFee = orderdata.serviceAmount;
//       var confirmedVAT = orderdata.vatAmount;
//       var confirmedFees = orderdata.kumbaAmount;
//       var confirmedGrandTotal = 0.0;
//       var confirmedCount = 0;
//       var confirmedPayTotal = 0.0;
//       var confirmedCreditsUsed = 0.0;
//       let orderSorted = {};

//       if (orderdata && orderdata.orders && orderdata.orders.length > 0) {
//         orderdata.orders.forEach((order) => {
//           if (!orderSorted[order.user]) {
//             orderSorted[order.user] = [];
//           }
//           order.orderTotal = order.item_total * order.quantity;
//           confirmedCount += order.quantity;

//           order.subtitle = "";
//           if (order.options && order.options.length > 0) {
//             console.log(order.rest_id);
//             let allRests = subrestdata
//               ? subrestdata.concat([mainrestdata])
//               : [mainrestdata];
//             let selectedRest = allRests.filter(
//               (rest) =>
//                 rest.restdata && rest.restdata.restaurant_id === order.rest_id
//             );
//             let menuTable = selectedRest[0].menuTable;
//             order.options.forEach((option) => {
//               let choiceitem = option.choiceitem
//                 ? menuTable[option.choiceitem]
//                 : null;
//               order.subtitle += choiceitem
//                 ? choiceitem.name + ","
//                 : option.name + ",";
//             });
//             order.subtitle = order.subtitle
//               .trim()
//               .replace(/.$/, ".")
//               .substr(0, 80);
//           }
//           orderSorted[order.user].push(order);
//         });

//         console.log(confirmedTaxDetails);

//         if (
//           confirmedTaxDetails &&
//           Object.keys(confirmedTaxDetails).length > 0
//         ) {
//           for (let key in confirmedTaxDetails) {
//             confirmedTax += confirmedTaxDetails[key].actual_tax;
//           }
//           console.log(confirmedTax);
//         } else {
//           confirmedTax = 0;
//           //confirmedCGST + confirmedSGST + confirmedVAT + confirmedFees + confirmedServiceFee;
//         }

//         confirmedGrandTotal =
//           Math.round((confirmedTotal + confirmedTax) * 100) / 100;

//         if (orderdata.order_credits) {
//           if (orderdata.order_credits > confirmedGrandTotal - discount) {
//             confirmedPayTotal = 0.0;
//             confirmedCreditsUsed = confirmedGrandTotal - discount;
//           } else {
//             confirmedPayTotal =
//               Math.round(
//                 (confirmedGrandTotal - discount - orderdata.order_credits) * 100
//               ) / 100;
//             confirmedCreditsUsed = orderdata.order_credits;
//           }
//         } else {
//           confirmedPayTotal = confirmedGrandTotal - discount;
//         }

//         setOrderSorted(orderSorted);
//         setConfirmedTotal(confirmedTotal);
//         setConfirmedCGST(confirmedCGST);
//         setConfirmedSGST(confirmedSGST);
//         setConfirmedTax(confirmedTax);
//         setConfirmedTaxes(confirmedTaxes);
//         setConfirmedServiceFee(confirmedServiceFee);
//         setConfirmedVAT(confirmedVAT);
//         setConfirmedFees(confirmedFees);
//         setConfirmedGrandTotal(confirmedGrandTotal);
//         setConfirmedPayTotal(confirmedPayTotal);
//         setConfirmedCount(confirmedCount);
//         setConfirmedCreditsUsed(confirmedCreditsUsed);
//         setDiscount(discount);
//         setLoading(false);
//       } else {
//         setOrderSorted(null);
//         setLoading(false);
//       }
//     } else {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     updateOrderPerUser();
//   }, [orderdata]);

  if (orderdata && context.restdata) {
    let confirmedTaxDetails = orderdata.tax_info
      ? JSON.parse(orderdata.tax_info)
      : null;
    return (
      <>
        {orderdata.order_subtotal && orderdata.order_subtotal > 0 && (
            <TotalsRow title={"Subtotal"} amount={window.rupeeSym + orderdata.order_subtotal.toFixed(2)} />
        //   <div
        //     className="kb-receipt-dinein-delivery"
        //     style={{ fontWeight: "500" }}
        //   >
        //     <p className="kb-receipt-dinein-subtotal-left"></p>
        //     <p className="kb-receipt-dinein-subtotal-right">
        //       {window.rupeeSym + orderdata.order_subtotal.toFixed(2)}
        //     </p>
        //   </div>
        )}

        {orderdata.tax_total > 0 &&
          confirmedTaxDetails &&
          Object.keys(confirmedTaxDetails).map((taxname) => {
            let taxdata = confirmedTaxDetails[taxname];
            return (
                <TotalsRow title={taxname} amount={window.rupeeSym +
                    ((Math.round(taxdata.actual_tax * 100) / 100).toFixed(2) ||
                      0.0)} />
            //   <div
            //     className="kb-receipt-dinein-delivery"
            //     style={{ fontWeight: "500" }}
            //   >
            //     <p className="kb-receipt-dinein-subtotal-left">{taxname}</p>
            //     <p className="kb-receipt-dinein-subtotal-right">
            //       {window.rupeeSym +
            //         ((Math.round(taxdata.actual_tax * 100) / 100).toFixed(2) ||
            //           0.0)}
            //     </p>
            //   </div>
            );
          })}

        {/* {newOrderCredits > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: this.props.context.textColor,
            }}
          >
            <b>{"Available Credits"} :</b>
            <b>{window.rupeeSym + state.orderCredits.toFixed(2)}</b>
          </div>
        ) : null} */}

        {orderdata.discount && orderdata.discount > 0 ? (
            <TotalsRow title={"Discount"} amount={"- " + window.rupeeSym + orderdata.discount.toFixed(2)} />
        //   <div
        //     className="kb-receipt-dinein-delivery"
        //     style={{ fontWeight: "500" }}
        //   >
        //     <p className="kb-receipt-dinein-subtotal-left">{"Discount"}</p>
        //     <p className="kb-receipt-dinein-subtotal-right">
        //       {"- " + window.rupeeSym + orderdata.discount.toFixed(2)}
        //     </p>
        //   </div>
        ) : null}

        {orderdata.delivery_charge && orderdata.delivery_charge > 0 ? (
            <TotalsRow title={"Delivery Charge"} amount={window.rupeeSym + orderdata.delivery_charge.toFixed(2)} />
        //   <div
        //     className="kb-receipt-dinein-delivery"
        //     style={{ fontWeight: "500" }}
        //   >
        //     <p className="kb-receipt-dinein-subtotal-left">{"Delivery Charge"}</p>
        //     <p className="kb-receipt-dinein-subtotal-right">
        //       {window.rupeeSym + orderdata.delivery_charge.toFixed(2)}
        //     </p>
        //   </div>
        ) : null}

        {orderdata.order_bill && orderdata.order_bill > 0 ? (
            <TotalsRow title={"Total"} amount={window.rupeeSym + orderdata.order_bill.toFixed(2)} />
        //   <div
        //     className="kb-receipt-dinein-delivery"
        //     style={{ fontWeight: "500" }}
        //   >
        //     <b className="kb-receipt-dinein-subtotal-left">{"Total"}</b>
        //     <b className="kb-receipt-dinein-subtotal-right">
        //       {window.rupeeSym + orderdata.order_bill.toFixed(2)}
        //     </b>
        //   </div>
        ) : null}
      </>
    );
  } else {
    return null;
  }
};

export default KbOrderTotal;
