import React, {useState, useEffect} from 'react';
import CustomBtn from "../../components/CustomBtn";
import { Card, Input, Button, InputGroup, InputGroupAddon } from "reactstrap";
import RestSuggestions from '../../v2/views/RestaurantCheckoutPage/RestSuggestions/RestSuggestions';

const PayTmCheckoutView = ({
    parent
}) => {

    let [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(false);

    let [online_payments, setOnlinePayments] = useState(false);
    let [offline_payments, setOfflinePayments] = useState(false);

    const { context } = parent.props;
    const state = parent.state;

    useEffect(() => {
        setRefresh(!refresh);
        setOnlinePayments(context.restdata.online_payments);
        setOfflinePayments(context.restdata.offline_payments);
        setLoading(!state.scriptLoaded);
    }, [state.scriptLoaded, parent.props, context.restdata]);

    const payOnlineClick = () => {
        setLoading(true);
        parent.onClick();
    }

    const payRestClick = () => {
        setLoading(true);
        parent.props.payAtRestaurant();
    }

    const restRecommendedItems = context.restdata.menu_items.filter(x => x.isRecommended)

    // RES SUGGESTIONS 👇🏽
    if(context && restRecommendedItems.length > 0 && !skip) {
        return (
            <div>
                <RestSuggestions />
                <CustomBtn value="Skip" customeClass="mt-5 mb-4 w-50" onClick={() => setSkip(true)} />
            </div>
        );
    }
    
    return (
            <div id="checkout-modal"
                body
            >
                <p style={{color : window.textColor}}>Are you sure you want to request the bill ?</p>
                {/* <label
                    className='kb-razor-label'
                    style={{ color: window.themeColor, textAlign: 'center' }}
                >
                    {online_payments ? "Please confirm your email for online payment receipt" : "Please confirm your email for online receipt"}
                </label>
                <br />
                <hr />
                <InputGroup size="sm">
                    <InputGroupAddon addonType="prepend">
                        <Button size="xs" style={{ border: "none" }}>
                            {"Email"}
                        </Button>
                    </InputGroupAddon>
                    <Input
                        type="email"
                        valid={parent.isEmail()}
                        invalid={!parent.isEmail()}
                        style={{ textAlign: "center" }}
                        value={parent.state.email}
                        onChange={e => parent.setState({ email: e.target.value })}
                        disabled={loading}
                    />
                </InputGroup>
                <br />
                
                <br />
                {online_payments ? (
                    <CustomBtn
                        customeClass="kb-btn"
                        disabled={!parent.isEmail() || loading}
                        customeStyle={{ borderRadius: 5, width: '100%' }}
                        onClick={() => payOnlineClick()}
                        value={"Pay Online"}
                        loading={loading}
                    />
                ) : null} */}
                {
                    context && context.selectedMenuType === 'takeaway' ||
                        context.selectedMenuType === 'delivery' ? null :
                        offline_payments ? 
                            <CustomBtn
                                customeClass="kb-btn mt-2"
                                disabled={loading}
                                customeStyle={{ borderRadius: 5, width: '100%' }}
                                onClick={() => payRestClick()}
                                value={"Request Bill"}
                                loading={loading}
                            /> : null
                }
                <CustomBtn
                    customeClass="kb-btn-2 mt-2"
                    customeStyle={{ borderRadius: 5, width: '100%' }}
                    onClick={parent.props.closeDialog}
                    value={"Cancel"}
                />
            </div>
    )
}

export default PayTmCheckoutView;
