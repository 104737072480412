import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { string, func, bool } from 'prop-types';
import { withTheme } from '../contexts/AppContext';

const TextInput = withTheme(({
    labelText,
    type,
    placeholder,
    onChange,
    id,
    name,
    autoFocus,
    disabled,
    className,
    context, 
    value
}) => {
    return (
        <FormGroup>
            <Label style={{ color: context.themeColor, fontSize: '13px' }} for={id}>{labelText}</Label>
            <Input
                type={type}
                name={name}
                id={id}
                autoFocus={autoFocus}
                placeholder={placeholder}
                onChange={e => onChange(e)}
                disabled={disabled}
                className={className}
                value={value}
                style={{ borderColor: window.themeColor, color:context.themeColor, backgroundColor: 'white'}}
            />
        </FormGroup>
    )
});

TextInput.propType = {
    labelText: string,
    type: string,
    placeholder: string,
    onChange: func,
    id: string,
    name: string,
    disabled: bool,
    className: string,
    value: string
}

export default TextInput;
