import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Navbar,
  Modal
} from "reactstrap";
import { AppContext, withTheme } from "../contexts/AppContext";

import sidebar_curve from "../img/sidebar_curve.png";
import { Link } from "react-router-dom";
import Login from "../signUp/Login";
import { Redirect } from "react-router";
import loadingpng from "../img/loading.png";
import SidemenuView from "../views/mainPageViews/SidemenuView";
import AppLoading from "../components/AppLoading";

var styles = {
  Buttonstyle: {
    // backgroundColor: "#7ac1d1 ",
    marginTop: "20px",
    fontFamily: "Lato",
    display: "block",
    marginRight: "auto",
    marginLeft: "auto",
    border: "none",
    fontSize: "15px",
    width: "130px",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
    borderRadius: "54.5px",
    backgroundImage: "linear-gradient(180deg,#7ac1d1  0,#7ac1d1 )"
  },
  labelStyle: {
    fontFamily: "Lato",
    fontSize: "20px",
    // color: "#636466"
  },
  nameStyle: {
    display: "block",
    fontFamily: "Lato",
    fontSize: "24px",
    color: "#ffffff",
    textAlign: "center"
  },
  Navbarstyle: {
    // backgroundColor: "white",
    textAlign: "center",
    fontFamily: "Lato",
    boxShadow: "0px 1px 5px darkgrey",
    width: "100%",
    position: "fixed",
    zIndex: "100",
    left: 0,
    bottom: 0
  }
};

class Sidemenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "None",
      loginForm: false
    };
  }

  render() {
    if (this.state.loginForm) {
      return <Redirect push to={{ pathname: "/login" }} />;
    } else {
      switch (this.state.redirect) {
        case "Scan":
          return <Redirect push to={{ pathname: "/scan" }} />;
        case "Payments":
          return <Redirect push to={{ pathname: "/user/managepay" }} />;
        case "Previous":
          return <Redirect push to={{ pathname: "/user/previous" }} />;
        case "Tutorial":
          return <Redirect push to={{ pathname: "/user/rules" }} />;
        default:
          if (this.props.context) {
            const backRow = (
              <Row>
                <Col md={2} xs={2}>
                  <img src={sidebar_curve} style={{ width: "2%" }} />
                </Col>
                <Col md={10} xs={10}>
                  <label style={{ ...styles.labelStyle, color: this.props.context.textColor }}>Back to Menu</label>
                </Col>
              </Row>
            );
            return (
              <SidemenuView
                parent={this}
                styles={styles}
                backRow={backRow}
              />
            );
          } else {
            return (
              <AppLoading />
            );
          }
      }
    }
  }
}

const AppSidemenu = withTheme(Sidemenu);

export default AppSidemenu;
