import React from "react";
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  FormFeedback
} from "reactstrap";

export const FieldGroup = function({
  id,
  label,
  help,
  ig,
  validationState,
  parent,
  ...props
}) {
  return (
    <FormGroup controlId={id} validationState={validationState || true}>
      <Label style={{ color: parent.props.context.textColor }}>{label}</Label>
      {ig ? (
        <InputGroup>
          <InputGroupAddon>{ig}</InputGroupAddon>
          <Input
            {...props}
            valid={validationState}
            invalid={!validationState}
          />
        </InputGroup>
      ) : (
        <Input {...props} valid={validationState} invalid={!validationState} />
      )}
      {help && <FormFeedback>{help}</FormFeedback>}
    </FormGroup>
  );
};
