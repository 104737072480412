import React, { Component } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  ButtonGroup,
  Row,
  Col,
  Label,
  Button
} from "reactstrap";

import cogoToast from 'cogo-toast';
import {Redirect} from 'react-router-dom';

import { AppContext, withTheme } from "../contexts/AppContext";

import ProcessOrder_Controller from "../controllers/signUp/processOrder.controller";

var styles = {
    labelstyle: {
        display: "block",
        fontFamily: "Lato",
        fontSize: "15px",
        color: "#544f4f"
    },
    Buttonstyle: {
      fontFamily: "Lato",
      backgroundColor: "#f15b4d",
      display: "block",
      border: "none",
      fontSize: "15px",
      boxShadow:
        "rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
      borderRadius: "6px",
      //width : "50%",
      height: "40px",
      width : "250px",
      textAlign : "center",
      padding : "2px"
    },
    CardStyle: {
        marginTop: "20px",
        boxShadow: "1px 1px 5px darkgrey",
        marginLeft: "2em",
        marginRight: "2em"
    },
  };

class ProcessOrder extends Component {
    constructor(props){
        super(props);

        this.changeTable = ProcessOrder_Controller.changeTable.bind(this);
        this.updateTable = ProcessOrder_Controller.updateTable.bind(this);
        this.checkoutOrder = ProcessOrder_Controller.checkoutOrder.bind(this);
        this.addPersonToOrder = ProcessOrder_Controller.addPersonToOrder.bind(this);
        this.redirectUser = ProcessOrder_Controller.redirectUser.bind(this);

        this.state = {
            returnDiv : null,
            goRestaurants : false,
            goCart : false,
            goMain : false,
        };
    }

    async processUserOrder() {
        var jsondata = this.props.context.user;
        console.log("0000 : Processing Order");
        try {
            if (jsondata.pending_orders && jsondata.pending_orders[0] && jsondata.pending_orders[0].order_status !== "cancelled") {
                console.log("0001 : Pending Order Detected");
                var orderdata = await this.props.context.fetchOrder(jsondata.pending_orders[0].order_id);
                var tableid = orderdata.table_id ? orderdata.table_id.id : null;
                var restid = orderdata.rest ? orderdata.rest.restaurant_id : null;
                
                console.log("0002 : Fetched orderdata", orderdata);
                if(this.props.context.tableID && this.props.context.restID) {
                    console.log("0003 : RestaurantID and tableID exists", this.props.context.tabledata, this.props.context.restdata);
                    if(restid === this.props.context.restID) {
                        console.log("0004 : RestaurantID matches for existing and current", this.props.context.restID, orderdata.rest);
                        if(tableid) {
                            if(tableid === this.props.context.tableID) {
                                console.log("0005 : TableID matches for existing and current", this.props.context.tableID, tableid);
                                this.setState({returnDiv : 
                                    <Card>
                                        {"Processing ... Please Wait"}
                                    </Card>
                                });
                                await this.updateTable(orderdata.order_id, tableid, restid, false);
                                console.log("0006 : Update session with existing order");
                                return new Promise((resolve, reject) => {
                                    return resolve(null);
                                });
                            } else {
                                console.log("0007 : TableID doesn't match for existing and current", this.props.context.tableID, tableid);
                                this.setState({returnDiv : 
                                    <Card style={styles.CardStyle}>
                                        <CardBody>
                                        <label style={{ ...styles.labelstyle, textAlign: "center" }}>
                                            {"You have an existing order on the table '" + orderdata.table_id.table_name + "' at " + orderdata.rest.name}
                                        </label>
                                        <label style={{ ...styles.labelstyle, textAlign: "center", fontSize : "12px" }}>
                                            {"Do you want to switch your order to '" + this.props.context.tabledata.table_name + "'?"}
                                        </label>
                                        </CardBody>
                                        <CardFooter style={{ height: "120px" }}>
                                            <Row style={{padding : "5px"}}>
                                                    <Button style={{...styles.Buttonstyle, backgroundColor : "#6abacd"}}  onClick={()=>this.changeTable(orderdata.order_id, tableid, this.props.context.tableID)}>{"Yes, Switch to " + this.props.context.tabledata.table_name}</Button>
                                            </Row>
                                            <Row style={{padding : "5px"}}>
                                                <Button style={styles.Buttonstyle}  onClick={()=>this.updateTable(orderdata.order_id, tableid, restid, false)}>{"No, Sit at " + tableid.table_name}</Button>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                });
                                return new Promise((resolve, reject) => {
                                    return resolve(null);
                                });
                            }
                        } else {
                            console.log("0008 : TableID doesn't exist for order", this.props.context.tableID);
                            this.setState({returnDiv : 
                                <Card>
                                    {"Processing.... Please Wait"}
                                </Card>
                            });
                            await this.updateTable(orderdata.order_id, this.props.context.tableID, restid, false);
                            console.log("0009 : Update session with existing order");
                            return new Promise((resolve, reject) => {
                                return resolve(null);
                            });
                        }
                    } else  {
                        console.log("0010 : RestID doesn't match for existing and current", this.props.context.restID, orderdata.rest);
                        this.setState({returnDiv : 
                            <Card style={styles.CardStyle} body>
                                <CardBody>
                                <label style={{ ...styles.labelstyle, textAlign: "center" }}>
                                    {"You have an existing order at " + orderdata.rest.name} 
                                </label>
                                <label style={{ ...styles.labelstyle, textAlign: "center", fontSize : "12px" }}>
                                    {"Do you want to checkout existing order ?"}
                                </label>
                                </CardBody>
                                <CardFooter style={{ height: "120px", textAlign : "center" }}>
                                    <Row style={{padding : "5px"}}>
                                        <Button style={{...styles.Buttonstyle, backgroundColor : "#6abacd"}} onClick={()=>this.checkoutOrder(orderdata.order_id, 0.00)}>{"Yes, Checkout & Start New Order "}</Button>
                                    </Row>
                                    <Row style={{padding : "5px"}}>
                                        <Button style={styles.Buttonstyle}  onClick={()=>this.updateTable(orderdata.order_id, tableid, restid, true)}>{"No, Show my existing order."}</Button>
                                    </Row>
                                </CardFooter>
                            </Card>
                        });
                        return new Promise((resolve, reject) => {
                            return resolve(null);
                        });
                    }
                } else if (this.props.context.refOrder) {
                    console.log("0011 : No existing tableID or restID. RefOrder", this.props.context.refOrder);
                    this.setState({returnDiv : 
                        <Card body>
                            {"Processing ... Please Wait"}
                        </Card>
                    });
                    await this.addPersonToOrder();
                    return new Promise((resolve, reject) => {
                        return resolve(null);
                    });
                } else {
                    console.log("0012 : No existing tableID or restID. No RefOrder");
                    this.setState({returnDiv : 
                        <Card>
                            {"Processing ... Please Wait"}
                        </Card>
                    });
                    await this.updateTable(orderdata.order_id, tableid, restid, true);
                    return new Promise((resolve, reject) => {
                        return resolve(null);
                    });
                }
            } else {
                console.log("0013 : No pending order");
                this.redirectUser();
                return new Promise((resolve, reject) => {
                    return resolve(null);
                });
            }
        } catch (e) {
            console.log("0014 : Error occured");
            // alert("Failed to process orders. Please try again.");
            this.props.context.popup("Failed to process orders. Please try again.", "error")
            //this.setState({goRestaurants : true});
            return new Promise((resolve, reject) => {
                return reject(e);
            });
        }
    }
    
    componentDidMount() {
        console.log("Process Order Mounted");
        this.redirectUser();
        // if(this.props.context.user) {
        //     this.processUserOrder();
        // }
    }

    render() {
        if(this.state.goRestaurants) {
           return <Redirect to="/restaurants" />;
        } else if(this.state.goMain) {
            return <Redirect to="/main" />;
        } else if(this.state.goCart) {
            return <Redirect to={{pathname : "/cart", state : {cartState : "pending"} }}/>;
        } else {
            return(
                <div>
                    {this.state.returnDiv ? this.state.returnDiv : "Loading"}
                </div>
            )
        }
    }
}

const AppProcessOrder = withTheme(ProcessOrder);
export default AppProcessOrder;