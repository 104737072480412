import React, { useState } from "react";

import KbChoiceFieldView from "./KbChoiceField";

const KbChoiceFieldController = ({
  customization,
  itemdata,
  itemKey,
  submit,
  isHappyHr,
  menuTable,
  parent,
  increasePrice,
  decreasePrice,
  itemPriceRadio,
  custom,
  choices,
  numSelectedChoices,
  updatePrice,
  setItemPriceRadio
}) => {
  const [selectedChoice, setSelectedChoice] = useState("");
  const [enteredText, setEnteredText] = useState("");

  const onChange = (val) => {
    submit(customization.id, val);
  };

  const onSelect = (values) => {
      var selectedOptions = customization.options.reduce((result, option) => {
        let choiceitem = option.choiceitem
          ? menuTable[option.choiceitem]
          : null;
        if (values === option.id || values.indexOf(option.id) !== -1) {
          result.push({
            ...option,
            choicename: choiceitem ? choiceitem.name : null,
          });
        }
        return result;
      }, []);

      submit(customization.id, selectedOptions);
  };

  const onSelectRadio = (values) => {
    if (typeof values === 'string') {
        values = [values];
    } 

            // if (onSelectCondition) {
                var selectedOptions = customization.options.reduce((result, option) => {
                    let choiceitem = option.choiceitem ? menuTable[option.choiceitem] : null;
                    if (values[0] === option.id) {
                        result.push({
                        ...option,
                        choicename: choiceitem ? choiceitem.name : null,
                        });
                    }
                    return result;
                }, []);
                
                submit(customization.id, selectedOptions);
            // }
    
  };


  if (
    customization.options.length > 0 ||
    customization.optionType !== "textarea"
  ) {
    var title =
      customization.maxChoices && customization.maxChoices > 0
        ? customization.description +
        " ( upto " +
        customization.maxChoices +
        " choices )"
        : customization.description;

    var optionViews = [];
    var elements = [];

    if (customization.options && customization.options.length > 0) {
      customization.options
        .sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
        .forEach((option) => {
          // console.log('OPTIONS --', option)
          var happyHrBaseTotal =
            (option.special_price ? option.special_price : option.price) +
            (itemdata.special_price ? itemdata.special_price : itemdata.price);
          let choiceitem = option.choiceitem
            ? menuTable[option.choiceitem]
            : null;

          var origBaseTotal = option.price + itemdata.price;

          var happyHrTotal = option.special_price
            ? option.special_price
            : option.price;

          var origTotal = option.price;
          var element = null;

          // console.log({
          //   happyHrTotal,
          //   origBaseTotal,
          //   happyHrBaseTotal
          // });

          if (customization.isBase) {
            if (isHappyHr) {
              if (happyHrBaseTotal != origBaseTotal) {
                element = (
                  <p>
                    <del>{origBaseTotal === 0 ? null : window.rupeeSym + origBaseTotal.toFixed(2)}</del>{" "}
                    {happyHrBaseTotal === 0 ? null : window.rupeeSym + happyHrBaseTotal.toFixed(2)}
                  </p>
                );
              } else {
                element = (
                  <p>{happyHrBaseTotal === 0 ? null : window.rupeeSym + happyHrBaseTotal.toFixed(2)}</p>
                );
              }
            } else {
              element = <p>{origBaseTotal === 0 ? null : window.rupeeSym + origBaseTotal.toFixed(2)}</p>;
            }
          } else {
            if (isHappyHr) {
              if (happyHrTotal != origTotal) {
                element = (
                  <p>
                    <del>{origTotal === 0 ? null : "+ " + window.rupeeSym + origTotal.toFixed(2)}</del>{" "}
                    {happyHrTotal === 0 ? null : "+ " + window.rupeeSym + happyHrTotal.toFixed(2)}
                  </p>
                );
              } else {
                element = (
                  <p>{happyHrTotal === 0 ? null : "+ " + window.rupeeSym + happyHrTotal.toFixed(2)}</p>
                );
              }
            } else {
              element = <p>{origTotal === 0 ? null : "+ " + window.rupeeSym + origTotal.toFixed(2)}</p>;
            }
          }

          elements.push(element);

          if (customization.optionType === "radio") {
            optionViews.push({
              id: option.id,
              text: choiceitem ? choiceitem.name : option.name,
              price : choiceitem ? choiceitem.price : option.price,
              element,
            });
          } else if (customization.optionType === "checkbox") {
            optionViews.push({
              id: option.id,
              text: choiceitem ? choiceitem.name : option.name,
              price : choiceitem ? choiceitem.price : option.price,
              element,
            });
          }
        });
    }
  }

  // console.log(optionViews);
  // console.log(elements);

  return (
    <KbChoiceFieldView
      onChange={onChange}
      onSelect={onSelect}
      onSelectRadio={onSelectRadio}
      selectedChoice={selectedChoice}
      enteredText={enteredText}
      optionViews={optionViews}
      customization={customization}
      itemdata={itemdata}
      title={title}
      custom={custom}
      parent={parent}
      elements={elements}
      increasePrice={increasePrice}
      decreasePrice={decreasePrice}
      itemPriceRadio={itemPriceRadio}
      numSelectedChoices={numSelectedChoices}
      updatePrice={updatePrice}
      setItemPriceRadio={setItemPriceRadio}
    />
  );
};

export default KbChoiceFieldController;
