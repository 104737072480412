import { Modal, Card, CardBody } from 'reactstrap';
import React from 'react';
import { withTheme } from '../../contexts/AppContext';
import CustomeBtn from '../CustomBtn';


export default withTheme(class Popup extends React.Component {
    state = {
        show: this.props.show,
        icon: null,
        action: null
    }

    componentDidMount() {
        switch (this.props.context.popupType) {
            case 'error':
                this.setState({ icon: 'fa-times' })
                break;
            case 'success':
                this.setState({ icon: 'fa-check' })
                break;
            case 'warning':
                this.setState({ icon: 'fa-exclamation-circle' })
                break;
            default:
                break;
        }
    }

    componentWillReceiveProps(nextProps) {
        switch (this.props.context.popupType) {
            case 'error':
                this.setState({ icon: 'fa-times' })
                break;
            case 'success':
                this.setState({ icon: 'fa-check' })
                break;
            case 'warning':
                this.setState({ icon: 'fa-exclamation-circle' })
                break;
            default:
                break;
        }
    }
    

    render() {
        const { type, text, context } = this.props;
        const { show, icon, action } = this.state;
        return (
            <Modal isOpen={context.showPopup} style={{ paddingTop: '40%'}}>
                <Card className='text-center' style={{ backgroundColor: context.backgroundColor }}>
                    <CardBody>
                        <i style={{ fontSize: '70px', color: context.themeColor }} className={`fa ${icon} mb-2`}></i>
                        <h5 className='mb-4' style={{ fontWeight: 'bold', color: context.textColor }}>{context.popupText}</h5>
                        <CustomeBtn
                            onClick={() => {
                                context.setContextState({
                                    showPopup: !context.showPopup
                                })
                            }}
                            customeClass="w-50"
                            value={'Close'}
                        />
                    </CardBody>
                </Card>
            </Modal>
        )
    }
});

