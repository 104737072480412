import React from 'react';
import veg from '../img/veg.jpg';
import egg from '../img/egg.jpg';
import nonVeg from '../img/non-veg.jpg';
import '../css/Type.css';

const Type =  ({ type, style }) => {
    // console.log('TYPE ---', type);
    switch (type) {
        case 'veg':
            return <img style={style} width={'4%'} height={'4%'} className='mt-1 mr-1' src={veg} alt='' />
        case 'egg':
            return <img style={style} width={'4%'} height={'4%'} className='mt-1 mr-1' src={egg} alt='' />
        case 'nonveg':
            return <img style={style} width={'4%'} height={'4%'} className='mt-1 mr-1' src={nonVeg} alt='' />;
        default:
            return null;
    }
    // return (
    //     <div>
    //         {
    //             type === 'veg' ? <img width={'4%'} height={'4%'} className='mt-1 mr-1' src={veg} alt='' />
    //                 : null
    //         }
    //          {
    //             type === 'egg' ? <img width={'4%'} height={'4%'} className='mt-1 mr-1' src={egg} alt='' />
    //                 : null
    //         }
    //         {
    //             type === 'nonveg' ? <img width={'4%'} height={'4%'} className='mt-1 mr-1' src={nonVeg} alt='' /> : null
    //         }
    //     </div>
    // )
}

export default Type;
