import React, { Component, useState, useEffect } from "react";

import "../css/stripe.css";
import axios from 'axios';
// import { injectStripe, Elements } from "react-stripe-elements";
import Btn from '../components/CustomBtn'

import { withTheme } from "../contexts/AppContext";

// import StripeCheckout_Controller from "../controllers/cartMiniComponents/StripeCheckout.controller";
// import StripeCheckoutView from "../views/cartMiniComponentsViews/StripeCheckoutView";

const StripeCheckout = withTheme(({context}) => {

    let [session, setSession] = useState(null);
    let [loading, setLoading] = useState(false)

    const getCheckoutSession = async () => {
        setLoading(true)
        if (context && context.orderID) {
            var url = window.kumbaURL + '/stripe/createsession';
            let header = { 'foodbots-header': context.token };
            
            try {
                const response = await axios({
                    url,
                    headers: header,
                    method: 'POST',
                    data: {
                        order_id : context.orderID,
                    },
                });
                
                let session = response.data;
                setSession(session);
                window.location = session.url;
                setLoading(false)
                return Promise.resolve(session);
            } catch (err) {
                setLoading(false)
                //alert("Couldn't place the order. Please try again");
                console.log(err);
                return Promise.reject(session);
            }
        }
    }

    // useEffect(() => {
    //     getCheckoutSession();
    // }, [context.orderID]);

    return(<div className="text-center mt-5">
        <img src='https://upload.wikimedia.org/wikipedia/en/thumb/e/eb/Stripe_logo%2C_revised_2016.png/1200px-Stripe_logo%2C_revised_2016.png' alt='stripe' width='300px' /><br />
        {/* <button className="btn btn-primary">Pay With Stripe</button> */}
        <Btn
            value='Pay With Stripe'
            customeClass='btn-primary'
            loading={loading}
            customeStyle={{
                backgroundColor: '#6773e6'
            }}
            onClick={getCheckoutSession}
         />
    </div>);

});

export default StripeCheckout;