import React, { useState, useEffect } from "react";
import "./KbMenuItem.css";
import axios from "axios";
import {
    Modal,
    ModalBody,
    CardImg,
    Card,
    Row,
    Col,
    Button,
    ButtonGroup
} from "reactstrap";

import cogoToast from "cogo-toast";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import {
    formatDescriptionFull,
    formatDescriptionShort
} from "../../../../helpers/HTMLDescripton";
import Type from "../../../../components/Type";
import AppItemCustomization from "./ItemCustomization";
import { withTheme } from "../../../../contexts/AppContext";
import styled from "styled-components";

const Wrapper = styled.div`
    button {
        position: absolute;
        bottom: 0;
        right: 0;
    }
`;

const EachRestSuggestion = ({
    title,
    description,
    img,
    price,
    theme,
    qty,
    inCart,
    id,
    outOfStock,
    isDigitalMenu,
    parent,
    item,
    isOpen,
    allowOrder,
    displayType,
    category,
    context
}) => {
    // console.log("IS RECOMENDED ----", item.isRecommended);
    console.log('EACH CAT --', category)
    const restdata = context.restdata;
    const [selectedSlot, setSelectedSlot] = useState(
        context ? context.selectedDeliverySlot : null
    );
    const [activeSlot, setActiveSlot] = useState(
        context ? context.activeDeliverySlot : null
    );

    const [slotError, setSlotError] = useState(false);
    const [repeatOrder, setRepeatOrder] = useState(false);
    const [removeDisplay, setRemoveDisplay] = useState(false);
    const [chooseOptions, setChooseOptions] = useState(false);
    const [customizations, setCustomizations] = useState(null);
    const [buttonText, setButtonText] = useState("+ ADD");
    const [orderExists, setOrderExists] = useState(false);
    const [existingOrder, setExistingOrder] = useState(null);
    const [itemOrders, setItemOrders] = useState(null);
    const [error, setError] = useState(null);
    const [showImg, setShowImg] = useState(true);

    // console.log('the context', context)
    // console.log('the item', item)

    // context methods
    const findUncomfirmedOrder = () => {
        let order = null;

        if (restdata && restdata.type === "homechef") {
            //console.log("Is homechef");
            const slotInStorage = context.selectedDeliverySlot;
            //console.log(slotInStorage, context.activeDeliverySlot);
            if (
                !slotInStorage ||
                slotInStorage === context.activeDeliverySlot
            ) {
                //console.log("Is equal");
                if (context.unconfirmedOrders) {
                    const order = context.unconfirmedOrders.filter(
                        (eachorder) => eachorder.item_id === id
                    );
                    // console.log({ order, id });
                    //console.log("Orders unconfirmed");
                    if (order && order[0]) {
                        console.log(order[0]);
                        setOrderExists(true);

                        setExistingOrder(order[0]);
                    } else {
                        setOrderExists(false);
                        setExistingOrder(null);
                    }
                } else {
                    //console.log("No Orders unconfirmed");
                    setOrderExists(false);
                    setExistingOrder(null);
                }
            } else {
                //console.log("Is not equal");
                setOrderExists(false);
                setExistingOrder(null);
            }
        } else {
            //console.log("Is not homechef");
            if (context.unconfirmedOrders) {
                const order = context.unconfirmedOrders.filter(
                    (order) => order.item_id === id
                );
                // console.log({ order, id });

                if (order && order[0]) {
                    //console.log(order[0]);
                    setOrderExists(true);
                    setItemOrders(order);
                    setExistingOrder(order[0]);
                }
            } else {
                //console.log("Is not equal");
                setOrderExists(false);
                setExistingOrder(null);
            }
        }
    };

    const optionsClose = () => {
        setChooseOptions(false);
        findUncomfirmedOrder();
        // close modal
    };

    const updateQuantity = async (existingId, quantity, mode) => {
        console.log("recidddddddd");
        let url = window.kumbaURL + "/customization/item/" + id;
        let { data } = await axios({
            url,
            method: "GET"
        });
        if (data && data.length > 0) {
            if (mode === "increment") {
                setRepeatOrder(true);
            } else {
                if (itemOrders.length > 1) {
                    setRemoveDisplay(true);
                } else {
                    if (quantity <= 0) {
                        context.removeOrder(existingId);
                        setOrderExists(false);
                        setExistingOrder(null);
                    } else {
                        context.updateOrder(existingId, quantity);
                    }
                }
            }
        } else {
            if (quantity <= 0) {
                context.removeOrder(existingId);
                setOrderExists(false);
                setExistingOrder(null);
            } else {
                context.updateOrder(existingId, quantity);
            }
        }
        // console.log("updateQuantity");
    };

    const getCustomizations = async () => {
        try {
            let url = window.kumbaURL + "/customization/item/" + id;
            let { data } = await axios({
                url,
                method: "GET"
            });

            if (isDigitalMenu) {
                setButtonText("SHORTLIST");
            } else if (data && data.length > 0) {
                setButtonText("+ ADD");
            } else {
                setButtonText("+ ADD");
            }

            setCustomizations(data);
            return Promise.resolve(data);
        } catch (err) {
            setError(err);
            return Promise.reject(err);
        }
    };

    const submitOrder = () => {
        const price =
            (item.isHappyHr || context.restdata.isHappyHr) && item.special_price
                ? item.special_price
                : item.price;

        const data = {
            item_id: id,
            options: [],
            quantity: 1,
            itemdata: item,
            item_total: price,
            restID: item.menu,
            id: context.unconfirmedOrders.length + 1
        };

        context.addOrders(data);
        findUncomfirmedOrder();
        // console.log("submitOrder");
    };

    const addFromModal = async () => {
        context.setContextState({
            selectedDeliverySlot: null
        });
        await context.clearOrder();
        cogoToast.success("Cleared Your Cart");
        setSlotError(false);
        return Promise.resolve();
    };

    useEffect(() => {
        let isMounted = true;
        if (item && chooseOptions) {
            getCustomizations();
        } else {
            return;
        }

        return () => {
            isMounted = false;
        };
    }, [item, chooseOptions]);

    const [orderChange] = context.unconfirmedOrders;

    useEffect(() => {
        findUncomfirmedOrder();
        console.log("arebwerer  ", context.unconfirmedOrders);
    }, [orderChange, context.selectedDeliverySlot, context.activeDeliverySlot]);

    const decideMenuPopup = async () => {
        let url = window.kumbaURL + "/customization/item/" + id;
        let { data } = await axios({
            url,
            method: "GET"
        });
        // data && data.length > 0
        //     ? setChooseOptions(true)
        //     : setChooseOptions(false);
            setChooseOptions(true);
    };

    const choiceHelper = (choice) => {
        if (choice) {
            context.updateOrder(existingOrder.id, existingOrder.quantity + 1);
            setRepeatOrder(false);
        } else {
            setRepeatOrder(false);
            setChooseOptions(true);
        }
    };

    const menuTypeMatches = () => {
        if (context.selectedMenuType) {
            // alert('there is menu type selected ')
            switch (context.selectedMenuType) {
                case "delivery":
                    console.log(
                        "DELIVERY OPEN---",
                        context.restdata.isDeliveryOpen
                    );
                    return context.restdata.isDeliveryOpen;
                case "takeaway":
                    console.log(
                        "TAKEAWAY OPEN---",
                        context.restdata.isTakeawayOpen
                    );
                    return context.restdata.isTakeawayOpen;
                default:
                    return context.restdata.isOpen;
            }
        } else return context.restdata.isOpen;
    };

    const isHappyHr = restdata.isHappyHr || item.isHappyHr;

    const itemOptions = (
        <AppItemCustomization
            itemdata={item}
            customizations={customizations}
            optionsClose={optionsClose}
            restdata={restdata}
            isHappyHr={isHappyHr}
            menuTable={context.menuTable}
            menuTypeMatches={menuTypeMatches}
            allowOrder={allowOrder}
        />
    );

    let dine_in_check = context
        ? (!context.selectedMenuType ||
              context.selectedMenuType !== "dine-in" ||
              (context.selectedMenuType === "dine-in" && context.tableID)) &&
          !isDigitalMenu
        : false;

    const colCheck = img || outOfStock || dine_in_check;

    const eatarianColor =
        item.eatarian === "veg"
            ? "green"
            : item.eatarian === "nonveg"
            ? "red"
            : null;

    function addsum(arr) {
        var sum = 0;
        for (var z = 0; z < arr.length; z++) {
            sum += arr[z].quantity;
        }
        return sum;
    }

    let presentTotal = itemOrders && addsum(itemOrders);

    const buttonIsDisabled = outOfStock || !allowOrder;

    return (
        <div className="col-9 ">
            <Wrapper
                className="kb-menu-item p-2 card"
                style={{
                    background: window.highlightColor,
                    position: "relative"
                }}
            >
                {/* modal for menu pop-up */}
                <Modal
                    isOpen={chooseOptions}
                    autoFocus={true}
                    className={"m-0 w-100 menu-options-modal"}
                    backdrop={true}
                    style={{
                        backgroundColor: context.backgroundColor
                    }}
                >
                    <ModalBody className="menu-options-modal-body pt-0">
                        <div
                            className="jumbotron"
                            style={ item.image ?{
                                backgroundImage: `url(${item.image})`,
                                backgroundSize: "100% 100%",
                                height: "50vh",
                                borderRadius: "0px 0px 30px 30px",
                                backgroundRepeat: "no-repeat"
                            }:null}
                        ></div>
                        <div
                            className="menu-options-modal-close"
                            onClick={() => setChooseOptions(false)}
                        >
                            <p className="menu-options-modal-close-text">
                                &times;
                            </p>
                        </div>
                        <div className="menu-options-modal-body-container">
                            <h2 className="menu-options-modal-title">
                                {item.name}
                            </h2>
                            {/* <p className="menu-options-modal-subtitle">
                             
                                {item.html_description
                                    ? renderHTML(item.html_description)
                                    : renderHTML(
                                          item.description
                                              ? formatDescriptionFull(
                                                    item.description
                                                )
                                              : ""
                                      )}
                            </p> */}
                            <p className="menu-options-modal-price">
                                {window.rupeeSym + item.price}
                            </p>
                        </div>

                        {itemOptions}
                    </ModalBody>
                </Modal>

                {/* modal for removing items in cart */}
                <Modal
                    isOpen={removeDisplay}
                    autoFocus={true}
                    className={"kb-detail-modal m-0 w-100"}
                    backdrop={true}
                >
                    <div className="fixed-bottom">
                        <div className="card">
                            <div
                                className="menu-options-modal-close"
                                onClick={() => setRemoveDisplay(false)}
                            >
                                <p className="menu-options-modal-close-text">
                                    &times;
                                </p>
                            </div>
                            <div className="card-body text-center">
                                <p>
                                    Remove from any of the previous selected
                                    choices
                                </p>
                                {itemOrders &&
                                    itemOrders.map((Item) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-around"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start"
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontSize: "14px",
                                                        marginTop: "2px",
                                                        marginRight: "10px"
                                                    }}
                                                >
                                                    {Item.quantity}
                                                </p>
                                                <p
                                                    style={{
                                                        marginBottom: "0rem"
                                                    }}
                                                >
                                                    {Item.subtitle}
                                                </p>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginTop: "-7px",
                                                    justifyContent: "flex-end"
                                                }}
                                            >
                                                <button
                                                    className="kb-menu-item-right-btn"
                                                    onClick={() => {
                                                        context.updateOrder(
                                                            Item.id,
                                                            Item.quantity - 1
                                                        );
                                                        setRemoveDisplay(false);
                                                    }}
                                                    style={{
                                                        background:
                                                            window.themeColor
                                                    }}
                                                >
                                                    remove
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* modal for error slot */}
                <Modal
                    isOpen={slotError}
                    autoFocus={true}
                    className={"kb-detail-modal m-0 w-100"}
                    backdrop={true}
                >
                    <div
                        className="fixed-bottom"
                        style={{ marginBottom: "40vh" }}
                    >
                        <div className="card">
                            <div className="card-body text-center">
                                <p>
                                    You can only + ADDs from one delivery slot.
                                    Would you like to clear the cart ?
                                </p>
                                <ButtonGroup className="w-100">
                                    <Button
                                        onClick={addFromModal}
                                        style={{
                                            background: window.themeColor
                                        }}
                                    >
                                        Clear Cart
                                    </Button>
                                    <Button
                                        onClick={() => setSlotError(false)}
                                        style={{
                                            color: window.themeColor,
                                            borderColor: window.themeColor,
                                            background: "inherit"
                                        }}
                                    >
                                        NO
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/* modal to decide if to retain order */}
                <Modal
                    isOpen={repeatOrder}
                    autoFocus={true}
                    className={"kb-detail-modal m-0 w-100"}
                    backdrop={true}
                >
                    <div className="fixed-bottom">
                        <div className="card">
                            <div
                                className="menu-options-modal-close"
                                onClick={() => setRepeatOrder(false)}
                            >
                                <p className="menu-options-modal-close-text">
                                    &times;
                                </p>
                            </div>
                            <div className="card-body text-center">
                                <p>Repeat previous choice ?</p>
                                <ButtonGroup className="w-100">
                                    {itemOrders && itemOrders.length > 1 ? (
                                        itemOrders.map((Item) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent:
                                                        "space-around"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-around"
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                            margin: "0rem"
                                                        }}
                                                    >
                                                        {Item.quantity}
                                                    </p>
                                                    <p
                                                        style={{
                                                            fontSize: "14px",
                                                            margin: "0rem"
                                                        }}
                                                    >
                                                        {Item.subtitle}
                                                    </p>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-around"
                                                    }}
                                                >
                                                    <button
                                                        className="kb-menu-item-right-btn"
                                                        onClick={() => {
                                                            context.updateOrder(
                                                                Item.id,
                                                                Item.quantity +
                                                                    1
                                                            );
                                                            setRepeatOrder(
                                                                false
                                                            );
                                                        }}
                                                        style={{
                                                            background:
                                                                window.themeColor
                                                        }}
                                                    >
                                                        add
                                                    </button>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <Button
                                            onClick={() => choiceHelper(true)}
                                            style={{
                                                background: window.themeColor
                                            }}
                                        >
                                            YES
                                        </Button>
                                    )}

                                    <Button
                                        onClick={() => choiceHelper(false)}
                                        style={{
                                            color: window.themeColor,
                                            borderColor: window.themeColor,
                                            background: "inherit"
                                        }}
                                    >
                                        NO
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div className="kb-menu-item-left">
                    <div
                        className="ml-1"
                        style={{
                            flex: true,
                            flexDirection: "row",
                            justifyContent: "flex-start"
                        }}
                    >
                        <div className="d-flex">
                            <Type type={item.eatarian} />
                            <h3
                                className={`kb-menu-item-left-heading`}
                                style={{
                                    color: theme,
                                    textTransform: "uppercase"
                                }}
                                onClick={() => decideMenuPopup()}
                            >
                                {title}
                            </h3>
                        </div>
                        {displayType &&
                        (displayType === "search" ||
                            displayType === "bestseller") ? (
                            <small
                                style={{
                                    color: window.themeColor,
                                    fontSize: "12px"
                                }}
                            >
                                {category ? `in ${category?.name}`:".."}
                            </small>
                        ) : null}
                    </div>

                    <div className="kb-menu-item-left-price">
                        {isHappyHr & item.special_price ? (
                            <p
                                style={{
                                    color: context.textColor
                                }}
                            >
                                {" " +
                                    window.rupeeSym +
                                    parseInt(item.special_price)}{" "}
                                <del>
                                    {window.rupeeSym + parseInt(item.price)}
                                </del>
                                {" (" +
                                    Math.round(
                                        ((parseInt(item.price) -
                                            parseInt(item.special_price)) /
                                            parseInt(item.price)) *
                                            100
                                    ) +
                                    "% off)"}
                            </p>
                        ) : (
                            <p
                                style={{
                                    color: context.textColor
                                }}
                            >
                                {window.rupeeSym + parseInt(item.price)}
                            </p>
                        )}
                    </div>
                </div>
                {colCheck ? (
                    <div className="kb-menu-item-right">
                        {img ? (
                            //   <Link to={{
                            //   pathname: `/item/${context.restdata.restaurant_id}/${item.item_id}`,
                            //   state: item
                            // }}>
                            <div
                                className="kb-menu-item-right-img-container"
                                onClick={() => setChooseOptions(true)}
                            >
                                <img
                                    src={img}
                                    alt="Menu Item"
                                    className="kb-menu-item-right-img"
                                    onError={() => setShowImg(false)}
                                />
                            </div>
                        ) : // </Link>
                        null}
                        {outOfStock ? (
                            <button
                                className="kb-menu-item-right-btn"
                                style={{
                                    backgroundColor: buttonIsDisabled
                                        ? "silver"
                                        : theme
                                }}
                                onClick={() => decideMenuPopup()}
                                disabled={buttonIsDisabled}
                            >
                                {"Sold Out"}
                            </button>
                        ) : menuTypeMatches() ? (
                            isDigitalMenu ||
                            !context.permissionToOrder ||
                            context.waitingForApproval ? null : context.selectedMenuType !==
                                  "dine-in" ||
                              (context.selectedMenuType === "dine-in" &&
                                  context.tableID) ? (
                                orderExists ? (
                                    <div className="kb-menu-item-cart">
                                        <button
                                            disabled
                                            className="btn btn-sm btn-success"
                                        >
                                            ADDED
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className="btn btn-sm "
                                        style={{
                                            backgroundColor: buttonIsDisabled
                                                ? "silver"
                                                : theme,
                                            color: window.svgTextColor
                                            // fontWeight: 'bold',
                                            // fontSize: '10px'
                                        }}
                                        onClick={() =>
                                            // decideMenuPopupFromBotton()
                                            decideMenuPopup()
                                        }
                                        disabled={buttonIsDisabled}
                                    >
                                        {buttonText}
                                    </button>
                                )
                            ) : null
                        ) : null}
                    </div>
                ) : null}
            </Wrapper>
        </div>
    );
};

export default withTheme(EachRestSuggestion);

// import React, { useState } from "react";
// import styled from "styled-components";
// import Type from "../../../../components/Type";
// import { withTheme } from "../../../../contexts/AppContext";
// import { errorImg } from "../../../components/KbCheckoutItem/KbCheckoutItem";

// const Wrapper = styled.div`
//     border-radius: 5px;
//     img {
//         border-radius: 5px;
//         height: 70px;
//         width: 60px;
//     }
//     p {
//         margin: 0;
//     }
//     #card-btn {
//         position: absolute;
//         right: 0;
//         bottom: 0;
//         font-weight: bold;
//     }
// `;

// function EachRestSuggestion({ data, context }) {
//     console.log(data);
//     const [showImg, setShowImg] = useState(true);
//     return (
//         <div className="col-8 pl-0 pr-0">
//             <Wrapper
//                 className="card m-2"
//                 style={{ background: window.highlightColor }}
//             >
//                 <div className="card-body d-flex p-2 ">
//                     {showImg ? <img
//                         // src={errorImg}
//                         src={
//                             showImg
//                                 ? window.kumbaURL +
//                                   "/image/items/" +
//                                   data.item_id
//                                 : errorImg
//                         }
//                         alt="food"
//                         width={"60"}
//                         className="shadow mr-2 align-self-center"
//                         onError={() => setShowImg(false)}
//                     />:null}
//                     <div>
//                         <div
//                             style={{
//                                 height:
//                                     data?.name?.length > 40 ? "60px" : "30px"
//                             }}
//                         >
//                             <div className="d-flex">
//                                 {context.restdata &&
//                                 (context.restdata.type === "restaurant" ||
//                                     context.restdata.type ===
//                                         "foodndrinksprovider") ? (
//                                     <Type
//                                         type={data.eatarian}
//                                         style={{
//                                             width: "10px",
//                                             height: "10px"
//                                         }}
//                                     />
//                                 ) : null}
//                                 <p
//                                     style={{
//                                         color: window.themeColor,
//                                         fontSize: "12px"
//                                     }}
//                                     className="fw-bold"
//                                 >
//                                     {data?.name}
//                                 </p>
//                             </div>
//                         </div>
//                         <p
//                             style={{
//                                 color: window.textColor,
//                                 fontSize: "13px"
//                             }}
//                             className={data?.name > 30 ? "mt-3" : "m-0"}
//                         >
//                             {window.rupeeSym + parseInt(data.price)}
//                         </p>
//                     </div>
//                 </div>
//                 <button
//                     style={{
//                         background: window.themeColor,
//                         color: window.svgTextColor
//                     }}
//                     className="btn btn-sm fw-bold"
//                     id="card-btn"
//                 >
//                     + ADD
//                 </button>
//             </Wrapper>
//         </div>
//     );
// }

// export default withTheme(EachRestSuggestion);
