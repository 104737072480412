import React, { Component } from "react";

import { AppContext, withTheme } from "../contexts/AppContext";

import { Redirect } from "react-router";

import "../css/input.css";
import "../css/ItemCustomization.css";

import ItemCustomization_Controller from "../controllers/items/ItemCustomization.controller";

import Cookies from "js-cookie";
import KbItemCustomizationView from "../v2/components/KbItemCustomization/KbItemCustomization";

class ItemCustomization extends Component {
  constructor(props) {
    super(props);

    this.submit = ItemCustomization_Controller.submit.bind(this);
    this.submitCustomizations = ItemCustomization_Controller.submitCustomizations.bind(
      this
    );
    this.onChange = ItemCustomization_Controller.onChange.bind(this);

    const itemID =
      this.props.match && this.props.match.params
        ? this.props.match.params.itemID
        : null;
    let item_id = itemID
      ? itemID
      : this.props.itemdata
      ? this.props.itemdata.item_id
      : null;
    this.choices = {};
    var app_id = "";
    this.state = {
      psid: null,
      loading: false,
      customizations: [],
      error: null,
      item_id: item_id,
      thread_context: null,
      token:
        this.props.context && this.props.context.token
          ? this.props.context.token
          : Cookies.get("token"),
      messengerLoaded: false,
      //src : src,
      quantity: 1,
      backtoMenu: false,
      addCard: false,
      showImg: true,
      confirmItem: false,
      numSelectedChoices : 0,
    };

    //this.getCustomizations = this.getCustomizations.bind(this);
  }

  componentDidMount() {
    //this.getCustomizations();
    console.log('CUSTOMIZATION PROPS ---',this.props);
  }

  

  render() {
    if (this.state.backtoMenu) {
      return <Redirect to={{ pathname: "/menu" }} />;
    } else if (this.state.confirmItem) {
      return <Redirect to={{ pathname: "/menu" }} />;
    } else if (this.state.addCard) {
      return <Redirect to={{ pathname: "/user/addcard" }} />;
    } else {
      var cancelWidth =
        this.props.itemdata.isOutOfStock || this.props.context.isDigitalMenu
          ? "100%"
          : "50%";

      var baseCustom =
        this.props.customizations && this.props.customizations.length > 0
          ? this.props.customizations.filter((x) => x.isBase)
          : [];

      var nonbaseCustom =
        this.props.customizations && this.props.customizations.length > 0
          ? this.props.customizations.filter((x) => !x.isBase)
          : [];

      var customs = baseCustom.concat(nonbaseCustom);

      return (
        <KbItemCustomizationView
          parent={this}
          customs={customs}
          cancelWidth={cancelWidth}
          allowOrder={this.props.allowOrder}
          numSelectedChoices={this.state.numSelectedChoices}
        />
      );
    }
  }
}

const AppItemCustomization = withTheme(ItemCustomization);
export default AppItemCustomization;
