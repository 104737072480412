import React, {useEffect, useState} from 'react'
import './KbReceiptDelivery.css';
import KbTimeline from '../KbTimeline/KbTimeline';

const KbReceiptDelivery = ({data}) => {
    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        switch(data.order_status) {
            case "awaiting_confirmation" : 
                setCurrentStep(1);
                break;
            
            case "accepted" :
                setCurrentStep(2);
                break;

            case "delivery-created" :
                setCurrentStep(3);
                break;

            case "delivery-accepted" :
                setCurrentStep(3);
                break;

            case "delivery-assigned" :
                setCurrentStep(3);
                break;

            case "delivery-pickedup" :
                setCurrentStep(4);
                break;

            case "delivery-ontheway" :
                setCurrentStep(4);
                break;

            case "delivery-arrived" :
                setCurrentStep(4);
                break;

            case "finished" :
                setCurrentStep(5);
                break;
        }
    },[data]);
    

    return (
        <div className='kb-receipt-delivery-container'>
            <div className="kb-receipt-delivery-top-flex">
                <p className="kb-receipt-delivery-text-left">ORDER STATUS</p>
                {/* <p className="kb-receipt-delivery-text-right">Track Order</p> */}
                <p className="kb-receipt-delivery-status">{data && data.order_status}</p>
            </div>

            <div className="kb-receipt-delivery-timeline">
                <KbTimeline steps={5} currentStep={currentStep} />
            </div>
        </div>
    )
}

export default KbReceiptDelivery
