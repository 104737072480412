import React, { useState } from 'react'
import '../../css/login.css'
import { FormGroup, Input, Label, Modal, ModalBody } from 'reactstrap'
// import ProcessOrder from '../../../controllers/signUp/processOrder.controller'
import ProcessOrder from '../../../signUp/ProcessOrder'
import Icon from 'react-icons-kit'
import { ic_info_outline } from 'react-icons-kit/md/ic_info_outline'
import { ic_done } from 'react-icons-kit/md/ic_done'
import OtpInput from 'react-otp-input'
import { CheckboxGroup, Checkbox } from 'react-checkbox-group'
import { Redirect } from 'react-router-dom'

const RestaurantLoginPage = ({
	pageOpen,
	setPageOpen,
	stage,
	otpSent,
	closeModal,
	context,
	phoneValid,
	onChange,
	onChangeName,

	onChangeLastName,
	lastName,
	updateUser,
	setRedirect,
	set_enable_whatapp,
	otpVerified,

	otp,
	onChangeOTP,
	sendOTP,
	sendPhoneNum,
	otpReceived,
	seconds,
	resendOTP,
	firstBtnDisabled,
	secondBtnDisabled,
	history,
	skip,
	verifyOTP,
	askName,
}) => {
	const [phone, setPhone] = useState(
		context && context.user && context.user.phone
			? context.user.phone.substr(-10)
			: ''
	)

	const [name, setName] = useState(
		context && context.user ? context.user.first_name : ''
	)

	const onChangePhone = (e) => {
		setPhone(e.target.value)
		onChange(e)
	}

	const onChangeNameHere = (e) => {
		setName(e.target.value)
		onChangeName(e)
	}

	const closePage = () => {
		setPageOpen(false)
		history.push('/main')
	}

	const stageArr = [1, 2]
	// console.log('LOGIN COMPONENT ----', context.user)
	const user = context.user

	let [approvedDrinks, setApprovedDrinks] = useState(
		context.restdata && context.restdata.type === 'foodndrinksprovider'
			? false
			: true
	)

	const onSelect = (options) => {
		if (options && options.includes('drinksApproval')) {
			setApprovedDrinks(true)
		} else {
			setApprovedDrinks(false)
		}
	}

	console.log({ load: context.loading })

    const logoImageUrl =
			context && context.restdata ? context.restdata.logo_image : null

	return (
		<div
			className="kb-login-page pt-1 pb-1 rounded"
			style={{ backgroundColor: window.highlightColor }}
		>
			{skip ? (
				<div className="kb-login-modal-close" onClick={() => closePage()}>
					<p className="kb-login-modal-close-text">&times;</p>
				</div>
			) : null}

			{logoImageUrl ? (
				<div className="text-center mb-5">
					<img src={logoImageUrl} alt="Restaurant Logo" width={80} />
				</div>
			) : null}

			{context && context.user && otpVerified ? (
				<>
					{askName ? (
						<div>
							<label
								htmlFor="telephone"
								className="kb-login-form-label"
								style={{ marginTop: '35%' }}
							>
								Your Name
							</label>
							<div className="kb-login-form-grid border rounded p-2">
								<p className="kb-login-form-text"></p>
								<input
									type="text"
									id="name"
									name="name"
									placeholder="Enter Your Name"
									className="kb-login-form-input"
									// defaultValue={context.user ? context.user.first_name : null}
									disabled={otpSent}
									onChange={(e) => onChangeNameHere(e)}
								/>
								<div
									className="kb-login-icon mb-2"
									style={{
										color: name.length > 2 ? 'green' : 'red',
										display: phone.length > 0 ? 'block' : 'none',
									}}
								>
									<Icon
										icon={name.length > 2 ? ic_done : ic_info_outline}
										size={24}
									/>
								</div>
							</div>
							<button
								className="kb-login-form-btn"
								onClick={() => updateUser()}
								disabled={firstBtnDisabled}
								style={{
									backgroundColor: context.themeColor,
									color: context.svgTextColor,
								}}
							>
								Submit & Continue
							</button>
						</div>
					) : null}
				</>
			) : null}

			{otpSent ? (
				<>
					<div className="kb-login-page-text text-center">
						<h2
							className="kb-login-page-title"
							style={{ color: window.textColor }}
						>
							OTP VERIFICATION
						</h2>
						<p
							className="kb-login-page-sub"
							style={{ color: window.textColor }}
						>
							Enter the OTP sent to{' '}
							<span className="kb-login-page-sub-span">
								{`${window.phoneCode} ${phone}`}
							</span>{' '}
						</p>
						{otpReceived && (
							<p
								className="kb-login-page-sub"
								style={{
									fontWeight: 'bolder',
									letterSpacing: '5px',
									color: window.textColor,
								}}
							>
								{otpReceived}
							</p>
						)}
					</div>

					<div className="kb-login-form-otp-container">
						<div className="kb-login-otp">
							<OtpInput
								onChange={onChangeOTP}
								value={otp}
								numInputs={4}
								isInputNum={true}
                                autoFocus
								containerStyle={'kb-login-otp-container'}
								inputStyle={{
									width: '45px',
									height: '45px',
									boxSizing: 'border-box',
									border: 'transparent',
									backgroundColor: '#eee',
									borderRadius: '4px',
									fontWeight: '600',
									boxShadow: '2px 2px 2px 0px rgba(100,100,100,1)',
								}}
								shouldAutoFocus={true}
							/>
						</div>

						<div className="kb-otp-text">
							<p
								className="kb-otp-text-top"
								style={{ color: window.textColor }}
							>
								Didn't receive any code?
							</p>
							{seconds > 0 ? (
								<p
									className="kb-otp-text-bottom"
									style={{ color: window.themeColor }}
								>
									RESEND NEW CODE (in {seconds} seconds)
								</p>
							) : (
								<p
									className="kb-otp-text-bottom"
									style={{ color: window.themeColor }}
									onClick={() =>
										context &&
										context.restdata &&
										context.restdata.enable_customer_otp
											? resendOTP()
											: sendPhoneNum()
									}
									// onClick={() => resendOTP()}
								>
									RESEND NEW CODE
								</p>
							)}
						</div>

						<br />
						<CheckboxGroup
							checkboxDepth={2}
							name={'groupDrinksApproval'}
							onChange={(e) => onSelect(e)}
						>
							<label
								style={{
									display: 'block',
									fontFamily: 'Lato',
									fontSize: '15px',
									color: '#544f4f',
									flex: true,
								}}
							>
								<Checkbox
									id="checkinputid"
									style={{ float: 'left', marginTop: '5px' }}
									key={'drinksApproval'}
									value={'drinksApproval'}
								/>{' '}
								<p
									style={{
										textAlign: 'left',
										color: window.textColor,
									}}
								>
									{
										'I understand and acknowledge that alcohol will only be served for age 21 or older'
									}
								</p>
							</label>
						</CheckboxGroup>

						<button
							className="kb-login-otp-btn"
							disabled={secondBtnDisabled || !approvedDrinks}
							onClick={() => verifyOTP()}
							style={{
								backgroundColor: context.themeColor,
								color: context.svgTextColor,
							}}
						>
							Verify & Proceed
						</button>
					</div>
				</>
			) : null}

			{context && !context.user && !otpSent ? (
				<>
					<div className="kb-login-page-text text-center">
						<h2
							className="kb-login-page-title"
							style={{ color: window.textColor }}
						>
							LOGIN
						</h2>
					</div>

					<div className="kb-login-form-container">
						<label
							htmlFor="telephone"
							className="kb-login-form-label"
							style={{ color: window.textColor }}
						>
							Enter mobile number
						</label>

						<div className="input-group mb-3">
							<div className="input-group-prepend">
								<span
									className="input-group-text"
									style={{
										backgroundColor: window.backgroundColor,
										color: window.themeColor,
										border: 0,
									}}
									id="basic-addon1"
								>
									{window.phoneCode}
								</span>
							</div>
							<input
								className="form-control"
								placeholder="Enter Phone Number"
								aria-label="Username"
								aria-describedby="basic-addon1"
								style={{
									backgroundColor: window.backgroundColor,
									border: 0,
									color: window.themeColor,
									letterSpacing: phone.length > 0 ? 5 : 0,
									height: '50px',
								}}
								type="number"
								disabled={otpSent}
								maxLength={10}
								id="telephone"
								name="telephone"
								onChange={(e) => onChangePhone(e)}
								value={phone}
							/>
							<div className="input-group-append">
								<span
									className="input-group-text d-flex align-items-center"
									style={{
										backgroundColor: window.backgroundColor,
										color: window.themeColor,
										border: 0,
										color: phoneValid ? 'green' : 'red',
										display: phone.length > 0 ? 'block' : 'none',
									}}
									id="basic-addon1"
								>
									<Icon
										icon={phoneValid ? ic_done : ic_info_outline}
										size={24}
									/>
								</span>
							</div>
						</div>

						{process.env.REACT_APP_SERVER_ENV !== 'test' ? (
							// <button
							//   className="kb-login-form-btn"
							//   onClick={() => sendOTP()}
							//   disabled={firstBtnDisabled}
							//   style={{backgroundColor : context.themeColor, color : context.svgTextColor }}
							// >
							//   Send OTP
							// </button>
							<button
								className="kb-login-form-btn"
								onClick={() =>
									context &&
									context.restdata &&
									context.restdata.enable_customer_otp
										? sendOTP()
										: sendPhoneNum()
								}
								// onClick={() => sendOTP()}
								disabled={firstBtnDisabled}
								// disabled={!phoneValid || context.loading}
								style={{
									backgroundColor: context.themeColor,
									color: context.svgTextColor,
								}}
							>
								Continue
							</button>
						) : (
							<button
								className="kb-login-form-btn"
								onClick={() =>
									context &&
									context.restdata &&
									context.restdata.enable_customer_otp
										? sendOTP()
										: sendPhoneNum()
								}
								// onClick={() => sendOTP()}
								// disabled={firstBtnDisabled}
								disabled={!phoneValid || context.loading}
								style={{
									backgroundColor: context.themeColor,
									color: context.svgTextColor,
								}}
							>
								Continue
							</button>
						)}
					</div>
				</>
			) : null}
		</div>
	)
}

export default RestaurantLoginPage
