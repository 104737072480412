import React, { useState, useEffect } from 'react';
import { Input, Label, FormGroup, ButtonGroup, Button } from 'reactstrap';
import { withTheme } from '../contexts/AppContext';
import Header from '../components/Header';
import TextInput from '../components/TextInput';
import CustomeBtn from '../components/CustomBtn';
import Axios from 'axios';
import AppLoading from '../components/AppLoading'
import TextInputGroup from '../components/TextInputGroup';
import { Redirect } from 'react-router';
import { set } from 'js-cookie';



export default withTheme(props => {
    const { context } = props;
    const [phone, setPhone] = useState(context.user ? context.user.phone : null);
    const [name, setName] = useState(context.user ? context.user.first_name : null);
    const [is_first_time, set_is_first_time] = useState(false);
    const [has_reservation, set_has_reservation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkinDone, setCheckinDone] = useState(false);

    const handleSubmit = () => {
        if(phone){
            setLoading(true)
            const data = {
                phone : window.phoneCode + phone,
                name,
                rest_id: context.restID,
                is_first_time,
                has_reservation,
                type: "check-in"
            }
            Axios({
                url: window.kumbaURL + "/restaurant/addguests",
                method: 'POST',
                data
            })
                .then(res => {
                    setLoading(false);
                    if (res.status === 200) {
                        context.popup('Your have successfully checked in at ' + context.restdata.name, 'success');
                        setTimeout(() => {
                            //setCheckinDone(true);
                            setPhone(context.user ? context.user.phone : null);
                            setName(context.user ? context.user.first_name : null);
                            set_has_reservation(false);
                            set_is_first_time(false);

                        }, 1000);
                    } else {
                        context.popup('someting went wrong please try again.', 'error');
                    }
                })
                .catch(err => {
                    setLoading(false);
                    context.popup('someting went wrong please try again.', 'error');
                    console.log(err)
                })
        }else {
            context.popup('Please Enter A Phone Number', 'warning');
        }
    };

    useEffect(() => {

    }, [])

    if (context && context.restdata) {
        if(checkinDone){
            return <Redirect to='/' />
        }else {
            return (
                <>
                    <div className='container' style={{ marginTop: '25%' }}>
                        <Header
                            title={"Checkin"}
                            current={"checkin"}
                        />
                        <div className='text-center'>
                            <h2 style={{ color: context.textColor }}>{"Welcome to " + context.restdata.name}</h2>
                            <p style={{ color: context.textColor, fontSize :  "15px" }}>{"Please check-in here"}</p>
                        </div>
                        <form>

                            <div className="row mt-2">
                                <div className="col-8">
                                        <p style={{color : window.textColor, fontSize : "18px"}}>
                                                {"Is this your first visit to " + context.restdata.name + "?"}
                                        </p>
                                </div>
                                <div className="col-4">
                                    <ButtonGroup>
                                        {is_first_time ? 
                                        <Button
                                            style={{
                                                backgroundColor : window.themeColor,
                                                color : window.svgTextColor
                                            }}
                                            onClick={() => set_is_first_time(true)}
                                        >
                                            Yes
                                        </Button> : 
                                        <Button
                                            style={{
                                                backgroundColor : window.svgTextColor,
                                                color : window.themeColor
                                            }}
                                            onClick={() => set_is_first_time(true)}
                                        >
                                            Yes
                                        </Button>
                                        }
                                        {is_first_time ? 
                                        <Button
                                            style={{
                                                backgroundColor : window.svgTextColor,
                                                color : window.themeColor
                                            }}
                                            onClick={() => set_is_first_time(false)}
                                        >
                                            No
                                        </Button> : 
                                        <Button
                                            style={{
                                                backgroundColor : window.themeColor,
                                                color : window.svgTextColor
                                            }}
                                            onClick={() => set_is_first_time(false)}
                                        >
                                            No
                                        </Button>
                                        }
                                    </ButtonGroup>
                                </div>
                            </div>

                            <div className="row mt-2">
                                <div className="col-8">
                                        <p style={{color : window.textColor, fontSize : "18px"}}>
                                                {"Do you have a reservation ?"}
                                        </p>
                                </div>
                                <div className="col-4">
                                    <ButtonGroup>
                                        {has_reservation ? 
                                        <Button
                                            style={{
                                                backgroundColor : window.themeColor,
                                                color : window.svgTextColor
                                            }}
                                            onClick={() => set_has_reservation(true)}
                                        >
                                            Yes
                                        </Button> : 
                                        <Button
                                            style={{
                                                backgroundColor : window.svgTextColor,
                                                color : window.themeColor
                                            }}
                                            onClick={() => set_has_reservation(true)}
                                        >
                                            Yes
                                        </Button>
                                        }
                                        {has_reservation ? 
                                        <Button
                                            style={{
                                                backgroundColor : window.svgTextColor,
                                                color : window.themeColor
                                            }}
                                            onClick={() => set_has_reservation(false)}
                                        >
                                            No
                                        </Button> : 
                                        <Button
                                            style={{
                                                backgroundColor : window.themeColor,
                                                color : window.svgTextColor
                                            }}
                                            onClick={() => set_has_reservation(false)}
                                        >
                                            No
                                        </Button>
                                        }
                                    </ButtonGroup>
                                </div>
                            </div>

                            <div className="mt-2">
                                <TextInputGroup
                                    labelText='Phone No:'
                                    name='phone'
                                    type='number'
                                    placeholder='Enter Phone Number'
                                    addontype="prepend"
                                    ig={window.phoneCode}
                                    value={phone ? phone : ""}
                                    onChange={e => setPhone(e.target.value)}
                                />
                                <TextInput
                                    labelText="Name"
                                    placeholder="Name"
                                    value={name ? name : ""}
                                    onChange={e => setName(e.target.value)}
                                />
                            </div>

                            <CustomeBtn
                                value={'Submit'}
                                onClick={handleSubmit}
                                customeClass={'w-100 mt-3'}
                                loading={loading}
                                disabled={loading}
                            />

                        </form>
                    </div>
                </>
            )
        }
    } else {
        return <AppLoading />
    }


});
