import React, { useState } from "react";
import { withTheme } from '../../../contexts/AppContext';

import AppLoading from "../../../components/AppLoading";

import KbRestaurantItem from "../../components/KbRestaurantItem/KbRestaurantItem";
import { Container, Row, Col } from 'reactstrap';


const RestaurantListingPage = ({ context }) => {

  let { listingdata } = context;

  return (
    <div>
      {!context.listingdata ? (
        <AppLoading />
      ) : (
        <>
          <div style={{ backgroundColor: window.themeColor }} className='shadow fixed-top'>
            <h3 style={{ color: window.svgTextColor, fontWeight: '600' }} className='text-center p-2'>{"Welcome to " + listingdata.name}</h3>
          </div>
          <Container style={{ marginTop: '120px' }}>
            {listingdata && listingdata.restaurants && listingdata.restaurants.length > 0 ?
              <Row noGutters>
                {listingdata.restaurants.map(eachrest => <Col xs={6} md={6}><KbRestaurantItem restdata={eachrest} /></Col>)}
              </Row> : null}
          </Container>
        </>
      )
      }
    </div>
  )
}

const AppRestaurantListingPage = withTheme(RestaurantListingPage);

export default AppRestaurantListingPage;
