import React from 'react';
// import '../css/Footer.css';

const TotalsRow = ({ title, amount, bold }) => {
  return (
    // <>
    // <label className="kb-cart-label">
    //   <h6 style={{ display: "inline", color: window.themeColor }}>
    //     <span style={{ float: "left" }}>
    //       <h5 style={bold ? { fontWeight: 'bold', color: window.themeColor } : { color: window.themeColor }}>{title}</h5>
    //     </span>
    //     <span style={{ float: "right", }}>
    //       <h5 style={bold ? { fontWeight: 'bold', color: window.themeColor } : { color: window.themeColor }}>{amount}</h5>
    //     </span>
    //   </h6>
    // </label>
    // <br />
    // </>
    <div
      className="kb-receipt-dinein-delivery"
      style={{ fontWeight: "500" }}
    >
      <span style={{ float: "left" }}>
        <h6 style={bold ? { fontWeight: 'bold', color: window.themeColor } : { color: window.themeColor }}>{title}</h6>
      </span>
      <span style={{ float: "right", }}>
        <h6 style={bold ? { fontWeight: 'bold', color: window.themeColor } : { color: window.themeColor }}>{amount}</h6>
      </span>
    </div>
  )
}

export default TotalsRow;
