import sailsIOClient from 'sails.io.js';
//var sailsIOClient = require('sails.io.js');
import socketIOClient from 'socket.io-client';

//var io = sailsIOClient.sails.connect('https://api.foodbots.co');

var io = sailsIOClient(socketIOClient);
//io.sails.url = "https://api.foodbots.co";
io.sails.url = process.env.REACT_APP_BACKEND_URL;
//"https://api.foodbots.net";

io.socket.on('disconnect', function() {
    io.socket._raw.io._reconnection = true;
});

export default io;