import cogoToast from "cogo-toast";
import axios from "axios";

export default class Guest_Controller {
  static async addGuests() {
    if (this.props.context && this.props.context.isUserDone) {
      const hide = cogoToast.loading("Adding guest ... ", { hideAfter: 0 });
      try {
        let url = window.kumbaURL + "/restaurant/addguests";
        let header = { "foodbots-header": this.props.context.token };
        const response = await axios({
          url,
          headers: header,
          method: "POST",
          data: {
            user: this.props.context.user.user_id,
            num_male: this.state.num_male,
            num_female: this.state.num_female,
            num_other: this.state.num_other,
            event_date: this.state.add_date,
            rest_id: this.props.context.restID,
            order_id: this.props.context.orderID || null,
            event:
              this.props.context && this.props.context.evendata
                ? this.props.context.evendata.id
                : null,
          },
        });
        this.setState({
          showAddModal: false,
          name: "",
          phone: "",
          num_male: 0,
          num_female: 0,
          num_other: 0,
          add_date: new Date(),
        });
        var data = response.data;
        hide();
        cogoToast.success("Added guest successfully.", {
          hideAfter: 2,
        });
        return new Promise((resolve, reject) => {
          return resolve(data);
        });
      } catch (err) {
        console.log(err);
        hide();
        cogoToast.error("Could not add guest. Please try again", {
          hideAfter: 2,
        });
        return new Promise((resolve, reject) => {
          return reject(err);
        });
      }
    }
  }

  static handleSelectChange(selectedOption) {
    // console.log(selectedOption.value);
    this.setState({ role: selectedOption.value });
  }

  static onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  static onChangePhone(e) {
    if (e.target.value === "" || e.target.value.match(/^[0-9]*$/)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  static openAddModal() {
    this.setState({ showAddModal: true });
  }

  static closeAddModal() {
    this.setState({ showAddModal: false });
  }

  static openAddCheckinModal() {
    this.setState({ showAddModal: true, showCheckinDetails: true });
  }

  static closeAddCheckinModal() {
    this.setState({ showAddModal: false, showCheckinDetails: false });
  }

  static incCount(type) {
    this.setState({ [type]: this.state[type] + 1 });
  }

  static decCount(type) {
    if (this.state[type] > 0) {
      this.setState({ [type]: this.state[type] - 1 });
    }
  }
}
