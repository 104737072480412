import React, { Component } from "react";
import axios from "axios";
import io from "../socket-client";
import Cookies from "js-cookie";
import cogoToast from "cogo-toast";
import AppContext_Controller, {initialState} from "../controllers/contexts/AppContext.controller";

export const AppContext = React.createContext({
  restID: null,
  token: null,
  restdata: null,
  user: null,
  activated: null,
  loading: false,
  io: null,
  themeColor: "#6abacd",
  loadRestaurantDetails: () => {},
  login: () => {},
  logout: () => {},
  signup: () => {},
  fblogin: () => {},
  handleBarcodeScan: () => {},
});

function getPWADisplayMode() {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  if (document.referrer.startsWith('android-app://')) {
    return 'twa';
  } else if (navigator.standalone || isStandalone) {
    return 'standalone';
  }
  return 'browser';
}

export class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);

    this.activateremTimer = AppContext_Controller.activateremTimer.bind(this);
    this.finalizeOrderNow = AppContext_Controller.finalizeOrderNow.bind(this);
    this.setToken = AppContext_Controller.setToken.bind(this);
    this.getRestaurantDetails = AppContext_Controller.getRestaurantDetails.bind(
      this
    );
    this.setRestaurantID = AppContext_Controller.setRestaurantID.bind(this);
    this.setListingID = AppContext_Controller.setListingID.bind(this);
    this.setDigitalMenu = AppContext_Controller.setDigitalMenu.bind(this);
    this.fetchRestaurantData = AppContext_Controller.fetchRestaurantData.bind(
      this
    );
    this.loadRestaurantDetails = AppContext_Controller.loadRestaurantDetails.bind(
      this
    );
    this.loadListingDetails = AppContext_Controller.loadListingDetails.bind(
        this
    );
    this.getCategoryItems = AppContext_Controller.getCategoryItems.bind(this);
    this.googlelogin = AppContext_Controller.googlelogin.bind(this);
    this.googlesignout = AppContext_Controller.googlesignout.bind(this);
    this.login = AppContext_Controller.login.bind(this);
    this.logout = AppContext_Controller.logout.bind(this);
    this.signup = AppContext_Controller.signup.bind(this);
    this.updateToken = AppContext_Controller.updateToken.bind(this);
    this.selectRest = AppContext_Controller.selectRest.bind(this);
    this.getUser = AppContext_Controller.getUser.bind(this);
    this.handleBarcodeScan = AppContext_Controller.handleBarcodeScan.bind(this);
    this.updateEmailToken = AppContext_Controller.updateEmailToken.bind(this);
    this.getTableDetails = AppContext_Controller.getTableDetails.bind(this);
    this.getEventDetails = AppContext_Controller.getEventDetails.bind(this);
    this.changeTableDetails = AppContext_Controller.changeTableDetails.bind(
      this
    );
    this.recordScan = AppContext_Controller.recordScan.bind(this);
    this.confirmOrder = AppContext_Controller.confirmOrder.bind(this);
    this.clearData = AppContext_Controller.clearData.bind(this);
    this.clearOrderData = AppContext_Controller.clearOrderData.bind(this);
    this.updateTable = AppContext_Controller.updateTable.bind(this);
    this.addShare = AppContext_Controller.addShare.bind(this);
    this.createOrder = AppContext_Controller.createOrder.bind(this);
    this.removeItemInOrder = AppContext_Controller.removeItemInOrder.bind(this);
    this.updateItemInOrder = AppContext_Controller.updateItemInOrder.bind(this);
    this.addOrders = AppContext_Controller.addOrders.bind(this);
    this.updateUnconfirmedCount = AppContext_Controller.updateUnconfirmedCount.bind(
      this
    );
    this.removeOrder = AppContext_Controller.removeOrder.bind(this);
    this.updateOrder = AppContext_Controller.updateOrder.bind(this);
    this.updateOrderInstructions = AppContext_Controller.updateOrderInstructions.bind(this);
    this.removeComboCustomization = AppContext_Controller.removeComboCustomization.bind(
      this
    );
    this.updateComboCustomization = AppContext_Controller.updateComboCustomization.bind(
      this
    );
    this.submitOrder = AppContext_Controller.submitOrder.bind(this);
    this.clearOrder = AppContext_Controller.clearOrder.bind(this);
    this.fetchOrder = AppContext_Controller.fetchOrder.bind(this);
    this.checkoutFeedback = AppContext_Controller.checkoutFeedback.bind(this);
    this.trueCallerAuth = AppContext_Controller.trueCallerAuth.bind(this);
    this.getOrder = AppContext_Controller.getOrder.bind(this);
    this.addPersonToOrder = AppContext_Controller.addPersonToOrder.bind(this);
    this.updateOrderPerUser = AppContext_Controller.updateOrderPerUser.bind(
      this
    );
    this.cancelOrder = AppContext_Controller.cancelOrder.bind(this);
    this.updateSubOrder = AppContext_Controller.updateSubOrder.bind(this);
    this.getuserinfos = AppContext_Controller.getuserinfos.bind(this);
    this.getpayingfor = AppContext_Controller.getpayingfor.bind(this);
    this.perosnifyInformation = AppContext_Controller.perosnifyInformation.bind(
      this
    );
    this.checkoutOrder = AppContext_Controller.checkoutOrder.bind(this);
    this.makePaymentOffline = AppContext_Controller.makePaymentOffline.bind(
      this
    );
    this.unsubscribePush = AppContext_Controller.unsubscribePush.bind(this);
    this.unsubscribeNotifPush = AppContext_Controller.unsubscribeNotifPush.bind(
      this
    );
    this.subscribePush = AppContext_Controller.subscribePush.bind(this);
    this.registerSubKumba = AppContext_Controller.registerSubKumba.bind(this);
    this.initializeNotificationUI = AppContext_Controller.initializeNotificationUI.bind(
      this
    );
    this.sendOTP = AppContext_Controller.sendOTP.bind(this);
    this.sendPhoneNum = AppContext_Controller.sendPhoneNum.bind(this);
    this.resendOTP = AppContext_Controller.resendOTP.bind(this);
    this.verifyOTP = AppContext_Controller.verifyOTP.bind(this);
    this.sendEmailOTP = AppContext_Controller.sendEmailOTP.bind(this);
    this.verifyEmailOTP = AppContext_Controller.verifyEmailOTP.bind(this);
    this.getCards = AppContext_Controller.getCards.bind(this);
    this.addCard = AppContext_Controller.addCard.bind(this);
    this.updateFilter = AppContext_Controller.updateFilter.bind(this);
    this.updateCard = AppContext_Controller.updateCard.bind(this);
    this.googleLogin = AppContext_Controller.googleLogin.bind(this);
    this.setStaticMenu = AppContext_Controller.setStaticMenu.bind(this);
    this.setMenuType = AppContext_Controller.setMenuType.bind(this);
    this.setMultipleCookies = AppContext_Controller.setMultipleCookies.bind(
      this
    );
    this.readyToPay = AppContext_Controller.readyToPay.bind(this);
    this.setContextState = AppContext_Controller.setContextState.bind(this);
    this.processLandingURL = AppContext_Controller.processLandingURL.bind(this);
    this.popup = AppContext_Controller.popup.bind(this);
    this.handleAssistance = AppContext_Controller.handleAssistance.bind(this);
    this.installApp = AppContext_Controller.installApp.bind(this);
    this.beforeInstallApp = AppContext_Controller.beforeInstallApp.bind(this);
    this.afterInstallApp = AppContext_Controller.afterInstallApp.bind(this);

    this.createCartFromOrder = AppContext_Controller.createCartFromOrder.bind(this);
    this.updateUserStatusOnTable = AppContext_Controller.updateUserStatusOnTable.bind(
        this
    );
    this.loginFromPopup = AppContext_Controller.loginFromPopup.bind(this);
    this.togglePWAInstall = AppContext_Controller.togglePWAInstall.bind(this);
    this.checkUserOrderStatus = AppContext_Controller.checkUserOrderStatus.bind(this);
    this.joinTable = AppContext_Controller.joinTable.bind(this);
    this.updateUserStatusOnTable = AppContext_Controller.updateUserStatusOnTable.bind(this);

    this.requestPermissionToOrder =
        AppContext_Controller.requestPermissionToOrder.bind(this);
    
    this.unconfirmedOrders = null;
    this.auth2 = null;
    this.googleUser = null;
    this.interval = null;
    this.timeout = null;

    this.state = {
      // Default Color is = rgb(122, 193, 209);
      ...initialState,

      permissionToOrder: true,
      waitingForApproval: false,
      shareData: null,
      setToken: this.setToken.bind(this),

      loadRestaurantDetails: this.loadRestaurantDetails.bind(this),
      loadListingDetails: this.loadListingDetails.bind(this),
      login: this.login.bind(this),
      logout: this.logout.bind(this),
      signup: this.signup.bind(this),
      handleBarcodeScan: this.handleBarcodeScan.bind(this),
      updateEmailToken: this.updateEmailToken.bind(this),
      updateTable: this.updateTable.bind(this),
      getUser: this.getUser.bind(this),
      getCards: this.getCards.bind(this),
      addCard: this.addCard.bind(this),
      updateCard: this.updateCard.bind(this),
      createOrder: this.createOrder.bind(this),
      fetchOrder: this.fetchOrder.bind(this),
      getOrder: this.getOrder.bind(this),
      updateOrderPerUser: this.updateOrderPerUser.bind(this),
      getuserinfos: this.getuserinfos.bind(this),
      getpayingfor: this.getpayingfor.bind(this),
      perosnifyInformation: this.perosnifyInformation.bind(this),
      updateSubOrder: this.updateSubOrder.bind(this),
      addShare: this.addShare.bind(this),
      addPersonToOrder: this.addPersonToOrder.bind(this),
      updateToken: this.updateToken.bind(this),
      checkoutOrder: this.checkoutOrder.bind(this),
      getTableDetails: this.getTableDetails.bind(this),
      getEventDetails: this.getEventDetails.bind(this),
      changeTableDetails: this.changeTableDetails.bind(this),
      clearData: this.clearData.bind(this),

      addOrders: this.addOrders.bind(this),
      updateOrder: this.updateOrder.bind(this),
      updateOrderInstructions: this.updateOrderInstructions.bind(this),
      removeOrder: this.removeOrder.bind(this),
      submitOrder: this.submitOrder.bind(this),
      clearOrder: this.clearOrder.bind(this),
      clearOrderData: this.clearOrderData.bind(this),
      removeItemInOrder: this.removeItemInOrder.bind(this),
      updateItemInOrder: this.updateItemInOrder.bind(this),
      processLandingURL: this.processLandingURL,

      createCartFromOrder: this.createCartFromOrder.bind(this),

      removeComboCustomization: this.removeComboCustomization.bind(this),
      updateComboCustomization: this.updateComboCustomization.bind(this),

      googlelogin: this.googlelogin.bind(this),
      googlesignout: this.googlesignout.bind(this),
      makePaymentOffline: this.makePaymentOffline.bind(this),

      initializeNotificationUI: this.initializeNotificationUI.bind(this),
      recordScan: this.recordScan.bind(this),

      sendOTP: this.sendOTP.bind(this),
      resendOTP: this.resendOTP.bind(this),
      verifyOTP: this.verifyOTP.bind(this),
      sendPhoneNum: this.sendPhoneNum.bind(this),

      sendEmailOTP: this.sendEmailOTP.bind(this),
      verifyEmailOTP: this.verifyEmailOTP.bind(this),

      updateFilter: this.updateFilter.bind(this),

      selectRest: this.selectRest.bind(this),

      setRestaurantID: this.setRestaurantID.bind(this),
      setListingID: this.setListingID.bind(this),
      setDigitalMenu: this.setDigitalMenu.bind(this),
      setStaticMenu: this.setStaticMenu.bind(this),

      handleAssistance: this.handleAssistance.bind(this),

      trueCallerAuth: this.trueCallerAuth.bind(this),

      checkoutFeedback: this.checkoutFeedback.bind(this),
      confirmOrder: this.confirmOrder.bind(this),

      activateremTimer: this.activateremTimer.bind(this),
      finalizeOrderNow: this.finalizeOrderNow.bind(this),

      googleLogin: this.googleLogin.bind(this),
      setMenuType: this.setMenuType.bind(this),
      setMultipleCookies: this.setMultipleCookies.bind(this),
      readyToPay: this.readyToPay.bind(this),
      setContextState: this.setContextState.bind(this),
      installApp: this.installApp.bind(this),

      popup: this.popup.bind(this),


      cancelOrder: this.cancelOrder.bind(this),
      loginFromPopup: this.loginFromPopup.bind(this),
      togglePWAInstall: this.togglePWAInstall.bind(this),

      checkUserOrderStatus: this.checkUserOrderStatus.bind(this),
      joinTable: this.joinTable.bind(this),
      updateUserStatusOnTable: this.updateUserStatusOnTable.bind(this),
    };
  }

  async componentDidMount() {

    //   this.togglePWAInstall()

    try {

    // console.log('APP CONTEXT ---', this.state)
    
    document.querySelector(
      "body"
    ).style.backgroundColor = this.state.backgroundColor;

    const token = Cookies.get("token") || null;

    this.setState({token: token});

    if(token){
        await this.getUser();
    }

    this.unconfirmedOrders = Cookies.getJSON('unconfirmedOrders') || [];
    this.updateUnconfirmedCount();

    // console.log('Done till here');

    await this.processLandingURL();
    // console.log(`uco ${this.unconfirmedOrders}`);

    // console.log("Done till here 2");

    io.socket.on("table_change", (data) => {
      // console.log("data ---", data);
      cogoToast.warn("Your table has been changed..");
      this.getTableDetails(data.data.new_table);
      this.setState({ orderID: Cookies.get("orderID") });
    });

    // io.socket.on(
    //   "restaurantupdate",
    //   function (data) {
    //     this.loadRestaurantDetails();
    //   }.bind(this)
    // );

    // window.addEventListener('beforeinstallprompt', (e) => this.beforeInstallApp(e));

    // window.addEventListener('appinstalled', () => this.afterInstallApp());

    io.socket.on(
      "orderupdate",
      function (data) {
        if (this.state.orderID) {
          this.getOrder();
        }
      }.bind(this)
    );

    io.socket.on(
      "orderconfirm",
      function (data) {
        if (this.state.orderID) {
          this.getOrder();
        }
      }.bind(this)
    );

    io.socket.on(
        "table_status_change",
        function (data) {
            this.getTableDetails(data.table_id)
        }.bind(this)
    );

    io.socket.on(
      "userupdate",
      function (data) {
        this.state.getUser();
      }.bind(this)
    );

    io.socket.on(
      "orderadded",
      function (data) {
        this.getTableDetails(this.state.tableID);
      }.bind(this)
    );

    // io.socket.on(
    //     'table_status_change',
    //     function (data) {
    //         this.getTableDetails(this.state.tableID);
    //     }.bind(this)
    // );

    io.socket.on(
      "orderfinished",
      function (data) {
        this.clearOrderData();
        setTimeout(() => {
            window.location.reload();
        }, 2000);
      }.bind(this)
    );

    io.socket.on(
      "reqauth",
      async function (rawdata) {
        //alert(rawdata);
        var data = JSON.parse(rawdata);
        if (data.accessToken) {
          this.setState({ token: data.accessToken });
          await this.getUser();
          this.setState({ loading: false });
        } else if (data.status) {
          this.setState({ token: null, loading: false });
          //this.setState({loading : false});
        }
      }.bind(this)
    );

    if (process.env.NODE_ENV === "development") {
      console.log("AppContext Mounts", {
        token: token || null,
        restID: Cookies.get("restID") || null,
        tableID: Cookies.get("tableID") || null,
        orderID: Cookies.get("orderID") || null,
        isStatic: Cookies.get("isStatic") || null,
        state: this.state,
      });
    }
    if (sessionStorage.getItem('menuType')) {
      // alert(sessionStorage.getItem('menuType'))
      this.setState({
        selectedMenuType: sessionStorage.getItem("menuType"),
      });
    }

    return Promise.resolve();
    } catch(e) {
        console.log(e);
        return Promise.reject(e);
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{ ...this.state, unconfirmedOrders: this.unconfirmedOrders }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export function withTheme(Component) {
  // ...and returns another component...
  return function ThemedComponent(props) {
    // ... and renders the wrapped component with the context!
    // Notice that we pass through any additional props as well
    return (
      <AppContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </AppContext.Consumer>
    );
  };
}
