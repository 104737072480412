import React, { Component } from 'react';
import {Row, Col, Button, ListGroupItem, ListGroup, Modal, ModalBody, ModalFooter, ModalHeader, CardTitle, InputGroup, InputGroupAddon, Input, Card, CardBody, ButtonGroup} from 'reactstrap';
import { AppContext, withTheme } from '../contexts/AppContext';
import RPCheckout from "../cartMiniComponents/PaymentCheckout";

import PackageCart_Controller from "../controllers/guests/PackageCart.controller";
  
var styles = {
    Navbarstyle: {
		backgroundColor : "white",
        fontFamily:"Lato",
		boxShadow:"0px 1px 5px darkgrey",
		width : "100%",
		//position : "fixed",
		zIndex : "100",
		//left : 0,
		left : "auto",
		top : 0,
		height : "60px",
    },
    NavbarBrandstyle: {
        fontSize:"15px",
		color:"#5a5050",
		textAlign : "center",
		width : "100%",
		fontFamily:"Lato",
    },
    SummaryButtonStyle:{
        backgroundColor:"#f15b4d",
        fontFamily:"Lato",
        display:"block",
        marginRight:"auto",
        marginLeft:"auto",
        border:"none",
        fontSize:"15px",
		width:"130px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        borderRadius: "6px",
		
	},
	ViewButtonstyle:{
        backgroundColor:"#f15b4d",
        fontFamily:"Lato",
        display:"block",
        border:"none",
        fontSize:"15px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        borderRadius: "6px",
		width : "100%",
		height : "40px",
		position : "fixed",
		zIndex : "100",
		left : 0,
		bottom : 0,
	},
	CancelButtonstyle:{
        backgroundColor:"#f15b4d",
        fontFamily:"Lato",
        display:"block",
        border:"none",
        fontSize:"15px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        //borderRadius: "6px",
		width : "50%",
		height : "40px",
		position : "fixed",
		zIndex : "120",
		right : 0,
		bottom : 0,
	},
	MenuButtonstyle:{
        backgroundColor:"#6abacd",
        fontFamily:"Lato",
        display:"block",
        border:"none",
        fontSize:"15px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        //borderRadius: "6px",
		width : "30%",
		height : "40px",
		position : "fixed",
		zIndex : "120",
		bottom : "20px",
		right : "33%",
		borderRadius : "20px", 
		margin:"5px", 
		//marginRight : "5px", 
		
	},
	LoginModalButtonstyle:{
        fontFamily:"Lato",
        backgroundColor:"#f15b4d",
        display:"block",
        border:"none",
        fontSize:"15px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        borderRadius: "6px",
		//width : "50%",
        height : "40px",
        margin : "5px"
    },
	Buttongreenstyle:{
        backgroundColor:"#6abacd",
        marginTop:"20px",
        fontFamily:"Lato",
        display:"block",
        marginRight:"auto",
        marginLeft:"auto",
        border:"none",
        fontSize:"15px",
        width:"130px",
        boxShadow:"rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px",
        borderRadius: "6px",
        padding: "10px 16px",
    },
    CardStyle: {
        marginTop:"5px",
        boxShadow:"1px 1px 5px darkgrey"
    },
    CardTitleStyle: {
        fontFamily:"Lato",
        fontSize:"15px",
        color:"#6abacd",
		paddingBottom:"10px",
        borderBottom:"1px solid",
        borderBottomColor:"#b9a6a6"
    },
    labelstyle:{
        display:"block",
        fontFamily:"Lato",
		fontSize:"15px",
	},
	labellightstyle:{
        display:"block",
        fontFamily:"Lato",
		fontSize:"15px",
		color : "#ffffff"
	},
	listItemStyle : {
		border : 0,
		maxHeight : "60px",
		fontFamily:"Lato",
		fontSize:"15px",
		backgroundColor : "white",
		margin : 0,
		padding : "5px",
		//borderBottom:"1px solid",
        //borderBottomColor:"#b9a6a6"
	}
};

class PackageCart extends Component {
	constructor(props) {
		super(props);

		this.addOrders = PackageCart_Controller.addOrders.bind(this);
		this.removeItemInOrder = PackageCart_Controller.removeItemInOrder.bind(this);
		this.updateItemInOrder = PackageCart_Controller.updateItemInOrder.bind(this);
		this.submitOrder = PackageCart_Controller.submitOrder.bind(this);
		this.clearOrder = PackageCart_Controller.clearOrder.bind(this);
		this.closeDialog = PackageCart_Controller.closeDialog.bind(this);
		this.payAtRestaurant = PackageCart_Controller.payAtRestaurant.bind(this);
		

		this.ordersList = [];
		this.state = {
			refresh : true,
			orderdata : null,
			dialogOpen : false,
		};
	}
   
      componentDidMount() {
		this.clearOrder();
		/*if(this.props.guest && this.props.guest.order) {
			this.fetchOrder();
		}*/
	  }
      
      render() {
        var confirmedOrders = [];
		var unconfirmedOrders = [];

		var unconfirmedTotal = 0.0;
		var confirmedTotal = 0.0;

		var confirmedTax = 0.0;
		var confirmedTaxes = 0.0;
		var confirmedCGST = 0.0;
		var confirmedSGST = 0.0;
		var confirmedServiceFee = 0.0;
		var confirmedFees = 0.0;
		var confirmedGrandTotal = 0.0;

		var unconfirmedTax = 0.0;
		var unconfirmedTaxes = 0.0;
		var unconfirmedCGST = 0.0;
		var unconfirmedSGST = 0.0;
		var unconfirmedServiceFee = 0.0;
		var unconfirmedFees = 0.0;
		var unconfirmedGrandTotal = 0.0;

		if(this.ordersList && this.ordersList.length > 0) {
			this.ordersList.forEach((order, index) => {
				var orderTotal = order.itemdata.price*order.quantity;
				var orderItem = <ListGroupItem style={{textAlign : "center",fontFamily:"Lato"}}>
						<Row style={{fontSize:"15px"}}>
							<Col md={3} xs={3} style={{textAlign:"left"}}>
								<p>{order.itemdata.name}</p>
							</Col>
							<Col md={4} xs={4}>
								{/*order.quantity*/}
								<InputGroup size="sm">
									<InputGroupAddon addonType="prepend"> 
										<Button size="xs" style={{backgroundColor:"#f15b4d",border:"none"}} onClick={() => {this.updateItemInOrder(order.id, order.quantity-1);}}>
											-
										</Button>
									</InputGroupAddon>
									<Input style={{textAlign : "center"}} value={order.quantity} />
									<InputGroupAddon addonType="append">
										<Button size="xs" style={{backgroundColor:"#6abacd",border:"none"}} onClick={() => {this.updateItemInOrder(order.id, order.quantity+1);}}>
											+
										</Button> 
									</InputGroupAddon>
								</InputGroup>
							</Col>
							<Col md={3} xs={3}>
								<span>{window.rupeeSym + orderTotal}</span>
							</Col>
							{/*}
							<Col md={1} xs={1}>
								<Button size="xs" style={{backgroundColor:"#ffffff",color : "#f15b4d", border:"none"}} onClick={() => this.removeItemInOrder(order.item_id)}>
									<Icon icon={bin}></Icon>
								</Button>
							</Col>
							*/}
						</Row>
						</ListGroupItem>
					
					unconfirmedOrders.push(orderItem);
					unconfirmedTotal += orderTotal;

					if(process.env.REACT_APP_COUNTRY === "IN") {
						var cgst = order.itemdata.cgst || 2.5;
						var sgst = order.itemdata.sgst || 2.5;

						unconfirmedCGST += orderTotal * cgst / 100;
						unconfirmedSGST += orderTotal * sgst / 100;
					} else {
						var tax = this.props.context.restdata.tax_rate || 8;
						unconfirmedTaxes += orderTotal * tax / 100;
					}
			});

			//unconfirmedServiceFee = unconfirmedTotal * this.state.service_fee / 100;
			unconfirmedFees = unconfirmedTotal * window.customerFees / 100;
			if(process.env.REACT_APP_COUNTRY === "IN") {
			  unconfirmedTax = unconfirmedCGST + unconfirmedSGST;
				unconfirmedGrandTotal =
			  unconfirmedTotal + unconfirmedTax + unconfirmedFees + unconfirmedServiceFee;
			} else {
			   unconfirmedGrandTotal =
			  unconfirmedTotal +
			  unconfirmedTaxes +
			  unconfirmedServiceFee +
			  unconfirmedFees;
			}
		}

		/*
		if(this.props.guest && this.props.guest.order && this.state.orderdata) {
			this.state.orderdata.packages.forEach((order, index) => {
				var orderTotal = order.item_id.price*order.quantity;
				var orderItem = <ListGroupItem style={{textAlign : "center",fontFamily:"Lato"}}>
						<Row style={{fontSize:"15px"}}>
							<Col md={4} xs={4} style={{textAlign:"left"}}>
								<p>{order.item_id.name}</p>
							</Col>
							<Col md={4} xs={4}>
								<Input style={{textAlign : "center"}} value={order.quantity} />
							</Col>
							<Col md={4} xs={4}>
								<span>{window.rupeeSym + orderTotal}</span>
							</Col>
							
						</Row>
						</ListGroupItem>
					confirmedOrders.push(orderItem);
					confirmedTotal += orderTotal;
				  if(process.env.REACT_APP_COUNTRY === "IN") {
					var cgst = order.order_cgst || 2.5;
					var sgst = order.order_sgst || 2.5;
	  
					confirmedCGST += orderTotal * cgst / 100;
					confirmedSGST += orderTotal * sgst / 100;
				  } else {
					var tax = this.props.context.restdata.tax_rate || 8;
					confirmedTaxes += orderTotal * tax / 100;
				  }
			});

			//confirmedServiceFee = confirmedTotal * this.state.service_fee / 100;
			confirmedFees = confirmedTotal * window.customerFees / 100;
			if(process.env.REACT_APP_COUNTRY === "IN") {
				confirmedTax = confirmedCGST + confirmedSGST;
				confirmedGrandTotal = confirmedTotal + confirmedTax + confirmedFees + confirmedServiceFee;
			} else {
				confirmedGrandTotal =
				confirmedTotal + confirmedTaxes + confirmedServiceFee + confirmedFees;
			}
		}
		*/


          return(
              
				   
						<div>
							<Modal
								isOpen={this.state.dialogOpen}
								size="lg"
								style={{
								overflow: "auto",
								height: "80%",
								top: "10%"
								}}
								aria-labelledby="contained-modal-title-lg"
							>
					  <ModalHeader style={{ backgroundColor: this.props.context.backgroundColor}} toggle={this.closeDialog.bind(this)} />
								
								<ModalBody style={{ backgroundColor: this.props.context.backgroundColor}}>
								<RPCheckout
									closeDialog={this.closeDialog.bind(this)}
									payAtRestaurant={this.payAtRestaurant.bind(this)}
									total={unconfirmedGrandTotal}
									credits_used={0.00}
									onFinish={"chargeAndConfirm"}
									submitOrder={this.submitOrder.bind(this)}
									packages={unconfirmedOrders}
								/>
								</ModalBody>
							</Modal>
							<ListGroup>
								<ListGroupItem style={{ backgroundColor: this.props.context.backgroundColor }}>
									<Row>
										<Col>
											<b>{"Item"}</b>
										</Col>
										<Col>
											<b>{"Quantity"}</b>
										</Col>
										<Col>
											<b>{"Total"}</b>
										</Col>
									</Row>
								</ListGroupItem>
								{unconfirmedOrders}
							</ListGroup>
							<Card id="totalcard" style={styles.CardStyle} body>
								<label style={styles.labelstyle}><p style={{"display":"inline","fontFamily":"Lato","color" : "#5a5050",fontWeight:"bold"}}>Subtotal<span style={{"float":"right"}}>{window.rupeeSym + unconfirmedTotal.toFixed(2)}</span></p></label>
								<label style={styles.labelstyle}><p style={{"display":"inline"}}>Taxes & Fees<span style={{"float":"right"}}>
								{process.env.REACT_APP_COUNTRY === "IN" ? window.rupeeSym + unconfirmedTax.toFixed(2) : window.rupeeSym + unconfirmedTaxes.toFixed(2)}
									</span></p></label>
								<label style={styles.labelstyle}><p style={{"display":"inline","fontWeight":"bold"}}>Total<span style={{"float":"right"}}>{window.rupeeSym + unconfirmedGrandTotal.toFixed(2)}</span></p></label>
							</Card>
							<ButtonGroup>
								<Button style={{...styles.GuestModalButtonstyle, backgroundColor : "#6abacd"}} onClick={() => this.setState({dialogOpen : true})}>{"Purchase Tickets"}</Button>
							</ButtonGroup>
							
					</div>
          )
      }
}

const AppPackageCart = withTheme(PackageCart);
export default AppPackageCart;