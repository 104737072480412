export default class ItemCustomization_Controller {

      static submit(key, value) {
        this.choices[key] = value;
        let count = 0;
        for (let eachkey in this.choices) {
            console.log('KEY --- ', eachkey);
            let eachvalue = this.choices[eachkey];
            if (typeof eachvalue === 'string') {
                eachvalue = [eachvalue];
            }
            count += eachvalue.length;
        }
        console.log(count);
        this.setState({numSelectedChoices : count});
      }
    
  static submitCustomizations() {
    this.props.context.clearOrder();

    if (this.props.context && this.props.context.unconfirmedCount === 0) {
      this.props.context.setContextState({
        selectedDeliverySlot: null,
      });
      window.removeCookie('selecedDeliverySlot');
    }
    const slotInStorage = this.props.context.selectedDeliverySlot;
    if (this.props.restdata.type === 'homechef') {
      if (slotInStorage && slotInStorage !== 'null') {
        if (slotInStorage !== this.props.context.activeDeliverySlot) {
          this.props.setSlotError(true);
          return;
        }
      } else {
        this.props.context.setContextState({
          selectedDeliverySlot:
            this.props.context.activeDeliverySlot,
        });
        window.setCookie(
          'selectedDeliverySlot',
          this.props.context.activeDeliverySlot
        );
      }
    }
    var choices = [];
    var choiceTotal = 0.0;
    var isHappyHr = (this.props.itemdata.isHappyHr || this.props.restdata.isHappyHr);
    Object.keys(this.choices).forEach(key => {
      var value = this.choices[key];
      if (typeof value === "string") {
        value = [value];
      }
      choices.push.apply(choices, value);
      value.forEach(v => {
        if (isHappyHr && v.special_price) {
          choiceTotal += v.special_price;
        } else {
          choiceTotal += v.price;
        }
      });
    });
    var price = isHappyHr && this.props.itemdata.special_price ? this.props.itemdata.special_price : this.props.itemdata.price;
    var data = {
      item_id: this.props.itemdata.item_id,
      options: choices,
      quantity: this.state.quantity,
      itemdata: this.props.itemdata,
      item_total: price + choiceTotal,
      restID: this.props.itemdata.menu,
      id: this.props.context.unconfirmedOrders.length + 1
    };

    this.props.context.addOrders(data);
    this.props.context.submitOrder(null, this.props.context.unconfirmedCreditsUsed);

    this.props.optionsClose();
  }
    
      static onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
      }
}