import React from "react";
// import Login_IN from "./Login_IN";
import Login_US from "./Login_US";

import Login_IN from "../v2/views/RestaurantLoginPage/RestaurantLoginControllerIN";

// const Login = process.env.REACT_APP_COUNTRY === "IN" ? Login_IN : Login_US;

export default ({ background, header, afterLogin, skip }) => {
  if (process.env.REACT_APP_COUNTRY === "IN") {
    return (
      <Login_IN
        background={background}
        header={header}
        afterLogin={afterLogin}
        skip={skip}
      />
    );
  } else {
    return (
      <Login_US
        background={background}
        header={header}
        afterLogin={afterLogin}
        skip={skip}
      />
    );
  }
}
