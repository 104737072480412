import axios from "axios";
import cogoToast from "cogo-toast";
import logo from "../../img/logo_2.png";

export default class RazorpayCheckout_Controller {

    static isEmail() {
		if (this.state.email) {
			var emailMatch = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
			return this.state.email.match(emailMatch);
		} else {
			return false;
		}
	}

	static async requestOrder() {
		try {
			// if (!this.props.context.orderID) {
			// 	await this.props.context.createOrder();
			// }

			const response = await axios({
				method : 'POST',
				url : process.env.REACT_APP_BACKEND_URL + "/razorpay/createorder",
				headers : {"foodbots-header" : this.props.context.token},
				data : {
					total : this.props.total,
					order_id : this.props.context.orderID,
				}
			})
			// console.log(response.data);
			this.setState({orderID : response.data.order_id, accountID : response.data.account_id});
			return new Promise((resolve, reject) => {
				return resolve(response.data);
			});
		} catch (e) {
			console.log(e);
			// alert("Error fetching payment info");
			this.props.context.popup("Error fetching payment info", "error")
			return new Promise((resolve, reject) => {
				return reject(e);
			});
		}
	}

	static onLoad() {
		if (
			this.props.context &&
			this.props.context.isUserDone &&
			this.props.context.user.first_name &&
			this.props.context.user.email && this.props.context.isRestDone && this.props.context.isOrderDone
		) {
			this.setState({ scriptLoaded: true });
			//console.log(data);
			if(this.props.context.restdata.online_payments && this.props.context.restdata.offline_payments) {
				//Do nothing
				this.setState({processing : false});
				
			} else if(this.props.context.restdata.online_payments) {
				this.setState({processing : true});
				if(this.props.total >= 1) {
					this.onClick();
				} else {
					this.handleResponse(null);
				}
			} else if(this.props.context.restdata.offline_payments) {
				this.setState({processing : true});
				if(this.props.total >= 1) {
					this.props.payAtRestaurant();
				} else {
					this.handleResponse(null);
				}
			} else {
				this.setState({processing : false});
			}
		} else {
			this.setState({processing : false});
		}
	}

	static async updateUser() {
		if (this.props.context && this.props.context.user) {
			var url = window.kumbaURL + "/profile/updateuser";
			let header = { "foodbots-header": this.props.context.token };
			try {
				const response = await axios({
					url,
					headers: header,
					method: "PUT",
					data: {
						// first_name: this.state.name,
						email: this.state.email
					}
				});

				return await this.props.context.getUser();
			} catch (err) {
				// alert("Couldn't place the order. Please try again");
				this.props.context.popup("Couldn't place the order. Please try again", "error")
			}
		}
	}

	//Confirms orders and redirects to payment link
	static async verifyAndCaptureCharge(response, total) {
		if(response.razorpay_payment_id) {
			var orders = this.props.orders || [];
			var data = orders.length > 0 ? orders.map(x => x.id) : [];
			var packages = this.props.packages || [];
			var packageData = packages.length > 0 ? packages.map(x => x.id) : [];
			var type = this.props.context && this.props.context.tabledata && this.props.context.tabledata.payAtEnd ? "postpay" : "prepay";
			this.props.closeDialog();

			let url = window.kumbaURL + "/razorpay/verify";
			let header = { "foodbots-header": this.props.context.token };
			const hide = cogoToast.loading("Awaiting Payment Confirmation", {
			hideAfter: 0
			});
			try {
				const respdata = await axios({
					url,
					headers: header,
					method: "PUT",
					data: {
						order_id: this.props.context.orderID,
						orders: data,
						packages : packageData,
						total: total,
						payment_type : type,
						rzp_data : response,
						paid_for : orders.length > 0 ? "orders" : packages.length > 0 ? "packages" : null
					}
				});
				hide();
				cogoToast.success("Verified Payment", { hideAfter: 2 });
				return new Promise((resolve, reject) => {
					return resolve(respdata.data);
				});
			} catch (err) {
				hide();
				cogoToast.error("Couldn't verify payment", { hideAfter: 2 });
				return new Promise((resolve, reject) => {
					return reject(err);
				});
			}
		} else {
			return new Promise((resolve, reject) => {
				return resolve(null);
			});
		}
	}

	static async chargeAndConfirm(response, total) {
		try {
		  this.props.closeDialog();
		  var data = await this.verifyAndCaptureCharge(response, total);
		  var rzpid = data && data.createdEntry ? data.createdEntry.entry_id : null;
		  await this.props.submitOrder(rzpid, this.props.creditsUsed);
		} catch (e) {
		  console.log(e);
		}
	}

	static async chargeAndCheckout(response, total) {
		try {
			this.props.closeDialog();
			var data = await this.verifyAndCaptureCharge(response, total);
			var rzpid = data && data.createdEntry ? data.createdEntry.entry_id : null;
			await this.props.checkoutOrder(rzpid, this.props.creditsUsed);
		} catch (e) {
			console.log(e);
		}
	}

	static async handleResponse(response) {
		var payment_id = response ? response.razorpay_payment_id : null;
		var total = this.props.total > 0 ? this.props.total : 0.00;
		switch(this.props.onFinish) {
			case "chargeAndCheckout" :
				await this.chargeAndCheckout(response, parseInt(total * 100));
				break;

			case "chargeAndConfirm" :
				await this.chargeAndConfirm(response, parseInt(total * 100));
				break;

			default :
				break;
		}
	}

	static async onClick() {
		if (this.props.context && this.props.context.isUserDone) {
			if (
				!this.props.context.user.email ||
				this.props.context.user.email !== this.state.email ||
				!this.props.context.user.first_name ||
				this.props.context.user.first_name !== this.state.name
			) {
				await this.updateUser();
			}
		}

		const data = await this.requestOrder();

		var options = {
			key: process.env.REACT_APP_RAZORPAY_KEY_ID,
			amount: parseInt(this.props.total * 100), // 2000 paise = INR 20
			name: "Food Bots",
			description: "Payment for order #" + this.props.context.orderID,
			image: logo,
			order_id : this.state.orderID,
			account_id : this.state.accountID,
			handler: function(response) {
				this.handleResponse(response);
			}.bind(this),
			prefill: {
				name: this.state.name,
				email: this.state.email,
				contact: this.state.phone,
			},
			notes: {
				order: this.props.context.orderID,
				user: this.props.context.user.user_id,
				unconfirmedOrders: JSON.stringify(this.props.context.unconfirmedOrders)
			},
			theme: {
				color: "#7ac1d1"
			}
		};

		// console.log(options);

		var rzp = window.Razorpay(options);
		rzp.open();
	}

}