import React, { useState, useEffect } from "react";
import Axios from "axios";
import { withTheme } from "../../../contexts/AppContext";
import "../../css/receipt.css";
import { ReactComponent as ArrowBack } from "../../images/arrowback.svg";
import { ReactComponent as Location } from "../../images/rlocation.svg";
import { ReactComponent as Phone } from "../../images/rphone.svg";
import KbReceiptDelivery from "../../components/KbReceiptDelivery/KbReceiptDelivery";
import KbReceiptDinein from "../../components/KbReceiptDinein/KbReceiptDinein";
import KbReceiptTakeaway from "../../components/KbReceiptTakeaway/KbReceiptTakeaway";
import Login from "../../../signUp/Login";
import AppLoading from "../../../components/AppLoading";
import Icon from "react-icons-kit";
import { phone } from "react-icons-kit/icomoon/phone";
import { location } from "react-icons-kit/icomoon/location";
import { Redirect } from "react-router";

const RestaurantReceiptPage = ({ context, match, history }) => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [restID, setRestID] = useState(null);
  // const [orderdata, setOrderdata] = useState(null);
  const [timelineView, setTimelineView] = useState(true);

  const  getOrders = async () => {
      try{
        // console.log("CONTEXT ---", context);
        const { orderID } = match.params;
        // context.setContextState({ orderID });
        // let orderdata = await context.getOrder();
        let url = window.kumbaURL + "/mainorders/sorted/" + orderID;
        let header = { "foodbots-header": context.token };
        let res = await Axios(url, {
        headers: header,
        });
        let orderdata = res.data;
            //setLoaded(true);
            setData(orderdata);
            context.setContextState({
                restID: orderdata.rest.restaurant_id,
                //orderdata: orderdata,
            });
            setRestID(orderdata.rest.restaurant_id);
            setTimelineView(orderdata.order_type !== "dine-in");
            await context.loadRestaurantDetails();
            setLoaded(true);
            return Promise.resolve();
      } catch(err) {
        setLoaded(false);
        return Promise.reject(err);
      };
  };

  const [goMain, setGoMain] = useState(false);

//   useEffect(() => {
//     setData(context.orderdata);
//   }, [context.orderdata]);

  const { orderID } = match.params;

    useEffect(() => {
        getOrders();
    }, [context.token, orderID]);

  if (loaded) {
    // console.log({ data, context });
  }

  if(goMain) {
      return(<Redirect push to="/main" />);
  } else if (context && context.user) {
    if (loaded && data) {
      const { rest, reserved_time, delivery_address, payment_mode } = data;
      const {restdata} = context;

      const restFullLocation = restdata.address ? (restdata.address.apt ? restdata.address.apt + ',' : '') + restdata.address.street : null;
      const restPlaceID = restdata.address && restdata.address.google_places_id ? restdata.address.google_placed_id : null;
      
      const restLocationURL = restdata.address ? encodeURI(`https://www.google.com/maps/search/?api=1&query=${restFullLocation}&query_place_id=${restPlaceID}`) : null;

      return (
        <div className="kb-receipt-page">
          <header className="kb-receipt-header">
            <div className="kb-receipt-header-arrow" onClick={() => setGoMain(true)}>
              <ArrowBack />
            </div>
            <h2 className="kb-receipt-header-text">Order Details</h2>
          </header>

          <main className="kb-receipt-main">
            <div
              className="kb-receipt-top"
              style={{ backgroundColor: context.highlightColor }}
            >
              <div className="kb-receipt-top-left">
                <p
                  className="kb-receipt-top-head"
                  style={{ color: context.textColor }}
                >
                  {restdata.name}
                </p>
                <p
                  className="kb-receipt-top-sub"
                  style={{ color: context.textColor }}
                >
                    {
                      restFullLocation
                    }
                </p>
              </div>
              <div className="kb-receipt-top-right">
                  {restLocationURL ?
                <button
                  className="kb-receipt-top-right-btn-left"
                  style={{ backgroundColor: window.themeColor, color: window.svgTextColor }}
                >
                  <a href={restLocationURL} target='_blank' style={{color: window.svgTextColor}}>
                    <Icon size={"100%"} icon={location} />
                  </a>
                </button> : null}
                <button
                  className="kb-receipt-top-right-btn-right"
                  style={{ backgroundColor: window.themeColor, color: window.svgTextColor }}
                >
                  <a href={`tel:${restdata.phone}`} style={{color: window.svgTextColor}}>
                    <Icon size={"100%"} icon={phone} />
                  </a>
                </button>
              </div>
            </div>

            <div className="kb-receipt-top-details">
              <p className="kb-receipt-top-details-text">{"Order ID #" + orderID}</p>
              <p className="kb-receipt-top-details-text">{new Date(`${reserved_time}`).toDateString()}.{" "}
                {new Date(`${reserved_time}`).toLocaleTimeString()}</p>

              {data.order_type === "delivery" && delivery_address ?
                <>
                <p className="kb-receipt-top-details-heading">Delivered to</p>
                <p className="kb-receipt-top-details-text">
                    {(delivery_address.apt || "") + "," + delivery_address.street}
                </p>
                </>: null
              }

              <p className="kb-receipt-top-details-heading">Payment Method</p>
              <p className="kb-receipt-top-details-text"
              style={{ textTransform: "uppercase" }}>{payment_mode}</p>
            </div>

            <hr className="kb-receipt-line" />

            <div className="kb-receipt-option">
              {timelineView ? 
                data ? (
                    data.order_type === "delivery" ? (
                    <KbReceiptDelivery data={data} />
                    ) : data.order_type === "takeaway" ? (
                    <KbReceiptTakeaway data={data} />
                    ) : data.order_type === "dine-in" ? (
                    <KbReceiptDinein data={data} context={context} />
                    ) : null
              ) : null : <KbReceiptDinein data={data} context={context} />}
            </div>
          </main>

          <footer className="kb-receipt-footer">
            {data ? (
              data.order_type === "delivery" || data.order_type === "takeaway" ? (
                <>
                  <button className="kb-receipt-footer-btn" style={{backgroundColor : window.themeColor}}
                  onClick={() => setTimelineView(!timelineView)}>
                      {timelineView ? "View Order Summary" : "View Status"}
                  </button>
                  <button className="kb-receipt-footer-btn mt-4" style={{backgroundColor : window.themeColor}}
                  onClick={() => history.push("/feedback")}>
                    Leave Feedback
                  </button>
                </>
              ) : data.order_type === "dine-in" ? (
                <>
                  <button className="kb-receipt-footer-btn" style={{backgroundColor : window.themeColor}}
                  onClick={() => history.push("/feedback")}>
                    Leave Feedback
                  </button>
                  <button className="kb-receipt-footer-btn mt-4" style={{backgroundColor : window.themeColor}}
                  onClick={() => setGoMain(true)}>Back to Main</button>
                </>
              ) : null
            ) : null}
          </footer>
        </div>
      );
    } else {
      return <AppLoading />;
    }
  } else {
    return <Login afterLogin={() => setIsLoggedIn(true)} />;
  }
};

const themedRestaurantReceiptPage = withTheme(RestaurantReceiptPage);
export default themedRestaurantReceiptPage;
