import axios from "axios";
import cogoToast from "cogo-toast";

export default class PayTmCheckout_Controller {
    static isEmail() {
        if (this.state.email) {
            var emailMatch = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
            return this.state.email.match(emailMatch);
        } else {
            return false;
        }
    }

    static async updateUser() {
        // console.log('UPDATING USER FROM PAYU ---', this.state)
        if (this.props.context && this.props.context.user) {
            var url = window.kumbaURL + '/profile/updateuser';
            let header = { 'foodbots-header': this.props.context.token };
            try {
                const response = await axios({
                    url,
                    headers: header,
                    method: 'PUT',
                    data: {
                        // first_name: this.state.name,
                        email: this.state.email,
                    },
                });

                return await this.props.context.getUser();
            } catch (err) {
                // alert("Couldn't place the order. Please try again");
                this.props.context.popup(
                    "Couldn't place the order.Please try again",
                    'error'
                );
            }
        }
    }

    static async processPayment(paydata) {
        try {
            let status = await this.confirmPayment(paydata);
            console.log('Reached Here 00105555', status);

            let rzpid = paydata['txnId'];
            let creditsUsed = 0.0;

            if (status === 'paid') {
                if (this.props.context.selectedMenuType === 'dine-in') {
                    //alert("Checkout Order");
                    console.log(
                        'Reached Here 00105556 Checkout Order',
                        this.props.context.orderID
                    );
                    await this.checkoutOrder(rzpid, creditsUsed);
                    this.props.navigation.navigate('CategoryList');
                    return Promise.resolve();
                } else {
                    //alert('Confirm Order');
                    console.log(
                        'Reached Here 00106666 Confirm Order',
                        this.props.context.orderID
                    );
                    await this.confirmOrder(rzpid, creditsUsed);
                    this.props.navigation.navigate('CategoryList');

                    return Promise.resolve();
                }
            } else {
                // console.log(
                //     'Reached Here 00105556 Cancel Order'
                // );
                await this.cancelOrder();
                // console.log('Reached Here 00177776 Cancel Order');
                this.props.navigation.navigate('CategoryList');
                return Promise.resolve();
            }
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    }

    static async confirmPayment(paydata) {
        try {
            console.log(
                'Verifying payment',
                paydata,
                this.props.context.orderID
            );
            if (this.props.context && this.props.context.orderID) {
                let verifyResponse = await axios({
                    method: 'POST',
                    url: window.kumbaURL + '/paytm/verify',
                    data: paydata,
                });

                let response = await axios({
                    url:
                        window.kumbaURL +
                        '/payments/confirm/' +
                        this.props.context.orderID,
                    method: 'GET',
                    headers: { 'foodbots-header': this.props.context.token },
                });

                let { data } = response;
                // if(data.status === "paid") {
                return Promise.resolve(data.status);
            } else {
                return Promise.reject('No orderID found');
            }
        } catch (e) {
            return Promise.reject(e);
        }
    }

    static async onClick() {
        try {
            if (this.props.context && this.props.context.isUserDone) {
                if (
                    !this.props.context.user.email ||
                    this.props.context.user.email !== this.state.email ||
                    !this.props.context.user.first_name ||
                    this.props.context.user.first_name !== this.state.name
                ) {
                    await this.updateUser();
                }
            }

            let orderdata = null;

            if (
                this.props.context &&
                this.props.context.selectedMenuType &&
                this.props.context.selectedMenuType !== 'dine-in'
            ) {
                orderdata = await this.props.context.submitOrder(
                    null,
                    0.0,
                    this.props.instructions
                    // customer_order_time:
                );
            }

            let orderID = orderdata
                ? orderdata.orderID
                : this.props.context.orderID;

            let utl = window.kumbaURL + '/paytm/initiate';
            let response = await axios({
                url: utl,
                method: 'POST',
                headers: {
                    'foodbots-header': this.props.context.token,
                    //'content-type': 'multipart/form-data'
                },
                data: {
                    order_id: orderID,
                    rest_id: this.props.context.restID,
                    mode: 'web',
                },
            });

            let { txnResponse, amount, callbackURL, payID } = response.data;


            let config = {
                root: '',
                flow: 'DEFAULT',
                data: {
                    orderId: payID /* update order id */,
                    token: txnResponse.body.txnToken /* update token value */,
                    tokenType: 'TXN_TOKEN',
                    amount: amount + '' /* update amount */,
                },
                merchant: {
                    name: 'Kumba',
                    redirect: true,
                },
                handler: {
                    notifyMerchant: function (eventName, data) {
                        if(eventName === "APP_CLOSED") {
                                setTimeout(
                                    function () {
                                        this.props.context.setContextState({
                                            paymentLoading: false,
                                            paymentCancelled : true
                                        });
                                        // this.setState({ goCheckout: true });
                                    }.bind(this),
                                    3000
                                );
                        }
                        console.log('notifyMerchant handler function called');
                        console.log('eventName => ', eventName);
                        console.log('data => ', data);
                    }.bind(this)
                    // transactionStatus: (paydata) =>
                    //     this.processPayment(paydata),
                    // payMode: {
                    //     order: ['UPI'],
                    // },
                },
            };

            await window.Paytm.CheckoutJS.init(config);
            window.Paytm.CheckoutJS.invoke();
            if(response.data){
                this.props.context.setContextState({
                  paymentLoading: true
                });
            }
        } catch (e) {
            console.log(e);
        }
    }

    static onLoad() {
        this.setState({ scriptLoaded: true });
    }

    static async checkoutOrder(rzpid, creditsUsed) {
        try {
            if (this.props.context && this.props.context.orderID) {
                //this.closeCheckoutDialog();
                const hide = cogoToast.loading('Processing', {
                    hideAfter: 0,
                });
                const data = await this.props.context.checkoutOrder(
                    this.props.context.orderID,
                    creditsUsed,
                    rzpid
                );
                const userdata = await this.props.context.getUser();
                hide();
                cogoToast.success('Order checked out successfully.', {
                    hideAfter: 2,
                });
                return Promise.resolve(data);
            } else {
                this.props.context.clearData();
                this.props.context.clearOrderData();
                cogoToast.success('Order cleared successfully.', {
                    hideAfter: 2,
                });
                return Promise.reolve(null);
            }
        } catch (er) {
            // console.log("ERROR = ", er);
            cogoToast.error("Couldn't checkout the order", { hideAfter: 2 });
            return Promise.reject(er);
        }
    }

    static async confirmOrder(rzpid, creditsUsed) {
        try {
            return await this.props.context.confirmOrder(
                this.props.context.orderID,
                creditsUsed,
                rzpid
            );
        } catch (e) {
            // this.props.context.popup('Failed confirming order', 'error');
            return Promise.reject(e);
        }
    }

    static async cancelOrder() {
        try {
            return await this.props.context.cancelOrder(
                this.props.context.orderID
            );
        } catch (e) {
            // this.props.context.popup('Failed confirming order', 'error');
            return Promise.reject(e);
        }
    }
}
