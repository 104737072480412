import React from "react";
import "./KbReceiptDinein.css";
import OrderTotal from "../../../components/OrderTotal";
import KbOrderTotal from "../KbOrderTotal/KbOrderTotal";
// import itemImg from '../../images/receipt-item.png';

const KbReceiptDinein = ({data, context}) => {
    //const arr = [1, 2];
  return (
    <div className="kb-receipt-dinein-container">
      <p className="kb-receipt-dinein-head">ITEMS</p>
      {data.orders.filter(x => x.status !== "unconfirmed" && x.status !== "rejected" && x.status !== "cancelled").map((item, index) => (
        <div
          className="kb-receipt-dinein-item"
          key={index}
          style={{ marginTop: index !== 0 && "1rem" }}
        >
          <div className="kb-receipt-dinein-item-left">
            <div className="kb-receipt-dinein-item-left-img-div">
            {item.item_id && item.item_id.image ?
            <img
                src={item.item_id.image}
                alt={`${item.item_id.name}`}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              /> : null 
              }
            </div>
            <p className="kb-receipt-dinein-item-left-text">
              {item.item_id.name} x {item.quantity}
            </p>
          </div>
          <div className="kb-receipt-dinein-item-right">
            <p className="kb-receipt-dinein-item-right-text">{`${
              window.rupeeSym
            } ${item.item_total.toFixed(2)}`}</p>
          </div>
        </div>
      ))}

      <hr className="kb-receipt-dinein-line" />

      <div
        className="kb-receipt-dinein-order-details"
        style={{ fontWeight: "500" }}
      >
        {/* <div className="kb-receipt-dinein-subtotal">
          <p className="kb-receipt-dinein-subtotal-left">Subtotal</p>
          <p className="kb-receipt-dinein-subtotal-right">₹250</p>
        </div>
        <div
          className="kb-receipt-dinein-delivery"
          style={{ fontWeight: "500" }}
        >
          <p className="kb-receipt-dinein-delivery-left">Delivery</p>
          <p className="kb-receipt-dinein-delivery-right">₹250</p>
        </div>
        <div className="kb-receipt-dinein-total" style={{ fontWeight: "600" }}>
          <p className="kb-receipt-dinein-total-left">Total</p>
          <p className="kb-receipt-dinein-total-right">₹250</p>
        </div> */}
        {data && <KbOrderTotal orderdata={data} context={context} />}
      </div>
      <hr className="kb-receipt-dinein-line" />
    </div>
  );
};

export default KbReceiptDinein;
