import React from 'react';
// import Script from "react-load-script";
import CustomBtn from "../../components/CustomBtn";
import { Card, Input, Button, InputGroup, InputGroupAddon } from "reactstrap";


const RazorpayCheckoutViews =  (props) => {
    const { parent } = props;
    return (
        <div>
            {parent.state.processing ? (
                <Card id="checkout-modal"
                    className='kb-razor-card' body>
                    <label
                        className='kb-razor-label'
                    >{"Processing .... "}</label>
                </Card>
            ) : (
                    <div id="checkout-modal"
                        className='kb-razor-card'
                        body>
                            <p>Are you sure you want to request bill ?</p>
                        <br />
                        {/* <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend">
                                <Button size="xs" style={{ border: "none" }}>
                                    {"Name"}
                                </Button>
                            </InputGroupAddon>
                            <Input
                                type="string"
                                style={{ textAlign: "center" }}
                                value={parent.state.name}
                                onChange={e => parent.setState({ name: e.target.value })}
                            />
                        </InputGroup>
                        <br /> */}
                        {/* <small>*Note : We won't ask for these details again*</small> */}
                        
                        {/* {parent.props.context.restdata.online_payments ? (
                            // 	<Button
                            // 	className='kb-razor-confirm-btn'
                            // 	disabled={!parent.state.scriptLoaded || !parent.isEmail()}
                            // 	onClick={parent.onClick.bind(parent)}
                            // >
                            // 	Pay Online
                            // </Button>
                            <CustomBtn
                                disabled={!parent.isEmail()}
                                customeClass="kb-btn"
                                customeStyle={{ borderRadius: 5, width: '100%' }}
                                onClick={parent.onClick.bind(parent)}
                                value={"Pay Online"}
                            />
                        ) : null} */}
                        {parent.props.context.restdata.offline_payments && !parent.props.context.isStatic ? <>
                            {
                                parent.props.context.selectedMenuType === 'takeaway' 
                                || parent.props.context.selectedMenuType === 'delivery' ? null :
                                    <CustomBtn
                                        customeClass="kb-btn mt-2"
                                        customeStyle={{ borderRadius: 5, width: '100%' }}
                                        // disabled={!parent.isEmail()}
                                        onClick={parent.props.payAtRestaurant}
                                        value={"Request Bill"}
                                    />
                            }

                        </> : null}
                        <CustomBtn
                            customeClass="kb-btn-2 mt-2"
                            customeStyle={{ borderRadius: 5, width: '100%' }}
                            onClick={parent.props.closeDialog}
                            value={"Cancel"}
                        />
                    </div>
                )
            }
        </div>
    )
}

export default RazorpayCheckoutViews;
