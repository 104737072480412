export default class ProcessOrder_Controller {
  static async changeTable(orderID, oldTable, newTable) {
    await this.props.context.changeTableDetails(orderID, oldTable, newTable);
    //this.props.closeDialog();
  }

  static async updateTable(orderID, tableID, restID, clearOrder) {
    if (clearOrder) {
      this.props.context.clearOrder();
    }
    await this.props.context.updateTable(orderID, tableID, restID);
    this.redirectUser();
  }

  static async checkoutOrder(orderID) {
    await this.props.context.checkoutOrder(orderID, 0.0);
    this.redirectUser();
    //this.setState({goRestaurants : true});
    //this.props.closeDialog();
  }

  static async addPersonToOrder() {
    await this.props.context.addPersonToOrder(
      this.props.context.refOrder,
      this.props.context.refUser
    );
    this.redirectUser();
    //this.setState({goRestaurants : true});
    //this.props.closeDialog();
  }

  static redirectUser() {
    if (this.props.context.unconfirmedCount > 0) {
      this.setState({ goCart: true });
    } else {
      this.setState({ goMain: true });
    }
  }
}
