import React from 'react';
// import loadingpng from "../img/loading.gif";
import { withTheme } from '../contexts/AppContext';
import { Spinner } from 'react-activity';

export default withTheme((props) => {
    return (
        <div
            style={{ width: "100%", textAlign: "center", marginTop: "50%" }}
        >
            {/* <img
                style={{ width: "20%" }}
                src={loadingpng}
                alt="loading-png"
            /> */}
            <Spinner color={props.context.themeColor} size={30}/>
        </div>
    )
});

