import React, { useState, useEffect } from "react";
import Icon from "react-icons-kit";
import { ic_add } from "react-icons-kit/md/ic_add";
import { ic_remove } from "react-icons-kit/md/ic_remove";
import "./KbItemCustomization.css";
import { useHistory } from 'react-router-dom'

import KbChoiceFieldController from "../KbChoiceField/KbChoiceFieldController";

const KbItemCustomization = ({ parent, customs, allowOrder, numSelectedChoices }) => {
  const { context, itemdata: { price } } = parent.props;


  const [itemPrice, setItemPrice] = useState(0);
  const [itemPriceRadio, setItemPriceRadio] = useState(0);
  const [itemPriceCheck, setItemPriceCheck] = useState(0);


  const increasePrice = (amount) => {
    setItemPriceCheck((prevPrice) => Number(prevPrice) + Number(amount));
  };

  const decreasePrice = (amount) => {
    setItemPriceCheck((prevPrice) => Number(prevPrice) - Number(amount));
  };

  const updatePrice = (amount) => {
    setItemPriceRadio((prevPrice) => {
        let newPrice = Number(prevPrice) + Number(amount);
        console.log(newPrice);
        return newPrice;
    });
  };

  useEffect(() => {
    console.log({ itemPriceCheck });
    console.log({ itemPriceRadio });
    setItemPrice((Number(itemPriceRadio) + Number(itemPriceCheck) + price)*parent.state.quantity);
  }, [itemPriceCheck, itemPriceRadio, parent.state.quantity]);

  return (
      <div>
          <div className="kb-customization-choice-group">
              {customs.length > 0
                  ? customs.sort((a,b) => a.orderNum - b.orderNum).map((custom) => (
                        <KbChoiceFieldController
                            customization={custom}
                            itemdata={parent.props.itemdata}
                            key={custom.id}
                            submit={parent.submit.bind(parent)}
                            isHappyHr={parent.props.isHappyHr}
                            menuTable={parent.props.menuTable}
                            itemKey={custom.id}
                            parent={parent}
                            increasePrice={increasePrice}
                            decreasePrice={decreasePrice}
                            itemPriceRadio={itemPriceRadio}
                            custom={custom}
                            numSelectedChoices={numSelectedChoices}
                            choices={parent.choices}
                            updatePrice={updatePrice}
                            setItemPriceRadio={setItemPriceRadio}
                        />
                    ))
                  : null}
          </div>

          {parent.props.restdata.isOpen ? (
              parent.props.itemdata.isOutOfStock ||
              (parent.props.context.selectedMenuType === 'dine-in' &&
                  !parent.props.context.tableID) ? null : (
                  <div
                      className="kb-customization-btn-group"
                      style={{
                          borderBottom: `1px solid ${parent.props.context.themeColor}`,
                          backgroundColor : window.highlightColor
                      }}
                  >
                      <button
                          className="kb-customization-btn"
                          onClick={() => {
                              if (parent.state.quantity > 1) {
                                  parent.setState({
                                      quantity: parent.state.quantity - 1,
                                  });
                                  decreasePrice(parent.props.itemdata.price);
                              }
                          }}
                      >
                          <Icon icon={ic_remove} size={'100%'} />
                      </button>
                      <p className="kb-customization-qty">
                          {parent.state.quantity}
                      </p>
                      <button
                          className="kb-customization-btn"
                          onClick={() => {
                              parent.setState({
                                  quantity: parent.state.quantity + 1,
                              });
                              increasePrice(parent.props.itemdata.price);
                          }}
                      >
                          <Icon icon={ic_add} size={'100%'} />
                      </button>
                  </div>
              )
          ) : null}

          {/* <div className="kb-customization-total-container" style={{backgroundColor : window.highlightColor}}>
              <p className="kb-customization-total-text">Item Total</p>
              <p className="kb-customization-total-price" style={{color : window.textColor}}>
                  {window.rupeeSym + itemPrice}
              </p>
          </div> */}

          <div className="kb-customization-bottom-btns">
              {parent.props.menuTypeMatches() ? (
                  parent.props.itemdata.isOutOfStock ||
                  (parent.props.context.selectedMenuType === 'dine-in' &&
                      !parent.props.context.tableID) || !parent.props.context.permissionToOrder || parent.props.context.waitingForApproval ? null : (
                      <button
                          className="kb-customization-bottom-add"
                          onClick={parent.submitCustomizations.bind(parent)}
                          style={{
                              backgroundColor: parent.props.context.themeColor
                          }}
                          disabled={!allowOrder}
                      >
                          Add Item
                      </button>
                  )
              ) : null}

              <button
                  className="kb-customization-bottom-cancel"
                  onClick={parent.props.optionsClose}
                // onClick={() => history.goBack()}
              >
                  Cancel
              </button>
          </div>
      </div>
  );
};

export default KbItemCustomization;
