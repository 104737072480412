import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { Button, Modal, ModalBody } from 'reactstrap';
// import Login_IN from '../signUp/Login_IN';
// import Login_US from '../signup/Login_US';
import { withTheme } from '../contexts/AppContext';
import Login from "../signUp/Login";
import CustomBtn from "./CustomBtn";
import { Icon } from 'react-icons-kit'
import { ic_close } from 'react-icons-kit/md/ic_close'

const LoginPopup = ({
    show,
    toggle,
    afterLogin,
    context,
    skip
}) => {
    const [redirect, setRedirect] = useState(false)
    // console.log('Popup context ---', context);
    const handleClose = () => {
        context.loginFromPopup(context.loginRedirect)
        setRedirect(true)
    }

    // useEffect(() => {
    //     console.log(`%c LOGIN POPUP IS ${context.showLogin}`, 'color: red; font-size: 20px');
    // },[context, context.user])

    if (redirect) {
        return <Redirect to={context.loginRedirect} />
    } else {
        return (
            <Modal style={{ width: '90%', backgroundColor : window.highlightColor}} isOpen={context.showLogin && context.loginRedirect !== null} toggle={toggle}>
                {/* <Icon onClick={handleClose} icon={ic_close} size={30} className='p-2 d-flex justify-content-end' /> */}
                <ModalBody className='m-0' style={{backgroundColor : window.highlightColor}}>
                    {
                        context.user ? handleClose() : (
                            <div>
                                <Login afterLogin={afterLogin} />
                                <br />
                                {context && context.restdata && context.restdata.skip_customer_login ? <>
                                <hr />
                                <p className="text-right mr-2 fw-bold" style={{color : window.themeColor}} onClick={handleClose}>{`Skip >>`}</p> </> : null}
                            </div>
                        )
                    }
                    {/* {
                        context.user ? <div className='text-center mt-5 mb-5'>
                            <h5>You Are Now Logged In</h5>
                            <CustomBtn
                                value='Close'
                                onClick={handleClose}
                                customeClass='w-100 mt-4'
                            />
                        </div> :
                            <div>
                                <Login afterLogin={afterLogin} />
                                <hr />
                                <p className="text-right" onClick={handleClose}><u>Skip >></u></p>
                            </div>
                    } */}
                </ModalBody>
            </Modal>
        )
    }
}

const loginPopup = withTheme(LoginPopup);
export default loginPopup;
