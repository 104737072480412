import React from "react";

export default class BackLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
    };
  }

  render() {
    if (this.state.isError) {
      return (
        <div>
          {this.props.title ? (
            <p
              style={{
                position: "absolute",
                left: "33%",
                top: "-20%",
                width: "150px",
                background: "transparent",
                maxHeight: "100px",
                verticalAlign: "center",
                alignContent: "center",
              }}
            >
              {this.props.title}
            </p>
          ) : null}
        </div>
      );
    } else {
      if (this.props.smallSize) {
        if (this.props.onHeader) {
          return (
            <div
              style={{
                position: "absolute",
                left: "38%",
                top: "4px",
                width: "100px",
                background: "transparent",
                maxHeight: "70px",
                verticalAlign: "center",
                alignContent: "center",
              }}
            >
              <img
                src={
                  process.env.REACT_APP_BACKEND_URL +
                  "/image/logos/" +
                  this.props.restID
                }
                style={{ width: "inherit", objectFit: "scale-down" }}
                onError={() => this.setState({ isError: true })}
              />
            </div>
          );
        } else {
          return (
            <div
              style={{
                position: "absolute",
                left: "33%",
                top: "-20%",
                width: "150px",
                background: "transparent",
                maxHeight: "100px",
                verticalAlign: "center",
                alignContent: "center",
              }}
            >
              <img
                src={
                  process.env.REACT_APP_BACKEND_URL +
                  "/image/logos/" +
                  this.props.restID
                }
                style={{ width: "inherit", objectFit: "scale-down" }}
                onError={() => this.setState({ isError: true })}
              />
            </div>
          );
        }
      } else {
        return (
          <div
            style={{
              position: "fixed",
              left: 0,
              top: "40%",
              width: "100%",
              background: "transparent",
              maxHeight: "100px",
              verticalAlign: "center",
              alignContent: "center",
            }}
          >
            <img
              src={
                process.env.REACT_APP_BACKEND_URL +
                "/image/logos/" +
                this.props.restID
              }
              style={{
                width: "inherit",
                objectFit: "scale-down",
                opacity: "0.5",
              }}
              onError={() => this.setState({ isError: true })}
            />
          </div>
        );
      }
    }
  }
}
