import React, { useState } from "react";
import { Form, Input, Modal } from "reactstrap";
import { withTheme } from "../contexts/AppContext";

import "../css/Header.css";
import { Icon } from "react-icons-kit";
import { menu, arrowLeft2 } from "react-icons-kit/icomoon";
// import rightCurve from '../img/top_right_curve.svg';
import offersIcon from "../img/offers_icon.svg";
import Sidemenu from "../mainPage/Sidemenu";
import DrawerMenu from "./DrawerMenu";
import Header_Controller from "../controllers/components/Header.controller";
import BackLogo from "../helpers/BackLogo";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.changeSideBar = Header_Controller.changeSideBar.bind(this);

    this.state = {
      sideBarOpen: false,
      refresh: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.context &&
      prevProps.context.user &&
      prevProps.context.user.user_id &&
      prevProps.context.confirmedCount[prevProps.context.user.user_id] &&
      this.props.context &&
      this.props.context.user &&
      this.props.context.user.user_id &&
      this.props.context.confirmedCount[this.props.context.user.user_id] &&
      prevProps.context.confirmedCount[prevProps.context.user.user_id] !==
        this.props.context.confirmedCount[this.props.context.user.user_id]
    ) {
      this.setState({ refresh: !this.state.rerfresh });
    }
  }

  render() {
    const {
      title,
      onChange,
      context,
      goBack,
      type,
      headerAction,
      current,
    } = this.props;

    return (
      <div
        className="kb-navbar rounded"
        style={{
          height: current === "menu" ? "120px" : "90px",
          border: "0px",
          backgroundColor:
            current === "restaurantmain"
              ? "transparent"
              : context.backgroundColor,
        }}
      >
        <Modal
          isOpen={this.state.sideBarOpen}
          size="lg"
          style={{
            overflow: "auto",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            position: "fixed",
            zIndex: 119,
            margin: 0,
            backgroundColor: context.backgroundColor,
          }}
          aria-labelledby="contained-modal-title-lg"
        >
          <div
            style={{
              left: 0,
              top: 0,
              bottom: 0,
              position: "fixed",
              width: "100%",
              zIndex: 120,
            }}
          >
            <Sidemenu
              sidebarClose={() => this.setState({ sideBarOpen: false })}
            />
          </div>
        </Modal>

        <div className="kb-navbar-heading">
          <div className=" pl-0 ml-0">
            {goBack ? (
              <Icon
                onClick={() => goBack()}
                icon={arrowLeft2}
                size={32}
                style={{ color: context.themeColor }}
              />
            ) : (
              <Icon
                onClick={() =>
                  this.setState({ sideBarOpen: !this.state.sideBarOpen })
                }
                icon={menu}
                size={20}
                style={{ color: context.themeColor }}
              />
            )}
          </div>
          <div className=" ml-2">
            {current === "restaurantmain" ? null : (
              <BackLogo
                restID={context.restID}
                smallSize
                onHeader
                title={title}
              />
            )}
            <p style={{ textAlign: "center", color: context.themeColor }}>{title}</p>
          </div>
          {this.props.context.isDigitalMenu ? (
            <div className=" ml-2"></div>
          ) : (
            <div className=" ml-2">
              {type === "checkout" ? (
                <div onClick={() => headerAction()}>
                  <p
                    className="p-0 m-0"
                    style={{
                      fontSize: "14px",
                      color:
                        current === "restaurantmain"
                          ? context.themeColor
                          : context.themeColor,
                    }}
                  >
                    PAY BILL
                  </p>
                  <p
                    className="p-0 m-0"
                    style={{
                      fontSize: "8px",
                      color:
                        current === "restaurantmain"
                          ? context.themeColor
                          : context.themeColor,
                    }}
                  >
                    {context.confirmedCount
                      ? context.confirmedCount +
                        " items | " +
                        window.rupeeSym +
                        context.confirmedPayTotal.toFixed(2)
                      : "0 items | " + window.rupeeSym + "0.00"}
                  </p>
                </div>
              ) : type === "offers" ? (
                <div onClick={() => headerAction()}>
                  <p
                    className="p-0 m-0"
                    style={{ fontSize: "15px", color: "white" }}
                  >
                    OFFERS <img src={offersIcon} alt="" />
                  </p>
                </div>
              ) : type === "menu" ? (
                <div onClick={() => headerAction()}>
                  <p
                    className="p-0 m-0"
                    style={{ fontSize: "15px", color: context.themeColor }}
                  >
                    ADD ITEMS
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </div>
        {/* {current === "restaurantmain" ? null : 
            <div style={{position : "fixed", right : 0, top : 0, zIndex : -15}}>
            <img src={rightCurve} alt='' />
            </div>
        } */}
        {current === "menu" ? (
          <Form
            style={{ marginTop: "20px" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <Input
              name="search"
              onChange={(e) => onChange(e)}
              type="search"
              placeholder="Search"
              style={{ width: "100%" }}
            />
          </Form>
        ) : null}
      </div>
    );
  }
}

const AppHeader = withTheme(Header);
export default AppHeader;
