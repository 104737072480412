


const trimDescription = (desc) => {
    const maxDesLength = 75;
    if (desc.length > maxDesLength) {
        let trimmedString = desc.substr(0, maxDesLength);
        trimmedString = trimmedString.substr(
            0,
            Math.min(maxDesLength, trimmedString.lastIndexOf(" "))
        );
        return trimmedString + "...";
    } else {
        return desc
    }
};

module.exports = {
    formatDescriptionFull: (description) => {
        // console.log(description, description.split('\\n'));
        let newStr = ``
        // let newdesc = preg_replace('/\n\n/g', '\\n<br />\\n', description);
        // let newdesc = description.replace("/\\n\\n/g", '\\n<br />\\n');
        description.split('\\n').forEach((x) => {
            return (newStr += `<p class="m-0 p-0">${x}</p><br />`);
        });
        // console.log(newdesc);
        return newStr;
    },
    formatDescriptionShort: (description) => {
        let newStr = ``
        // let newdesc = trimDescription(description).replace(
        //     '\\n\\n',
        //     '\\n<br />\\n'
        // );
        trimDescription(description)
            .split('\\n')
            .forEach((x) => {
                return (newStr += `<p class="m-0 p-0">${x}</p>`);
            });
        // trimDescription(description).split('\\n').forEach(x => { return newStr += `<p class="m-0 p-0">${x}</p>` });
        return newStr;
    }
}
