import React, { Component } from "react";
import {
    Modal,
    ModalHeader,
    Card,
    ModalBody,
    Row,
    Col,
    Label,
    Button
} from "reactstrap";
import { Collapse, CardBody } from 'reactstrap';
import { FieldGroup } from "../helpers/FieldGroup";
import OtpInput from "react-otp-input";
import { AppContext, withTheme } from "../contexts/AppContext";
// import { GoogleLogin } from "react-google-login";
import ProcessOrder from './ProcessOrder';
import logo from "../img/logo_2.png";
import login_US_Controller from "../controllers/signUp/loginUS.controller";
import { firebaseAuth, googleProvider as provider } from '../Firebase'
import axios from "axios";
import { Link } from 'react-router-dom';
import { Spinner } from 'react-activity'

import '../v2/css/login.css';


var styles = {
    labelstyle: {
        display: 'block',
        fontFamily: 'Lato',
        fontSize: '12px',
        color: '#544f4f',
    },
    LoginModalButtonstyle: {
        fontFamily: 'Lato',
        // backgroundColor: "#7AC1D1",
        display: 'block',
        border: 'none',
        fontSize: '15px',
        boxShadow:
            'rgba(0, 0, 0, 0.1) 1px 2px 1px 0px, rgba(0, 0, 0, 0.1) 0px 1px 1px 0px',
        borderRadius: '6px',
        //width : "50%",
        height: '40px',
    },
    CardStyle: {
        marginTop: '20px',
        // boxShadow: "1px 1px 5px darkgrey",
        marginLeft: '2em',
        marginRight: '2em',
    },
};

class Login extends Component {
    constructor(props) {
        super(props);

        this.sendOTP = login_US_Controller.sendOTP.bind(this);
        this.verifyOTP = login_US_Controller.verifyOTP.bind(this);
        this.resendOTP = login_US_Controller.resendOTP.bind(this);
        this.responseGoogle = login_US_Controller.responseGoogle.bind(this);
        this.onChange = login_US_Controller.onChange.bind(this);
        this.loginWithGoogle = this.loginWithGoogle.bind(this);
        this.onChangeOTP = login_US_Controller.onChangeOTP.bind(this);

        this.state = {
            loginForm: this.props.open || false,
            email:
                this.props.context && this.props.context.user
                    ? this.props.context.user.email
                    : null,
            emailValid: false,
            otp: null,
            otpsent: false,
            showPopup: false,
            showWhyEmail: false,
            authLoading: false
        };
    };

    sendUserDataToDB(user) {
        this.setState({
            authLoading: true
        });
        axios(window.kumbaURL + "/auth/firebase", {
            data: {
                displayName: user.displayName,
                email: user.email,
                emailVerified: user.emailVerified,
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber,
                uid: user.uid,
                providerData: user.providerData
            },
            method: 'POST'
        })
            .then(res => {

                window.setCookie('token', res.data.token, 360);
                this.props.context.setContextState({
                    token: res.data.token
                });
                this.props.context.getUser();
                this.setState({
                    authLoading: false,otpsent: false, showPopup: true
                });
            })
            .catch(err => {
                this.setState({
                    authLoading: false
                });
                // console.log('ERROR --', err)
            })
    }

    loginWithGoogle() {
        firebaseAuth.signInWithPopup(provider)
            .then((result) => {
                var credential = result.credential;

                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = credential.accessToken;
                // The signed-in user info.
                var user = result.user;
                // console.log(user)
                this.sendUserDataToDB(user);
            }).catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                this.props.context.popup(`${errorMessage}`, 'error');
                // ...
            });
    }


    render() {
        return (
            <div style={{ marginTop: '20%' }}>
                {
                    this.state.authLoading ?
                        <div className='text-center'>
                            <Spinner size={30}  />
                        </div> : <>
                            <Row>
                                <Col md={{ size: 4, offset: 4 }} xs={12}>
                                    <h1 style={{ textAlign: 'center' }}>
                                        {/* <img src={logo} alt="" height="240" width="120" /> */}
                                        Login
                                    </h1>
                                </Col>
                            </Row>
                            {this.props.context && this.props.context.user ? (
                                <ProcessOrder closeDialog={this.props.closeModal} />
                            ) : (
                                <div id="login-modal" style={styles.CardStyle} body>
                                    <label style={styles.labelstyle}>
                                        <p
                                            style={{
                                                textAlign: 'center',
                                                color: window.themeColor,
                                            }}
                                        >
                                            Please enter your email to continue.
                                        </p>
                                    </label>
                                    <div className="text-center">
                                        <Button className="bg-white text-info btn-sm" onClick={() => this.setState({ showWhyEmail: !this.state.showWhyEmail })} style={{ border: 'none' }}>Why we need your email</Button>
                                    </div>
                                    <Collapse isOpen={this.state.showWhyEmail}>
                                        <Card>
                                            <CardBody>
                                                We use your email to protect your data and send you receipts.
                                                We will not use your email for any marketing purposes unless specified otherwise.
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                    <FieldGroup
                                        id="email"
                                        name="email"
                                        type="text"
                                        placeholder="Email"
                                        // label="Email"
                                        value={this.state.email}
                                        // ig={
                                        //     "Email"
                                        // }
                                        onChange={this.onChange.bind(this)}
                                        validationState={this.state.emailValid}
                                        disabled={this.state.otpsent}
                                        parent={this}
                                    />

                                    {this.state.otpsent ? (
                                        <div>
                                            {this.state.otpreceived ? (
                                                <Row>
                                                    <Col>
                                                        <p>
                                                            {'Your OTP is : ' +
                                                                this.state.otpreceived}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            ) : null}

                                            <div className="kb-login-otp">
                                                <OtpInput
                                                    className="text-center"
                                                    onChange={this.onChangeOTP.bind(this)}
                                                    numInputs={4}
                                                    containerStyle={
                                                        'kb-login-otp-container'
                                                    }
                                                    inputStyle={{
                                                        width: '45px',
                                                        boxSizing: 'border-box',
                                                        border: 'transparent',
                                                        borderBottom: '4px solid #ccc',
                                                    }}
                                                    isInputNum={true}
                                                    separator={<span> - </span>}
                                                    shouldAutoFocus={true}
                                                />
                                            </div>
                                        </div>
                                    ) : null}
                                    <br />
                                    {this.state.otpsent ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    ...styles.LoginModalButtonstyle,
                                                    backgroundColor:
                                                        this.props.context.themeColor,
                                                }}
                                                onClick={this.resendOTP.bind(this)}
                                            >
                                                {'Resend OTP'}
                                            </Button>
                                            <Button
                                                style={{
                                                    ...styles.LoginModalButtonstyle,
                                                    backgroundColor:
                                                        this.props.context.themeColor,
                                                }}
                                                onClick={this.verifyOTP.bind(this)}
                                            >
                                                {'Verify OTP'}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <Button
                                                style={{
                                                    ...styles.LoginModalButtonstyle,
                                                    backgroundColor:
                                                        this.props.context.themeColor,
                                                }}
                                                onClick={this.sendOTP.bind(this)}
                                                className="w-100 mb-2"
                                            >
                                                {'Login'}
                                            </Button>
                                            <p className="text-center">OR</p>
                                            <Button
                                                style={{
                                                    ...styles.LoginModalButtonstyle,
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                }}
                                                onClick={this.loginWithGoogle}
                                                className="w-100 mb-2"
                                            >
                                                <img
                                                    width="20px"
                                                    style={{
                                                        marginBottom: '3px',
                                                        marginRight: '5px',
                                                    }}
                                                    alt="Google sign-in"
                                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                                                />
                                                Login with Google
                                            </Button>
                                            {/* <img
                                src={
                                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToXAetPPbsSFTvemwWd606QMiPE-7OjTi-TYribSwa7ZPljJT_O5p3PGfXJtYlmFIMaA&usqp=CAU'
                                }
                                alt=""
                                width="250"
                            /> */}
                                            {/* {this.props.skip ? (
                                <Link
                                    style={{
                                        color: this.props.context.themeColor,
                                    }}
                                    to="/main"
                                >
                                    Skip
                                </Link>
                            ) : null} */}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                }

            </div>
        );
    }
}

const AppLogin = withTheme(Login);
export default AppLogin;
